/*!
 * Reinhart Realtors Website Theme
 *
 * Community details styles
 * 
 */
 
 .show-bio {
    .more {
        display: inline-block;
    }
    .less {
        display: none;
    }
    i {
        transition: transform 0.2s linear;
    }
    &.active {
        .more {
            display: none;
        }
        .less {
            display: inline;
        }
        i {
            transform: rotate(-180deg);
        }
    }
}

#communityDetailsAccordion {
  .community-listings-container {
    .rr-prop-compare-container {
      display: none;
      padding: 0;
      @include media-breakpoint-up(lg) {
        display: block;
      }
      button {
        border: none;
        font-weight: 400;
      }
    }
    .nav.nav-tabs {
      background-color: transparent;
      > li {
        padding-top: 0;
      }
    }
    .nav-link {
      color: $dark-gray;
      border: none;
      font-weight: 400;
      .radio-checked {
        display: none;
      }
      .svg-icon {
        margin-bottom: 4px;
      }
      &.active {
        border: none;
        .radio-checked {
          display: inline-block;
        }
        .radio-unchecked {
          display: none;
        }
      }
    }
  }
    button {
        font-size: inherit;
        color: inherit;
        text-transform: inherit;
        text-decoration: none;
        font-weight: 300;
        padding: 0;

        i {
            font-size: 30px;
            transition: transform 0.2s linear;
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
        }

        &:not(.collapsed) {
            i {
                transform: rotate(-180deg);
            }
        }

        &:hover,
        &:focus,
        &:active {
            color: $bright-red;
        }
        &:focus {
            box-shadow: none;
        }
    }
}

#community-page {
    .nav.nav-tabs {
        > li,
        &.nav-justified > li {
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                margin-top: 0;
            }
        }
    }
    
    .community-hero {
        height: 40vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        top: 0;
        margin-top: 80px; // offset mt-0 in the main element
    
        @include media-breakpoint-up(md) {
            height:50vh;
        }
        @include media-breakpoint-up(lg) {
            height:70vh;
            margin-top: 0px;
        }
    }    

    .bio-wrap {
        max-height: 400px;
        overflow: hidden;
        position: relative;
        -webkit-transition: max-height .25s ease;
        -moz-transition: max-height .25s ease;
        -ms-transition: max-height .25s ease;
        -o-transition: max-height .25s ease;
        transition: max-height .25s ease;

        .btn {
            min-height: 50px;
            height: auto;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 60px;
            background: linear-gradient(rgba(255,255,255,0),#fff);
        }

        &.active {
            max-height: 3000px;

            &:after {
                content: none;
            }
        }
    }

    #community-map {
        height: 300px;

        @include media-breakpoint-up(md) {
            height: 400px;
        }

        @include media-breakpoint-up(lg) {
            height: 600px;
        }
        
        @include media-breakpoint-up(xl) {
            height: 700px;
        }
    }

    #community-list {
        border: 1px solid #f5f5f5;
        border-left: none;
        border-right: none;
        overflow: auto;
        height: 450px;
        -webkit-box-shadow: -4px 0 4px -4px rgba(0,0,0,.5);
        box-shadow: -4px 0 4px -4px rgba(0,0,0,.5);

        @include media-breakpoint-up(lg) {
            height: 600px;
        }
        
        @include media-breakpoint-up(xl) {
            height: 700px;
        }

        li {
            &.active, &:hover, &:active {
                background-color: #f5f5f5;

                a {
                    color: $dark-red;
                }
            }
        }

        .community-image {
            img {
                object-fit: cover;
                width: 217px;
                height: 135px;
                overflow: hidden;
            }

            .community-number {
                font-weight: 700;
                text-align: center;
                color: #fff;
                position: absolute;
                top: 0;
                left: 0;
                width: 36px;
                height: 36px;
                z-index: 1;
            }
        }
    }
}

// For the community-search-form for properties, change color of select elements when no beds or baths
// are selected to show the placeholder color of medium gray, but keep the options black
.community-search-form select {
    &.rr-placeholder {
        color: $medium-gray;
    }
    option {
        color: $black;
    }
}

#poi-map-element .poi-map-div {
    height: 500px
}

#poi-map-element .ui-autocomplete-input {
    font-weight: 400
}

#poi-map-element .poi-map-form .svg-icon {
    position: absolute;
    right: 0;
    top: 5px;
    fill: #2e2e2e;
    font-size: 24px;
    transform: rotate(-90deg)
}

#poi-map-element .poi-map-form .poi-form-element {
    margin-top: 18px
}

#poi-map-element .poi-map-form .poi-map-autocomplete-toggle {
    border: 0;
    right: 0;
    top: 0;
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: transparent
}

#poi-map-element .poi-map-results-wrapper .alert-info {
    background-color: #c4c4c4;
    border-color: #c4c4c4;
    color: #2e2e2e;
    padding: 20px;
    border-radius: 0
}

#poi-map-element .poi-map-results-wrapper .alert-danger {
    z-index: 100
}

#poi-map-element .poi-map-results-wrapper .poi-map-results h3 {
    margin: 20px 0 25px;
    font-size: 24px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: .005em
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul {
    height: 510px;
    overflow-y: auto;
    padding: 0;
    list-style: none;
    margin-left: 0
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item {
    padding: 10px 0;
    margin-left: 0
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a {
    color: #2e2e2e;
    font-weight: 400
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a:hover,#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a:active,#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a:focus {
    color: #a41832;
    text-decoration: none
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a span {
    color: #4a525d;
    padding-right: 20px;
    min-width: 20px;
    display: inline-block;
    font-weight: 600
}

#poi-map-element .placeInfoBubble {
    width: 250px
}

#poi-map-element .placeInfoBubble span {
    display: block
}

#poi-map-element .placeInfoBubble .placeName {
    font-weight: 700
}

#poi-map-element .ui-helper-hidden-accessible {
    display: none
}

#poi-map-element .ui-autocomplete {
    z-index: 105;
    position: absolute;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0;
    border: 1px solid #c4c4c4;
    -webkit-box-shadow: 5px 5px 10px 0 #777;
    box-shadow: 5px 5px 10px 0 #777
}

#poi-map-element .ui-autocomplete .ui-menu-item {
    margin: 0;
    width: 100%;
    display: block;
    padding: .25em .2em;
    background: #fff;
    cursor: pointer;
    text-decoration: none
}

#poi-map-element .ui-autocomplete .ui-menu-item.ui-state-hover,#poi-map-element .ui-autocomplete .ui-menu-item:hover {
    background: #eee
}

#poi-map-element .ui-autocomplete .ui-menu-item a {
    display: block;
    padding-left: 15px
}

#poi-map-element .ui-autocomplete .ui-menu-item a:hover {
    text-decoration: none
}

.stat-trend-wrapper {
    height: 160px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 9px 18px 0 rgba(0,0,0,.03),0 2px 7px 0 rgba(0,0,0,.03);
    text-align: center;
    color: #2e2e2e;
    padding: 25px
}

@media screen and (max-width: 767px) {
    .stat-trend-wrapper {
        margin-bottom:15px
    }
}

@media screen and (min-width: 768px) {
    .stat-trend-wrapper {
        padding:15px 10px
    }
}

@media screen and (min-width: 992px) {
    .stat-trend-wrapper {
        padding:25px 15px
    }
}

@media screen and (min-width: 1200px) {
    .stat-trend-wrapper {
        padding:25px;
        margin: 0 15px
    }
}

.stat-trend-wrapper .stat-label {
    margin-bottom: 5px;
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: .05em
}

@media screen and (min-width: 768px) {
    .stat-trend-wrapper .stat-label {
        font-size:13px;
        line-height: 20px
    }
}

@media screen and (min-width: 992px) {
    .stat-trend-wrapper .stat-label {
        font-size:16px;
        line-height: 20px
    }
}

@media screen and (min-width: 1200px) {
    .stat-trend-wrapper .stat-label {
        font-size:16px;
        line-height: 20px
    }
}

.stat-trend-wrapper .stat-value {
    margin-bottom: 5px;
    display: block;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px
}

@media screen and (min-width: 768px) {
    .stat-trend-wrapper .stat-value {
        font-size:27px;
        line-height: 36px
    }
}

@media screen and (min-width: 992px) {
    .stat-trend-wrapper .stat-value {
        font-size:30px;
        line-height: 40px
    }
}

@media screen and (min-width: 1200px) {
    .stat-trend-wrapper .stat-value {
        font-size:30px;
        line-height: 40px
    }
}

.stat-trend-wrapper .stat-last-month {
    color: #8c8c8c
}

#community-page .find_community button {
    width: 66px;
}

@media screen and (max-width: 767px) {
    #community-page #community-bio-tabs {
        padding:10px 0
    }

    #community-page #community-bio-tabs li {
        padding: 0
    }
}

.resultsPropertyBubble {
    min-height: 30px
}

a.maponics-trigger:hover,a.maponics-trigger:focus {
    color: #2e2e2e;
    text-decoration: none
}

#communityDataDropdown iframe {
    margin-top: 50px;
    width: 100%;
    border: 0;
    min-height: 350px
}