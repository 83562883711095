/*!
 * Reinhart Realtors Website Theme
 *
 * Mobile page styles
 * 
 */

 .mobile-app-page {
  .hero-img {
    height: 416px;
    .absolute-md {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
    }
    .mobile-slogan {
      text-shadow: 0 2px 6px rgb(0 0 0 / 50%);
      @include media-breakpoint-down(md) {
        font-size: 36px;
      }
    }
  }
  .phone-number-validation {
    input {
      height: 50px;
      width: 400px;
      padding-left: 10px;
      padding-bottom: 2px;
    }
    button {
      border: none;
    }
  }
  .phone-img {
    bottom: 0;
    width: 100%;
    img {
      margin-left: 55px;
    }
  }
  .mobile-wells {
    padding-top: 40px;
    img{
      border: 1px solid $gray-400;
    }
  }  
  .mobile-well-desc {
    margin: auto;
  }
  .ipad-img {
    top: -35px;
  }
  #mobile-apps-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 5;
  }
  .app-store-icon {
    width: 130px;
    @include media-breakpoint-up(lg) {
      width: 160px;
    }
  }
  #company-footer {
    padding-bottom: 60px;
    @include media-breakpoint-up(md) {
      padding-bottom: 75px;
    }
  }
 }
