/*!
 * Reinhart Realtors Agent Theme - Teal
 * Authored by MavTech Consulting (https://www.mavtechconsulting.com)
 * Based on the Bootstrap 4 framework
 */

// Import the Bootstrap framework
// Color definitions and default overrides are done in the bootstrap file. If no color changes
// are required, then use generic bootstrap. Otherwise, use a custom bootstrap file that pulls
// custom color definitions included in the agent theme directory
@import "agent-themes/teal/bootstrap";

// Import the agent base theme
@import "agent_theme_base";
