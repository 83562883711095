/*!
 * Reinhart Realtors Website Theme
 *
 * Property search form and results styles
 * 
 */

body.property-search-page {
  margin-bottom: 50px;
} 

 // Property search form
.property-search {
  .nav {
    padding-bottom: 15px;
    @include media-breakpoint-up(md) {
      padding-bottom: 0px;
    }
  }
  .nav.nav-tabs > li.nav-item {
    margin-bottom: 0;
  }
  input::placeholder {
    //color: $black;
    color: $medium-gray;
  }
  select.rr-placeholder {
    color: $medium-gray;
  }
  select option {
    color: $black;
  }
}

.rr-collapse-heading {
  cursor: pointer;
  i {
    display: inline-block;
    font-size: 0.8125em;
    color: $medium-gray;
    transition: transform 0.2s linear;
  }
  &.collapsed i {
    transform: rotate(-180deg);
  }
}

#advanced-search-width-wrapper {
  z-index: 1;
}

.formCounter {
    position: fixed;
    top: 0;
    bottom: 0;
    height: auto;
    width: auto;
    z-index: 1500; // mapquest logo is z-index 1000
    background-color: $white;
    padding: 10px;
    text-align: center;
    right: 0;
    box-shadow: 0 2px 7px 0 rgba($black,.06),0 9px 18px 0 rgba($black,.06);
    border: 1px solid $off-white;

    .formCounterCountValue {
        font-size: 24px;
        @include media-breakpoint-up(md) {
          margin-right: 20px;
        }
    }

    .formCounterText {
      font-size: 24px;
      margin-right: 40px;

      @include media-breakpoint-up(lg) {
        font-size: 16px;
        margin-right: 0px;
      }
    }

    button {
      height: auto;
    }

    @include media-breakpoint-down(md) {
        top: auto;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @include media-breakpoint-down(sm) {
        .formCounterCountValue,
        .formCounterSubmit {
          flex: 1 0 50%;
        }
        .formCounterText {
            display: none;
        }
    }

    @include media-breakpoint-up(lg) {
        // right:0;
        // position: absolute;
        position: sticky;
        top: 15px;
        bottom: unset;
        right: unset;
        margin: 0 -15px 15px -20px;
        transition: top 0.3s ease;
        .formCounterCountValue {
            font-size: 30px;
            color: $carbon-gray;
            min-height: 36px;
            margin-right: 0px;
        }
        button {
            padding: 12px 10px;
        }
        .formCounterSubmit {
            margin-top: 12px;
        }
    }
    @include media-breakpoint-up(xl) {
        // right: 15px;
        margin: 0 0 15px -5px;
    }
}

.to-spacer-gutter {
    padding-left: 0;
    padding-right: 0;
    width: 30px;
    margin-left: -15px;
    margin-right: -15px;
    text-align: center;
    line-height: 22px;
}

// Property search results
.rr-prop-compare-container {
  padding: 15px 15px 12px;
  &.show > button {
    position: relative;
    &:before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: $white;
      border-left: 2px solid $med-cool-gray;
      border-top: 2px solid $med-cool-gray;
      z-index: 11;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      bottom: -18px;
    }
  }
  button {
    padding: 0;
    outline: 0;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
  }
  a {
    display: inline-block;
    color: $black;
    text-decoration: none;
  }
  .rr-prop-compare-indicator {
    color: $bright-red;
  }
  i {
    font-size: 24px;
    line-height:1;
  }
  .dropdown-menu {
    border-top: 2px solid $med-cool-gray;
    z-index: 10;
    box-shadow: 0 6px 7px 0 rgba($black,0.3);
  }
  .dropdown-item {
    padding: 3px 20px;
    img {
      vertical-align: bottom;
    }
  }
}

.rr-search-filters-container {
  button {
    width: 100%;
    background-color: $medium-gray;
    color: $white;
    outline: 0;
    padding: 12px 20px;
    &:hover,
    &:focus,
    &:active {
      background-color: $medium-gray;
      border-color: $medium-gray;
      color: $white;
      box-shadow: none;
    }
    &:not(.collapsed) {
      &:focus {
        background-color: $dark-charcoal-gray;
      }
      i {
        transform: rotate(180deg);
      }
    }
    i {
      transition: transform 0.2s linear;
    }
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
}

.search-results {
  .sort_order {
    .select-wrapper {
      text-align: center;
    }
    select {
      &:disabled {
        color: $medium-gray;
      }
      @include media-breakpoint-down(sm) {
        display: inline-block;
        width: auto;
      }
    }
  }
  .page_nav {
    .prev.disabled,
    .next.disabled {
      color: $light-gray;
    }
    select {
      min-width: 80px;
      text-align: center;
      appearance: none;
    }
  }
  .refine-container {
    border-top: 1px solid $off-white;
    border-bottom: 1px solid $off-white;
  }
  .ui-autocomplete {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 1px 2px 0 rgba($black,0.3);
  }
}

// results grid used on multiple pages where listings are displayed such as property search results, communities,
// and agent details pages
.results-grid {
  display: grid;
  // grid-auto-rows: 1fr; // makes every row the same height regardless of row contents  gap: 60px 30px;
  gap: 60px 30px;
  grid-template-columns: 1fr;
  justify-items: center;
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2,330px);
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 290px);
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 350px);
  }
}

// Common styles between property cards in search results lists for detailed searches and map searches
.property-container,
.map-list-property-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 390px;
  a {
    transition: color .15s ease-in-out;
  }
  .openhouse-banner,
  .sold-banner {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 4px 10px;
    z-index: 10;
    letter-spacing: 0.8px;
    line-height: 19px;
    color: $white;
    font-size: 14px;
    font-weight: 300;
    // text-transform: uppercase;
  }
  .openhouse-banner {
    background: $bright-red;
  }
  .sold-banner {
    // background: rgba($carbon-gray,0.8);
    background: $carbon-gray;
  }
  .property-img-container {
    position: relative;
    width: 100%;
    aspect-ratio: 3 / 2;
    overflow: hidden;
    @supports not (aspect-ratio: 3 / 2) {
      height: min(60vw, 260px);
      @include media-breakpoint-up(md) {
        height: 220px;
      }
      @include media-breakpoint-up(lg) {
        height: 200px;
      }
      @include media-breakpoint-up(xl) {
        height: 240px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .property-address {
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
    .prop-street,
    .prop-city {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

// Specific styles for detailed search result cards
.property-container {
  @include media-breakpoint-up(md) {
    max-width: 330px;
  }
  @include media-breakpoint-up(lg) {
    max-width: 290px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 350px;
  }
  &:hover .property-address a {
    color: $dark-red;
  }
  .property-status-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: 12px;
    .prop-status {
      text-transform: uppercase;
      margin-top: 2px;
      line-height: 2;
      letter-spacing: 0.8px;
    }
  }
  .property-address {
    flex-grow: 1;
    a {
      color: $carbon-gray;
      font-weight: bold;
      text-decoration: none;
    }
  }
  .property-details {
    margin-top: 15px;
    color: $dark-charcoal-gray;
    font-size: 18px;
    .prop-params {
      min-height: 24px;
      > span {
        margin-right: 5px;
      }
    }
  }
  .property-actions {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $medium-gray;
  }
  .property-bin,
  .favorite-property {
    position: relative;
    text-decoration: none;
    padding-left: 30px;
    color: $light-cool-gray;
    &:hover {
      color: $dark-red;
    }
    .inactive {
      opacity: 1;
    }
    .active {
      opacity: 0;
    }
    svg,
    i {
      position: absolute;
      top: 0;
      left: 0;
      &.active {
        color: $dark-red;
      }
    }
    svg {
      font-size: 24px;
      vertical-align: bottom;
    }
    i {
      font-size: 20px;
      line-height: 1.3;
    }
  }
  .property-bin {
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-right: 30px;
    }
    &.property-bin-remove {
      color: $dark-red;
      .inactive {
        opacity: 0;
      }
      .active {
        opacity: 1;
      }
    }
  }
  .favorite-property {
    display: inline-block;
    &.remove-favorite-property {
      color: $dark-red;
      .inactive {
        opacity: 0;
      }
      .active {
        opacity: 1;
      }
    }
  }
  .map-property {
    display: inline-block;
    text-decoration: none;
    color: $light-cool-gray;
    font-size: 16px;
    &:hover {
      color: $dark-red;
    }
    i {
      margin-right: 3px;
    }
  }
}

// Specific styles for map-based search result property cards
.map-list-property-container {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid $medium-gray;
  .property-price-status {
    margin-top: 10px;
    line-height: 1.5;
    letter-spacing: 0.8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .prop-price {
      font-size: 16px;
    }
    .prop-status {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
  .property-params {
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > span {
      margin-right: 5px;
    }
  }
  .openhouse-banner,
  .sold-banner {
    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
  }
  a.clickthru {
    text-indent: -99em;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
  }
  &:hover {
    cursor: pointer;
    .property-address {
      color: $dark-red;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    max-width: unset;
    .property-img-container {
      margin-right: 10px;
      max-width: 160px;
      flex: 1 0 160px;
    }
    .property-details {
      overflow: hidden;
    }
    .property-address {
      margin-top: 0px;
      .prop-street {
        font-size: 16px;
      }
      .prop-city {
        font-size: 14px;
        font-weight: normal;
      }
    }
    .property-price-status {
      .prop-price {
        font-size: 14px;
      }
      .prop-status {
        font-size: 12px;
      }
    }
    .property-params {
      font-size: 14px;
    }
  }
}

// Styling for map-based search => map & property list with map
#map-search {
  .btn-map-gallery {
    display: inline;
    padding: 8px 12px;
  }

  .properties-list-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      justify-content: space-around;
      align-items: baseline;
    }
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
    .form-control {
      width: auto;
    }
    .page-link:focus {
      box-shadow: none;
    }
  }

  // Setting relative z-index so reappearing header stays on top of map
  #map-tab,
  #map-tab #map {
    height: 560px;
    z-index: 5;
    @include media-breakpoint-up(md) {
      height: 750px;
    }
  }

  .properties-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(md) {
      height: 750px;
      overflow-y: auto;
    }
    .property {
      .property-header {
          font-size: .9rem;
          img {
              width: 110px;
              height: auto;
          }

          .property-address {
              color: $dark-red;
              font-size: 1rem;
          }
      }

      .property-details-mobile {
          font-size: .9rem;
      }

      .property-footer {
          font-size: .9rem;

          .logo {
              img {
                  height: 40px;
                  width: auto;
              }
          }
      }
    }
  }

  @include media-breakpoint-up(md) {
      .tab-content > .tab-pane {
          display: block;
      }
  }
}

// Property pins on map
.property-pin {
  display: block;
  background-color: #000;
  color: #fff;
  font-size: 8.5pt;
  line-height: 8.5pt;
  box-sizing: border-box;
  width: 54px;
  text-align: center;
  padding: 2px 0 3px;
  position: relative;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  // background-image: url(/img/search/spr_maptags.png);
  // background-size: 16px 76px;
  // background-repeat: no-repeat;
  // background-position: -100px 100px;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    width: 0;
    height: 0;
    border-top: solid 9px #000;
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
  }
  &.active,
  &.new {
    background-color: #338000;
    &:after {
      border-top: solid 9px #338000;
    }
  }
  &.conting {
    background-color: #e6b300;
    color: #000;
    // background-position: left -40px;
    // padding-left: 8px;
    &:after {
      border-top: solid 9px #e6b300;
    }
  }
  &.sold {
    background-color: $dark-red;
    text-transform: uppercase;
    letter-spacing: .1em;
    &:after {
      border-top: solid 9px $dark-red;
    }
  }
}

// Styles for Mapquest popup when user selects a property on the map
.listing-map-popup {
  width: 300px;
  .prop-hdr-popup {
    display: flex;
    width: 100%;
    background-color: $dark-charcoal-gray;
    color: $white;
    .prop-address {
      flex-grow: 1;
      width: 100%;
      padding: 0.5rem;
      overflow: hidden;
      .address {
        max-width: 100%;
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: 0.05em;
        @include text-truncate();
      }
      .city {
        font-size: 0.75em;
      }
    }
    .prop-popup-close {
      font-size: 20px;
      padding: 5px 10px;
    }
  }
  .prop-img-popup {
    width: 100%;
    aspect-ratio: 3 / 2;
    overflow: hidden;
    @supports not (aspect-ratio: 3 / 2) {
      height: 200px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .prop-details-popup {
    padding: 0.5rem 0.5rem 0.75rem;
    .prop-details-1 {
      width: 100%;
      overflow: hidden;
      font-size: 0.875rem;
      @include text-truncate();
      .price {
        margin-right: 5px;
        font-weight: bold;
      }
      .status {
        margin-left: 5px;
        font-size: 0.875rem;
      }
    }
    .prop-details-2 {
      font-size: 0.875rem;
      > span {
        margin-right: 0.75rem;
      }
    }
  }
}
