/*!
 * Reinhart Realtors Website Theme
 *
 * Site-wide footer component
 * 
 */

 footer.agent-footer {
  background-color: $black;
  color: $white;
  .site-logos {
    flex-direction: column-reverse;
    gap: 30px;
    @include media-breakpoint-up(md) {
      gap: unset;
    }
  }
  .site-links {
    flex-direction: column;
  }
  .site-logos,
  .site-links {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      // align-items: flex-start;
    }
  }
  .rr-agent-container-footer {
    a {
      text-decoration: none;
    }
    // margin-top: 20px;
    text-align: center;
    @include media-breakpoint-up(md) {
      // margin-top: 0px;
      text-align: right;
    }
  }
  .rr-agent-footer-name {
    font-size: 28px;
    line-height: 1;
  }
  .rr-agent-footer-title {
    font-size: 16px;
    margin-top: 5px;
  }
  .rr-agent-footer-logo {
    max-width: min(100%, 300px);
    max-height: 100px;
  }
  .site-links {
    margin-top: 15px;
    text-align: center;
    @include media-breakpoint-up(md) {
      align-items: flex-end;
      text-align: unset;
    }
  }
  ul.footer-social-list,
  ul.footer-nav {
    margin-bottom: 0;
  }
  .partner-logos {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 0px;
    }
  }
  .agent-copyright {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
  }
 }

.agent-footer a {
  color: $white;
}

#company-footer > .row {
  margin: 0;
  padding: 20px 10px 10px;
}

#company-footer .icon-social {
  color: $white;
  background-color: transparent;
  border-color: transparent;
}

#company-footer .right-icons {
  width: 25px;
}

#company-footer a:hover {
  color: $gray-300; // original value from css #E0E0E0; using closest bootstrap value for now
}

/*!
 * The social icons are implemented as svg vector files and imported through a use statement.
 * The svg element in the _footer.html does not contain a 'viewbox' statement since the individual
 * files have a viewbox defined. Here are the dimensions of the viewboxes:
 *    Facebook:   w:9.18  h:19.2
 *    Twitter:    w:23.75 h:19.14
 *    Instagram:  w:19.2  h:19.2
 *    LinkedIn:   w:19.14 h:19.14
 *    Blog:       w:48    h:24
 *
 * The outer container svg needs to have a width and height set, and the inner svg viewbox will
 * be scaled to fit that width and height, being constrained by the first dimension it bumps
 * against. For example, if the blog outer svg is defined as 96px x 24px, the icon will stay the same
 * height (24px) because this matches the viewport, and the icon will be centered with 24px padding
 * in the horizontal direction. If an offset is desired, then the x and y viewbox coordinates of the
 * inner svg container need to be modified (these are currently set as 0,0 - center of the container.
 * Likewise, if the blog outer svg is set to 96px x 48px, then the svg will double in size. The same
 * ratio works to reduce the svg size by cutting the outer svg container width and height values.
 *
 * For styling purposes, the elements will be made a consistent height, and
 * the container svg width and height will be overridden appropriately as shown below
 *
 */

 .footer-social-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
}

.footer-social-link {
  display: inline-block;
  padding: 10px;
  .svg-icon {
    width: 26px;
    height: 26px;
  }
  .svg-icon.facebook {
    width: 16px;
    height: 26px;
  }
  .svg-icon.instagram,
  .svg-icon.linkedin,
  .svg-icon.pinterest,
  .svg-icon.reddit,
  .svg-icon.tumblr,
  .svg-icon.twitter-x {
    width: 26px;
    height: 26px;
  }
  .svg-icon.youtube {
    width: 37px;
    height: 26px;
  }
  .svg-icon.instagram {
    margin-top: 2px;
  }
  .svg-icon.blog {
    width: 60px;
    height: 30px;
    margin-top: 12px;
  }
}
