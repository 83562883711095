/*!
 * Reinhart Realtors Website Theme
 *
 * Global layout classes for pages
 * 
 */

 // Set the body to flex, flex-direction column, min-height of 100vw so page will always be
 // full height of window, then set main-content with flex-grow so it will fill the remaining space
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Main content layout
#main-content {
  margin-top: 80px; // offset to account for absolute and fixed position menu
  flex-grow: 1;
  // min-height: calc(100vh - 326px);

  // &:not(.isHomePage) {
  //     margin-top: 70px;

  //     @include media-breakpoint-up(md) {
  //         margin-top: 125px;
  //     }
  // }
}

// When on a page with a hero image, the header background will be transparent, so change the
// margin-top so the hero image sits at the top of the page for lg screens
.rr-hero-transparent #main-content {
  @include media-breakpoint-up(lg) {
    margin-top: 0px;
  }
}

// Background image - cover styles
.bg-image-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

// Miscellaneous utility layout classes
.min-height-24 {
  min-height: 24px
}

.min-height-52 {
  min-height: 52px
}

.min-height-80 {
  min-height: 80px
}

.full-width {
  width: 100%
}

.width-50 {
  width: 50%
}
