/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Content (CMS) page styles
 * 
 */

.rr-agent-contact-address {
  margin: 20px 0px;
}

.rr-agent-contact-phone {
  display: flex;
  .contact-phone-label {
    flex: 0 0 80px;
  }
  .contact-phone-number {
    margin-left: 10px;
    flex: 1 0;
  }
}

.rr-agent-contact-email {
  margin-top: 10px;
}