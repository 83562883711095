/*!
 * Reinhart Realtors Website Theme
 *
 * Agent search form and results styles
 * 
 */

 input#realtor_name {
  &::placeholder {
    color: $black;
  }
 }

.agent-search #roster-result-container,
#team-members .agent-team-member-container {
    display: flex;
    justify-content: center;
}

.agent-search-grid {
  display: grid;
  // grid-auto-rows: 1fr; // makes every row the same height regardless of row contents
  gap: 40px 30px;
  grid-template-columns: minmax(100%, 270px);
  justify-items: center;
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2,270px);
    gap: 50px 60px;
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 270px);
    gap: 50px 60px;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 255px);
    gap: 50px 30px;
  }
}

.agent-img-container {
  width: 100%;
  aspect-ratio: 3 / 4;
  overflow: hidden;
  @supports not (aspect-ratio: 3 / 4) {
    height: min(60vw, 260px);
    @include media-breakpoint-up(md) {
      height: 220px;
    }
    @include media-breakpoint-up(lg) {
      height: 200px;
    }
    @include media-breakpoint-up(xl) {
      height: 240px;
    }
  }
  a {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.entity-agent {
  a {
    color: #2e2e2e;
    &:hover {
      text-decoration: none;
      span {
        border-bottom: 1px solid $dark-charcoal-gray;
      }
    }
  }
  a.entity-phone {
    color: $black;
    &:hover {
      color: $dark-red;
      text-decoration: underline;
    }
  }
} 

@media screen and (max-width: 767px) {
    .entity-agent .min-height-52,.entity-agent .min-height-80 {
        min-height:0;
    }
}
