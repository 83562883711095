/*!
 * Reinhart Realtors Website Theme
 *
 * Agent details/bio page styles
 * 
 */

#realtor-bio-page {
  .agent-info {
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
  .agent-image-wrap {
    width: 270px;
    height: 100%;
    max-width: 100%;
    aspect-ratio: 3 / 4;
    overflow: hidden;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
      flex: 0 0 210px;
      width: 100%;
      margin-left: 0;
      margin-right: 20px;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 255px;
    }
    @include media-breakpoint-up(xl) {
      flex: 0 0 270px;
    }
    @supports not (aspect-ratio: 3 / 4) {
      height: min(120vw, 360px);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .preferred-realtor {
    background-color: rgba($black,.7);
    color: $white;
    text-align: center;
    height: 30px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
  .agent-details-wrap {
    @include media-breakpoint-up(md) {
      flex-grow: 1;
    }
  }
  .agent-contact-actions {
    @include media-breakpoint-up(md) {
      display: flex;
    }
  }
  .agent-contact-info {
    @include media-breakpoint-up(md) {
      flex-grow: 1;
      margin-right: 15px;
    }
  }
  .agent-action-buttons {
    @include media-breakpoint-up(md) {
      flex: 0 0 210px;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 290px;
    }
    @include media-breakpoint-up(xl) {
      flex: 0 0 350px;
    }
  }
  .phone-label {
    min-width: 68px;
  }
  .social-icon-list li {
    margin-left: 0;
    padding: 0;
    .social-links [class^=bi],
    .social-links [class*="bi-"] {
      color: $light-cool-gray;
      background-color: transparent!important;
      display: block;
      font-size: 20px;
      text-decoration: none;
      text-align: center;
    }
    .social-links .svg-icon {
      color: $light-cool-gray;
      background-color: transparent;
      display: inline-block;
      font-size: 20px;
      text-decoration: none;
      text-align: center;
      vertical-align: text-bottom;
    }
    a.social-links:hover {
      text-decoration: none;
      [class^=bi],
      [class*=" bi-"],
      .svg-icon {
        color: $dark-charcoal-gray;
      }
    }
  }
}

// Testimonial styling - on agent details page and testimonials page
#realtor-bio-page,
.rr-agent-testimonials-container {
  .quoteWrapper {
    position: relative;
    .quoteInner:before {
      content: " ";
      background-image: url(/img/icons/quotes.png);
      background-repeat: no-repeat;
      background-size: cover;
      width: 42px;
      height: 32px;
      display: block;
      margin: 0 auto;
      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
      @include media-breakpoint-up(md) {
        position: absolute;
        margin-left: -72px;
        margin-top: 6px;
      }
    }
    > p {
      margin-top: 30px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
    .testimonial-name {
      text-align: center;
      @include media-breakpoint-up(md) {
        text-align: left;
      }
      cite {
        font-style: normal;
      }
      .date {
        margin-top: -5px;
      }
      &:before {
        content: " ";
        width: 30px;
        height: 20px;
        border-top: 3px solid $black;
        display: block;
        margin: 0 auto;
        @include media-breakpoint-up(md) {
          float:left;
          margin: 11px 10px 0 0;
          height: 30px;
        }
      }
    }
  }
}

// This is the trigger for mobile devices to open and close the collapse menu
.mobileTrigger {
  display: block;
  background-color: $light-cool-gray;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 11px;
  margin-bottom: 11px;
  font-size: 16px;
  margin: 0;
  color: $carbon-gray;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: .05em;
  text-decoration: none;
  color: $white;
  padding: 9px 15px;
  font-size: 15px;
  margin-bottom: 2px!important; 
  a {
    color: $white;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    i {
      transition: transform 0.2s linear;
    }
    &.collapsed i {
      transform: rotate(-180deg);
    }
  }
}
