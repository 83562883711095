/*!
 * Reinhart Realtors Website Theme
 *
 * Buttons
 *
 * Overrides Bootstrap values
 * 
 */

// Additional base style and overrides
.btn {
  letter-spacing: .8px;
  font-weight: 700;
  line-height: 1.429;
  text-transform: uppercase;
  outline: 0;
  height: 50px;
}

.btn.agent-search-reset,
.btn.property-search-reset {
  height: unset;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-primary,
.btn-warning {
  color: $white;
  // background-color: $light-cool-gray; // Set in the _bootstrap.scss theme overrides
  // border-color: $light-cool-gray;
  outline: 0;
  padding: 12px 20px;
  border-width: 2px;
  border-style: solid;
  span {
    position: relative;
    display: inline-block;
    transition: padding .25s ease-in-out;
    svg {
      transition: opacity .25s ease-in-out;
      transform: rotate(180deg);
      position: absolute;
      right: 0;
      top: 5px;
      font-size: 11px;
      opacity: 0;
    }
  }
  &:hover {
    color: $white;
    outline: 0;
    box-shadow: 0 2px 5px 0 rgba($black,.25);
    background-color: $dark-charcoal-gray;
    border-color: $dark-charcoal-gray;
    span svg {
      opacity: 1;
    }
  }
  &:focus {
    color: $white;
    outline: 0;
    box-shadow: none;
    background-color: $dark-charcoal-gray;
    border-color: $dark-charcoal-gray;
    outline-offset: 0;
    span svg {
      opacity: 1;
    }
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    outline-width: 0;
    color: $white;
    background-color: $dark-charcoal-gray;
    border-color: $light-cool-gray;
    box-shadow: inset 0 4px 0 rgba($black,0.36);
    &:focus {
      box-shadow: none;
    }
  }
}

.btn-secondary {
  color: $light-cool-gray;
  background-color: $white;
  border-color: $light-cool-gray;
  outline: 0;
  transition: all .25s ease;
  box-shadow: none;
  padding: 12px 20px;
  border-width: 2px;
  border-style: solid;
  span {
    position: relative;
    display: inline-block;
    transition: padding .25s ease-in-out;
    svg {
      transition: opacity .25s ease-in-out;
      transform: rotate(180deg);
      position: absolute;
      right: 0px;
      top: 5px;
      font-size: 11px;
      opacity: 0;
    }
  }
  &:hover {
    color: $light-cool-gray;
    outline: 0;
    box-shadow: 0 2px 5px 0 rgba($black,.25);
    background-color: $white;
    border-color: $dark-cool-gray;
    span svg {
      opacity: 1;
    }
  }
  &:focus {
    color: $light-cool-gray;
    outline: 0;
    box-shadow: none;
    background-color: $white;
    border-color: $dark-cool-gray;
    outline-offset: 0;
    span svg {
      opacity: 1;
    }
  }
  &:active {
    outline-width: 0;
    color: $light-cool-gray;
    background-color: $white;
    border-color: $light-cool-gray;
  }
}

.show > .btn-primary.dropdown-toggle {
  outline-width: 0;
  color: $white;
  background-color: $dark-charcoal-gray;
  border-color: $light-cool-gray;
  box-shadow: inset 0 4px 0 rgba($black,0.36);
}

.btn-sm {
  height: auto;
  padding: 8px 15px;
  line-height: 1.125rem;
}

.button-medium,
.btn-med {
  font-weight: 700;
  font-size: 16px!important;
  line-height: 20px;
  height: 50px;
  padding: 0 40px;
}

.btn-link {
  color: $bright-red;
  font-weight: 400;
  border-radius: 0;
}

.btn-red {
  color: $white;
  background-color: $bright-red;
  border-color: $bright-red;
  outline: 0;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 12px 20px;
  border-width: 2px;
  border-style: solid;

  &:hover {
      color: $white;
      outline: 0;
      -webkit-box-shadow: 0 2px 5px 0 rgba($black,.25);
      box-shadow: 0 2px 5px 0 rgba($black,.25);
      background-color: $dark-red;
      border-color: $dark-red;
  }

  &:focus {
      color: $white;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: $dark-red;
      border-color: $dark-red;
      outline-offset: 0;
  }

  &:active,
  &:not(:disabled):not(.disabled):active {
      outline-width: 0;
      color: $white;
      background-color: $dark-red;
      border-color: $bright-red;
      -webkit-box-shadow: inset 0 4px 4px 0 rgba($black,.36);
      box-shadow: inset 0 4px 4px 0 rgba($black,.36);
  }
}
