/*!
 * Reinhart Realtors Website Theme
 *
 * Landing page styles
 * 
 */

 .landing-page-image {
  // height: 160px;
  height: 220px;
  @include media-breakpoint-up(md) {
    // height: 230px;
    height: 360px;
  }
  @include media-breakpoint-up(lg) {
    // height: 330px;
    // margin-top: -55px;
    height: 460px;
  }
  @include media-breakpoint-up(xl) {
    height: 520px;
  }
}

.go-next-box {
  position: relative;
  height: 200px;
  @include media-breakpoint-up(md) {
    height: 217px;
  }
  @include media-breakpoint-up(lg) {
    height: 306px;
  }
}

.go-next-block {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  text-shadow: 0 2px 4px rgba($black,.8);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: background-color .5s ease-in;
  background-color: rgba($black,.1);
  &:focus {
    text-decoration: none;
  }
  &:hover {
    @include media-breakpoint-up(md) {
      color: #000;
      background-color: rgba($white,.75);
      transition: background-color .25s ease-in;
      text-decoration: none;
      text-shadow: none;
      span {
        color: black;
        &:after {
          background-color: $black;
          box-shadow: none;
        }
      }
    }
  }
  span {
    color: $white;
    transition: background-color .5s ease-in;
    margin-top: 5px;
    &:after {
      content: " ";
      display: block;
      width: 114px;
      height: 3px;
      top: 20px;
      position: relative;
      background-color: #fff;
      margin: auto;
      box-shadow: 0 1px 3px 0 rgba($black,.5),0 2px 4px 0 rgba($black,.8);
    }
    @include media-breakpoint-up(md) {
      margin: auto;
    }
  }
  @include media-breakpoint-down(sm) {
    color: $black;
    background-color: rgba($white,.75);
    transition: background-color .25s ease-in;
    text-decoration: none;
    text-shadow: none;
    span {
      color: $black;
      &:after {
        background-color: $black;
        box-shadow: none;
      }
    }
  }
}
