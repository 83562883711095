/*!
 * Reinhart Realtors Website Theme
 *
 * Forms
 *
 * Overrides Bootstrap values
 * 
 */

// Additional base style and overrides
.form-control {
  border-bottom-width: 1px;
  padding: 0 10px 8px 10px;
  box-shadow: none;
  &:disabled {
    color: $medium-gray;
    background-color: $white;
    opacity: 0.5;
    cursor: not-allowed;
  }
  &[disabled]+svg {
    color: $medium-gray;
    opacity: 0.5;
  }
}

// Basic label styling
label, .label {
  font-family: $font-family-sans-serif;
  margin: 0;
  color: $light-gray;
  text-transform: uppercase;
  font-weight: 400;
}

// Radio button and checkbox styling and colors
.radio label, 
.checkbox label {
  color: $medium-gray;
  text-transform: unset;
}

// Differentiate label colors between checked and unchecked state
.radio input[type=radio]:checked+label, 
.checkbox input[type=checkbox]:checked+label {
  color: $black;
}

// Float labels above input boxes
.float-label-wrap {
  position: relative;
  label {
    font-size: 13px;
    position: absolute;
    top: -23px;
    letter-spacing: 0.8px;
    font-weight: 400;
    left: 0;
    color: $medium-gray;
    pointer-events: none;
    opacity: 0;
    margin: 0;
    transition: top .25s linear,opacity .25s linear;
    &.show {
      top: -23px;
      opacity: 1;
      pointer-events: auto;
    }
    &.error {
      z-index: 2;
      background: $white;
      opacity: 1;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.checkbox label {
  padding-left: 10px;
  //text-transform: none;
  font-weight: 400;
  font-size: 16px!important;
  min-height: 0;
  color: $dark-gray;
  line-height: 30px
}

// save search form label colors
#save-search-form label {
  color: $black;
}

// checkbox, primarily for property search form
.search-additional-filters .checkbox {
  white-space: nowrap;
  overflow: hidden;
  input {
    accent-color: $dark-charcoal-gray;
  }
}

// for positioning an icon on an input box
.icon-input {
  position: relative;
  svg {
    position: absolute;
    right: 0;
    top: 5px;
  }
}

/** 
* ====================================================================== 
* popup-form styles
* ======================================================================= 
**/

.tt-menu {
  width: 100%;
  background-color: $white;
  border: 1px solid $medium-gray;
  border-top: 0;
  box-shadow: 0 9px 18px 0 rgb(0 0 0 / 9%), 0 2px 7px 0 rgb(0 0 0 / 6%);
}

.tt-suggestion {
  padding: 2px 15px;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: $dark-gray;

  &:hover {
      background-color: $light-cool-gray;
      cursor: pointer;
      color: $white;
  }
}

.tt-suggestions {
  background-color: $white;
  box-shadow: 0 9px 18px 0 rgba($black,.03),0 2px 7px 0 rgba($black,.03);
  transition: box-shadow .25s ease-in;
  border: 1px solid $off-white;
  padding: 0
}

.tt-suggestions>:not(a) {
  padding: 0 15px
}

.tt-suggestions a:not([role=button]) {
  color: $dark-gray;
}

.tt-suggestions.card-off-white {
  border: none
}

.tt-suggestions:hover {
  box-shadow: 0 12px 15px 0 rgba($black,.24),0 17px 50px 0 rgba($black,.19)
}

.tt-suggestion {
  padding: 2px 15px;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: $dark-gray;
}

.tt-suggestion p {
  margin: 0;
  color: $dark-gray;
}

.tt-suggestion.tt-cursor,.tt-suggestion.tt-cursor *,.tt-suggestion:hover {
  background-color: $light-cool-gray;
  cursor: pointer;
  color: $white;
}

.tt-suggestion.tt-cursor p,.tt-suggestion.tt-cursor * p,.tt-suggestion:hover p {
  color: $white
}

.tt-menu {
  width: 100%;
  background-color: $white;
  border: 1px solid $medium-gray;
  border-top: 0;
  box-shadow: 0 9px 18px 0 rgba($black,.09),0 2px 7px 0 rgba($black,.06)
}


