/*!
 * Reinhart Realtors Agent Theme - Teal
 * Authored by MavTech Consulting (https://www.mavtechconsulting.com)
 * Based on the Bootstrap 4 framework
 */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/*!
 * Reinhart Realtors Agent Website Theme - Teal
 *
 * Set color variables from Reinhart style guide
 * Compile this file before the _bootstrap.scss file as color values from
 * this file are referenced there.
 */
/*!
 * Set values for Bootstrap variables found in bootstrap/_variables.scss
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --carbon-gray: #000;
  --dark-gray: #000;
  --medium-gray: #919091;
  --light-gray: #c8c7c8;
  --off-white: #dedede;
  --dark-cool-gray: #000;
  --med-cool-gray: #919091;
  --light-cool-gray: #919091;
  --dark-red: #279c8c;
  --bright-red: #2cae9c;
  --primary: #919091;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #919091;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Lato, Arial, Helvetica, san-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Lato, Arial, Helvetica, san-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2cae9c;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #279c8c;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #c8c7c8;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #c8c7c8;
}
.table tbody + tbody {
  border-top: 2px solid #c8c7c8;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #c8c7c8;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #c8c7c8;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e0e0e0;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c6c5c6;
}

.table-hover .table-primary:hover {
  background-color: lightgray;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: lightgray;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #e0e0e0;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #c6c5c6;
}

.table-hover .table-warning:hover {
  background-color: lightgray;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: lightgray;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #000;
  background-color: #dedede;
  border-color: #c8c7c8;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: 2rem;
  padding: 0.375rem 0.75rem;
  font-family: Lato, Arial, Helvetica, san-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #000;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #000;
  background-color: inherit;
  border-color: #919091;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #919091;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: transparent;
  border: solid transparent;
  border-width: 0 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 700;
  color: #000;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 20px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #000;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-primary:hover {
  color: #fff;
  background-color: #7e7d7e;
  border-color: #787678;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #7e7d7e;
  border-color: #787678;
  box-shadow: 0 0 0 0.2rem rgba(162, 161, 162, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #787678;
  border-color: #717071;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 161, 162, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-warning:hover {
  color: #fff;
  background-color: #7e7d7e;
  border-color: #787678;
}
.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #7e7d7e;
  border-color: #787678;
  box-shadow: 0 0 0 0.2rem rgba(162, 161, 162, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #787678;
  border-color: #717071;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 161, 162, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #919091;
  border-color: #919091;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #919091;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #919091;
  border-color: #919091;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #919091;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2cae9c;
  text-decoration: none;
}
.btn-link:hover {
  color: #279c8c;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #000;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #919091;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 15px;
  padding-left: 15px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: 0;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: 0;
}

.input-group-prepend {
  margin-right: 0;
}

.input-group-append {
  margin-left: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0 solid #000;
  border-radius: 0;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #919091;
  background-color: #919091;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #919091;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #eaeaea;
  border-color: #eaeaea;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 0 solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #919091;
  background-color: #919091;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(145, 144, 145, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(145, 144, 145, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(145, 144, 145, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: 0.25rem;
  left: -2.25rem;
  width: 1rem;
  height: 1rem;
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(145, 144, 145, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-family: Lato, Arial, Helvetica, san-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 0 solid #000;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #919091;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.25);
}
.custom-select:focus::-ms-value {
  color: #000;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2rem;
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2rem;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #919091;
  box-shadow: none;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2rem;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-family: Lato, Arial, Helvetica, san-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  border: 0 solid #000;
  border-radius: 0;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #000;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #919091;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #eaeaea;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #919091;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #eaeaea;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #919091;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #eaeaea;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #919091;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23fff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: #fff;
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2cae9c;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #279c8c;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.25s ease, background-color 0.25s ease, border-color 0.25s ease, box-shadow 0.25s ease;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #919091;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #787678;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #919091;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #fff;
  background-color: #787678;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(145, 144, 145, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #4b4b4b;
  background-color: #e9e9e9;
  border-color: #e0e0e0;
}
.alert-primary hr {
  border-top-color: lightgray;
}
.alert-primary .alert-link {
  color: #323232;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #4b4b4b;
  background-color: #e9e9e9;
  border-color: #e0e0e0;
}
.alert-warning hr {
  border-top-color: lightgray;
}
.alert-warning .alert-link {
  color: #323232;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #919091;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #000;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #919091;
  border-color: #919091;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #4b4b4b;
  background-color: #e0e0e0;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #4b4b4b;
  background-color: lightgray;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #4b4b4b;
  background-color: #e0e0e0;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #4b4b4b;
  background-color: lightgray;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #c8c7c8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #c8c7c8;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Lato, Arial, Helvetica, san-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Lato, Arial, Helvetica, san-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #000;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #919091 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #787678 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #919091 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #787678 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #c8c7c8 !important;
}

.border-top {
  border-top: 1px solid #c8c7c8 !important;
}

.border-right {
  border-right: 1px solid #c8c7c8 !important;
}

.border-bottom {
  border-bottom: 1px solid #c8c7c8 !important;
}

.border-left {
  border-left: 1px solid #c8c7c8 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #919091 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #919091 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 5px !important;
}

.mt-5,
.my-5 {
  margin-top: 5px !important;
}

.mr-5,
.mx-5 {
  margin-right: 5px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 5px !important;
}

.ml-5,
.mx-5 {
  margin-left: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.mt-10,
.my-10 {
  margin-top: 10px !important;
}

.mr-10,
.mx-10 {
  margin-right: 10px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 10px !important;
}

.ml-10,
.mx-10 {
  margin-left: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.mt-15,
.my-15 {
  margin-top: 15px !important;
}

.mr-15,
.mx-15 {
  margin-right: 15px !important;
}

.mb-15,
.my-15 {
  margin-bottom: 15px !important;
}

.ml-15,
.mx-15 {
  margin-left: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.mt-20,
.my-20 {
  margin-top: 20px !important;
}

.mr-20,
.mx-20 {
  margin-right: 20px !important;
}

.mb-20,
.my-20 {
  margin-bottom: 20px !important;
}

.ml-20,
.mx-20 {
  margin-left: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.mt-25,
.my-25 {
  margin-top: 25px !important;
}

.mr-25,
.mx-25 {
  margin-right: 25px !important;
}

.mb-25,
.my-25 {
  margin-bottom: 25px !important;
}

.ml-25,
.mx-25 {
  margin-left: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-30,
.my-30 {
  margin-top: 30px !important;
}

.mr-30,
.mx-30 {
  margin-right: 30px !important;
}

.mb-30,
.my-30 {
  margin-bottom: 30px !important;
}

.ml-30,
.mx-30 {
  margin-left: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.mt-35,
.my-35 {
  margin-top: 35px !important;
}

.mr-35,
.mx-35 {
  margin-right: 35px !important;
}

.mb-35,
.my-35 {
  margin-bottom: 35px !important;
}

.ml-35,
.mx-35 {
  margin-left: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.mt-40,
.my-40 {
  margin-top: 40px !important;
}

.mr-40,
.mx-40 {
  margin-right: 40px !important;
}

.mb-40,
.my-40 {
  margin-bottom: 40px !important;
}

.ml-40,
.mx-40 {
  margin-left: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.mt-45,
.my-45 {
  margin-top: 45px !important;
}

.mr-45,
.mx-45 {
  margin-right: 45px !important;
}

.mb-45,
.my-45 {
  margin-bottom: 45px !important;
}

.ml-45,
.mx-45 {
  margin-left: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

.mt-50,
.my-50 {
  margin-top: 50px !important;
}

.mr-50,
.mx-50 {
  margin-right: 50px !important;
}

.mb-50,
.my-50 {
  margin-bottom: 50px !important;
}

.ml-50,
.mx-50 {
  margin-left: 50px !important;
}

.m-55 {
  margin: 55px !important;
}

.mt-55,
.my-55 {
  margin-top: 55px !important;
}

.mr-55,
.mx-55 {
  margin-right: 55px !important;
}

.mb-55,
.my-55 {
  margin-bottom: 55px !important;
}

.ml-55,
.mx-55 {
  margin-left: 55px !important;
}

.m-60 {
  margin: 60px !important;
}

.mt-60,
.my-60 {
  margin-top: 60px !important;
}

.mr-60,
.mx-60 {
  margin-right: 60px !important;
}

.mb-60,
.my-60 {
  margin-bottom: 60px !important;
}

.ml-60,
.mx-60 {
  margin-left: 60px !important;
}

.m-65 {
  margin: 65px !important;
}

.mt-65,
.my-65 {
  margin-top: 65px !important;
}

.mr-65,
.mx-65 {
  margin-right: 65px !important;
}

.mb-65,
.my-65 {
  margin-bottom: 65px !important;
}

.ml-65,
.mx-65 {
  margin-left: 65px !important;
}

.m-70 {
  margin: 70px !important;
}

.mt-70,
.my-70 {
  margin-top: 70px !important;
}

.mr-70,
.mx-70 {
  margin-right: 70px !important;
}

.mb-70,
.my-70 {
  margin-bottom: 70px !important;
}

.ml-70,
.mx-70 {
  margin-left: 70px !important;
}

.m-75 {
  margin: 75px !important;
}

.mt-75,
.my-75 {
  margin-top: 75px !important;
}

.mr-75,
.mx-75 {
  margin-right: 75px !important;
}

.mb-75,
.my-75 {
  margin-bottom: 75px !important;
}

.ml-75,
.mx-75 {
  margin-left: 75px !important;
}

.m-80 {
  margin: 80px !important;
}

.mt-80,
.my-80 {
  margin-top: 80px !important;
}

.mr-80,
.mx-80 {
  margin-right: 80px !important;
}

.mb-80,
.my-80 {
  margin-bottom: 80px !important;
}

.ml-80,
.mx-80 {
  margin-left: 80px !important;
}

.m-85 {
  margin: 85px !important;
}

.mt-85,
.my-85 {
  margin-top: 85px !important;
}

.mr-85,
.mx-85 {
  margin-right: 85px !important;
}

.mb-85,
.my-85 {
  margin-bottom: 85px !important;
}

.ml-85,
.mx-85 {
  margin-left: 85px !important;
}

.m-90 {
  margin: 90px !important;
}

.mt-90,
.my-90 {
  margin-top: 90px !important;
}

.mr-90,
.mx-90 {
  margin-right: 90px !important;
}

.mb-90,
.my-90 {
  margin-bottom: 90px !important;
}

.ml-90,
.mx-90 {
  margin-left: 90px !important;
}

.m-95 {
  margin: 95px !important;
}

.mt-95,
.my-95 {
  margin-top: 95px !important;
}

.mr-95,
.mx-95 {
  margin-right: 95px !important;
}

.mb-95,
.my-95 {
  margin-bottom: 95px !important;
}

.ml-95,
.mx-95 {
  margin-left: 95px !important;
}

.m-100 {
  margin: 100px !important;
}

.mt-100,
.my-100 {
  margin-top: 100px !important;
}

.mr-100,
.mx-100 {
  margin-right: 100px !important;
}

.mb-100,
.my-100 {
  margin-bottom: 100px !important;
}

.ml-100,
.mx-100 {
  margin-left: 100px !important;
}

.m-130 {
  margin: 130px !important;
}

.mt-130,
.my-130 {
  margin-top: 130px !important;
}

.mr-130,
.mx-130 {
  margin-right: 130px !important;
}

.mb-130,
.my-130 {
  margin-bottom: 130px !important;
}

.ml-130,
.mx-130 {
  margin-left: 130px !important;
}

.m-165 {
  margin: 165px !important;
}

.mt-165,
.my-165 {
  margin-top: 165px !important;
}

.mr-165,
.mx-165 {
  margin-right: 165px !important;
}

.mb-165,
.my-165 {
  margin-bottom: 165px !important;
}

.ml-165,
.mx-165 {
  margin-left: 165px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 5px !important;
}

.pt-5,
.py-5 {
  padding-top: 5px !important;
}

.pr-5,
.px-5 {
  padding-right: 5px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 5px !important;
}

.pl-5,
.px-5 {
  padding-left: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.pt-10,
.py-10 {
  padding-top: 10px !important;
}

.pr-10,
.px-10 {
  padding-right: 10px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 10px !important;
}

.pl-10,
.px-10 {
  padding-left: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.pt-15,
.py-15 {
  padding-top: 15px !important;
}

.pr-15,
.px-15 {
  padding-right: 15px !important;
}

.pb-15,
.py-15 {
  padding-bottom: 15px !important;
}

.pl-15,
.px-15 {
  padding-left: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pt-20,
.py-20 {
  padding-top: 20px !important;
}

.pr-20,
.px-20 {
  padding-right: 20px !important;
}

.pb-20,
.py-20 {
  padding-bottom: 20px !important;
}

.pl-20,
.px-20 {
  padding-left: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.pt-25,
.py-25 {
  padding-top: 25px !important;
}

.pr-25,
.px-25 {
  padding-right: 25px !important;
}

.pb-25,
.py-25 {
  padding-bottom: 25px !important;
}

.pl-25,
.px-25 {
  padding-left: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.pt-30,
.py-30 {
  padding-top: 30px !important;
}

.pr-30,
.px-30 {
  padding-right: 30px !important;
}

.pb-30,
.py-30 {
  padding-bottom: 30px !important;
}

.pl-30,
.px-30 {
  padding-left: 30px !important;
}

.p-35 {
  padding: 35px !important;
}

.pt-35,
.py-35 {
  padding-top: 35px !important;
}

.pr-35,
.px-35 {
  padding-right: 35px !important;
}

.pb-35,
.py-35 {
  padding-bottom: 35px !important;
}

.pl-35,
.px-35 {
  padding-left: 35px !important;
}

.p-40 {
  padding: 40px !important;
}

.pt-40,
.py-40 {
  padding-top: 40px !important;
}

.pr-40,
.px-40 {
  padding-right: 40px !important;
}

.pb-40,
.py-40 {
  padding-bottom: 40px !important;
}

.pl-40,
.px-40 {
  padding-left: 40px !important;
}

.p-45 {
  padding: 45px !important;
}

.pt-45,
.py-45 {
  padding-top: 45px !important;
}

.pr-45,
.px-45 {
  padding-right: 45px !important;
}

.pb-45,
.py-45 {
  padding-bottom: 45px !important;
}

.pl-45,
.px-45 {
  padding-left: 45px !important;
}

.p-50 {
  padding: 50px !important;
}

.pt-50,
.py-50 {
  padding-top: 50px !important;
}

.pr-50,
.px-50 {
  padding-right: 50px !important;
}

.pb-50,
.py-50 {
  padding-bottom: 50px !important;
}

.pl-50,
.px-50 {
  padding-left: 50px !important;
}

.p-55 {
  padding: 55px !important;
}

.pt-55,
.py-55 {
  padding-top: 55px !important;
}

.pr-55,
.px-55 {
  padding-right: 55px !important;
}

.pb-55,
.py-55 {
  padding-bottom: 55px !important;
}

.pl-55,
.px-55 {
  padding-left: 55px !important;
}

.p-60 {
  padding: 60px !important;
}

.pt-60,
.py-60 {
  padding-top: 60px !important;
}

.pr-60,
.px-60 {
  padding-right: 60px !important;
}

.pb-60,
.py-60 {
  padding-bottom: 60px !important;
}

.pl-60,
.px-60 {
  padding-left: 60px !important;
}

.p-65 {
  padding: 65px !important;
}

.pt-65,
.py-65 {
  padding-top: 65px !important;
}

.pr-65,
.px-65 {
  padding-right: 65px !important;
}

.pb-65,
.py-65 {
  padding-bottom: 65px !important;
}

.pl-65,
.px-65 {
  padding-left: 65px !important;
}

.p-70 {
  padding: 70px !important;
}

.pt-70,
.py-70 {
  padding-top: 70px !important;
}

.pr-70,
.px-70 {
  padding-right: 70px !important;
}

.pb-70,
.py-70 {
  padding-bottom: 70px !important;
}

.pl-70,
.px-70 {
  padding-left: 70px !important;
}

.p-75 {
  padding: 75px !important;
}

.pt-75,
.py-75 {
  padding-top: 75px !important;
}

.pr-75,
.px-75 {
  padding-right: 75px !important;
}

.pb-75,
.py-75 {
  padding-bottom: 75px !important;
}

.pl-75,
.px-75 {
  padding-left: 75px !important;
}

.p-80 {
  padding: 80px !important;
}

.pt-80,
.py-80 {
  padding-top: 80px !important;
}

.pr-80,
.px-80 {
  padding-right: 80px !important;
}

.pb-80,
.py-80 {
  padding-bottom: 80px !important;
}

.pl-80,
.px-80 {
  padding-left: 80px !important;
}

.p-85 {
  padding: 85px !important;
}

.pt-85,
.py-85 {
  padding-top: 85px !important;
}

.pr-85,
.px-85 {
  padding-right: 85px !important;
}

.pb-85,
.py-85 {
  padding-bottom: 85px !important;
}

.pl-85,
.px-85 {
  padding-left: 85px !important;
}

.p-90 {
  padding: 90px !important;
}

.pt-90,
.py-90 {
  padding-top: 90px !important;
}

.pr-90,
.px-90 {
  padding-right: 90px !important;
}

.pb-90,
.py-90 {
  padding-bottom: 90px !important;
}

.pl-90,
.px-90 {
  padding-left: 90px !important;
}

.p-95 {
  padding: 95px !important;
}

.pt-95,
.py-95 {
  padding-top: 95px !important;
}

.pr-95,
.px-95 {
  padding-right: 95px !important;
}

.pb-95,
.py-95 {
  padding-bottom: 95px !important;
}

.pl-95,
.px-95 {
  padding-left: 95px !important;
}

.p-100 {
  padding: 100px !important;
}

.pt-100,
.py-100 {
  padding-top: 100px !important;
}

.pr-100,
.px-100 {
  padding-right: 100px !important;
}

.pb-100,
.py-100 {
  padding-bottom: 100px !important;
}

.pl-100,
.px-100 {
  padding-left: 100px !important;
}

.p-130 {
  padding: 130px !important;
}

.pt-130,
.py-130 {
  padding-top: 130px !important;
}

.pr-130,
.px-130 {
  padding-right: 130px !important;
}

.pb-130,
.py-130 {
  padding-bottom: 130px !important;
}

.pl-130,
.px-130 {
  padding-left: 130px !important;
}

.p-165 {
  padding: 165px !important;
}

.pt-165,
.py-165 {
  padding-top: 165px !important;
}

.pr-165,
.px-165 {
  padding-right: 165px !important;
}

.pb-165,
.py-165 {
  padding-bottom: 165px !important;
}

.pl-165,
.px-165 {
  padding-left: 165px !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -5px !important;
}

.mt-n5,
.my-n5 {
  margin-top: -5px !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -5px !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -5px !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -5px !important;
}

.m-n10 {
  margin: -10px !important;
}

.mt-n10,
.my-n10 {
  margin-top: -10px !important;
}

.mr-n10,
.mx-n10 {
  margin-right: -10px !important;
}

.mb-n10,
.my-n10 {
  margin-bottom: -10px !important;
}

.ml-n10,
.mx-n10 {
  margin-left: -10px !important;
}

.m-n15 {
  margin: -15px !important;
}

.mt-n15,
.my-n15 {
  margin-top: -15px !important;
}

.mr-n15,
.mx-n15 {
  margin-right: -15px !important;
}

.mb-n15,
.my-n15 {
  margin-bottom: -15px !important;
}

.ml-n15,
.mx-n15 {
  margin-left: -15px !important;
}

.m-n20 {
  margin: -20px !important;
}

.mt-n20,
.my-n20 {
  margin-top: -20px !important;
}

.mr-n20,
.mx-n20 {
  margin-right: -20px !important;
}

.mb-n20,
.my-n20 {
  margin-bottom: -20px !important;
}

.ml-n20,
.mx-n20 {
  margin-left: -20px !important;
}

.m-n25 {
  margin: -25px !important;
}

.mt-n25,
.my-n25 {
  margin-top: -25px !important;
}

.mr-n25,
.mx-n25 {
  margin-right: -25px !important;
}

.mb-n25,
.my-n25 {
  margin-bottom: -25px !important;
}

.ml-n25,
.mx-n25 {
  margin-left: -25px !important;
}

.m-n30 {
  margin: -30px !important;
}

.mt-n30,
.my-n30 {
  margin-top: -30px !important;
}

.mr-n30,
.mx-n30 {
  margin-right: -30px !important;
}

.mb-n30,
.my-n30 {
  margin-bottom: -30px !important;
}

.ml-n30,
.mx-n30 {
  margin-left: -30px !important;
}

.m-n35 {
  margin: -35px !important;
}

.mt-n35,
.my-n35 {
  margin-top: -35px !important;
}

.mr-n35,
.mx-n35 {
  margin-right: -35px !important;
}

.mb-n35,
.my-n35 {
  margin-bottom: -35px !important;
}

.ml-n35,
.mx-n35 {
  margin-left: -35px !important;
}

.m-n40 {
  margin: -40px !important;
}

.mt-n40,
.my-n40 {
  margin-top: -40px !important;
}

.mr-n40,
.mx-n40 {
  margin-right: -40px !important;
}

.mb-n40,
.my-n40 {
  margin-bottom: -40px !important;
}

.ml-n40,
.mx-n40 {
  margin-left: -40px !important;
}

.m-n45 {
  margin: -45px !important;
}

.mt-n45,
.my-n45 {
  margin-top: -45px !important;
}

.mr-n45,
.mx-n45 {
  margin-right: -45px !important;
}

.mb-n45,
.my-n45 {
  margin-bottom: -45px !important;
}

.ml-n45,
.mx-n45 {
  margin-left: -45px !important;
}

.m-n50 {
  margin: -50px !important;
}

.mt-n50,
.my-n50 {
  margin-top: -50px !important;
}

.mr-n50,
.mx-n50 {
  margin-right: -50px !important;
}

.mb-n50,
.my-n50 {
  margin-bottom: -50px !important;
}

.ml-n50,
.mx-n50 {
  margin-left: -50px !important;
}

.m-n55 {
  margin: -55px !important;
}

.mt-n55,
.my-n55 {
  margin-top: -55px !important;
}

.mr-n55,
.mx-n55 {
  margin-right: -55px !important;
}

.mb-n55,
.my-n55 {
  margin-bottom: -55px !important;
}

.ml-n55,
.mx-n55 {
  margin-left: -55px !important;
}

.m-n60 {
  margin: -60px !important;
}

.mt-n60,
.my-n60 {
  margin-top: -60px !important;
}

.mr-n60,
.mx-n60 {
  margin-right: -60px !important;
}

.mb-n60,
.my-n60 {
  margin-bottom: -60px !important;
}

.ml-n60,
.mx-n60 {
  margin-left: -60px !important;
}

.m-n65 {
  margin: -65px !important;
}

.mt-n65,
.my-n65 {
  margin-top: -65px !important;
}

.mr-n65,
.mx-n65 {
  margin-right: -65px !important;
}

.mb-n65,
.my-n65 {
  margin-bottom: -65px !important;
}

.ml-n65,
.mx-n65 {
  margin-left: -65px !important;
}

.m-n70 {
  margin: -70px !important;
}

.mt-n70,
.my-n70 {
  margin-top: -70px !important;
}

.mr-n70,
.mx-n70 {
  margin-right: -70px !important;
}

.mb-n70,
.my-n70 {
  margin-bottom: -70px !important;
}

.ml-n70,
.mx-n70 {
  margin-left: -70px !important;
}

.m-n75 {
  margin: -75px !important;
}

.mt-n75,
.my-n75 {
  margin-top: -75px !important;
}

.mr-n75,
.mx-n75 {
  margin-right: -75px !important;
}

.mb-n75,
.my-n75 {
  margin-bottom: -75px !important;
}

.ml-n75,
.mx-n75 {
  margin-left: -75px !important;
}

.m-n80 {
  margin: -80px !important;
}

.mt-n80,
.my-n80 {
  margin-top: -80px !important;
}

.mr-n80,
.mx-n80 {
  margin-right: -80px !important;
}

.mb-n80,
.my-n80 {
  margin-bottom: -80px !important;
}

.ml-n80,
.mx-n80 {
  margin-left: -80px !important;
}

.m-n85 {
  margin: -85px !important;
}

.mt-n85,
.my-n85 {
  margin-top: -85px !important;
}

.mr-n85,
.mx-n85 {
  margin-right: -85px !important;
}

.mb-n85,
.my-n85 {
  margin-bottom: -85px !important;
}

.ml-n85,
.mx-n85 {
  margin-left: -85px !important;
}

.m-n90 {
  margin: -90px !important;
}

.mt-n90,
.my-n90 {
  margin-top: -90px !important;
}

.mr-n90,
.mx-n90 {
  margin-right: -90px !important;
}

.mb-n90,
.my-n90 {
  margin-bottom: -90px !important;
}

.ml-n90,
.mx-n90 {
  margin-left: -90px !important;
}

.m-n95 {
  margin: -95px !important;
}

.mt-n95,
.my-n95 {
  margin-top: -95px !important;
}

.mr-n95,
.mx-n95 {
  margin-right: -95px !important;
}

.mb-n95,
.my-n95 {
  margin-bottom: -95px !important;
}

.ml-n95,
.mx-n95 {
  margin-left: -95px !important;
}

.m-n100 {
  margin: -100px !important;
}

.mt-n100,
.my-n100 {
  margin-top: -100px !important;
}

.mr-n100,
.mx-n100 {
  margin-right: -100px !important;
}

.mb-n100,
.my-n100 {
  margin-bottom: -100px !important;
}

.ml-n100,
.mx-n100 {
  margin-left: -100px !important;
}

.m-n130 {
  margin: -130px !important;
}

.mt-n130,
.my-n130 {
  margin-top: -130px !important;
}

.mr-n130,
.mx-n130 {
  margin-right: -130px !important;
}

.mb-n130,
.my-n130 {
  margin-bottom: -130px !important;
}

.ml-n130,
.mx-n130 {
  margin-left: -130px !important;
}

.m-n165 {
  margin: -165px !important;
}

.mt-n165,
.my-n165 {
  margin-top: -165px !important;
}

.mr-n165,
.mx-n165 {
  margin-right: -165px !important;
}

.mb-n165,
.my-n165 {
  margin-bottom: -165px !important;
}

.ml-n165,
.mx-n165 {
  margin-left: -165px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 5px !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5px !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5px !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5px !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5px !important;
  }
  .m-sm-10 {
    margin: 10px !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10px !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10px !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10px !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10px !important;
  }
  .m-sm-15 {
    margin: 15px !important;
  }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15px !important;
  }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15px !important;
  }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15px !important;
  }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15px !important;
  }
  .m-sm-20 {
    margin: 20px !important;
  }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 20px !important;
  }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 20px !important;
  }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 20px !important;
  }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 20px !important;
  }
  .m-sm-25 {
    margin: 25px !important;
  }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 25px !important;
  }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 25px !important;
  }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 25px !important;
  }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 25px !important;
  }
  .m-sm-30 {
    margin: 30px !important;
  }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 30px !important;
  }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 30px !important;
  }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 30px !important;
  }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 30px !important;
  }
  .m-sm-35 {
    margin: 35px !important;
  }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 35px !important;
  }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 35px !important;
  }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 35px !important;
  }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 35px !important;
  }
  .m-sm-40 {
    margin: 40px !important;
  }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 40px !important;
  }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 40px !important;
  }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 40px !important;
  }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 40px !important;
  }
  .m-sm-45 {
    margin: 45px !important;
  }
  .mt-sm-45,
  .my-sm-45 {
    margin-top: 45px !important;
  }
  .mr-sm-45,
  .mx-sm-45 {
    margin-right: 45px !important;
  }
  .mb-sm-45,
  .my-sm-45 {
    margin-bottom: 45px !important;
  }
  .ml-sm-45,
  .mx-sm-45 {
    margin-left: 45px !important;
  }
  .m-sm-50 {
    margin: 50px !important;
  }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 50px !important;
  }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 50px !important;
  }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 50px !important;
  }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 50px !important;
  }
  .m-sm-55 {
    margin: 55px !important;
  }
  .mt-sm-55,
  .my-sm-55 {
    margin-top: 55px !important;
  }
  .mr-sm-55,
  .mx-sm-55 {
    margin-right: 55px !important;
  }
  .mb-sm-55,
  .my-sm-55 {
    margin-bottom: 55px !important;
  }
  .ml-sm-55,
  .mx-sm-55 {
    margin-left: 55px !important;
  }
  .m-sm-60 {
    margin: 60px !important;
  }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 60px !important;
  }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 60px !important;
  }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 60px !important;
  }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 60px !important;
  }
  .m-sm-65 {
    margin: 65px !important;
  }
  .mt-sm-65,
  .my-sm-65 {
    margin-top: 65px !important;
  }
  .mr-sm-65,
  .mx-sm-65 {
    margin-right: 65px !important;
  }
  .mb-sm-65,
  .my-sm-65 {
    margin-bottom: 65px !important;
  }
  .ml-sm-65,
  .mx-sm-65 {
    margin-left: 65px !important;
  }
  .m-sm-70 {
    margin: 70px !important;
  }
  .mt-sm-70,
  .my-sm-70 {
    margin-top: 70px !important;
  }
  .mr-sm-70,
  .mx-sm-70 {
    margin-right: 70px !important;
  }
  .mb-sm-70,
  .my-sm-70 {
    margin-bottom: 70px !important;
  }
  .ml-sm-70,
  .mx-sm-70 {
    margin-left: 70px !important;
  }
  .m-sm-75 {
    margin: 75px !important;
  }
  .mt-sm-75,
  .my-sm-75 {
    margin-top: 75px !important;
  }
  .mr-sm-75,
  .mx-sm-75 {
    margin-right: 75px !important;
  }
  .mb-sm-75,
  .my-sm-75 {
    margin-bottom: 75px !important;
  }
  .ml-sm-75,
  .mx-sm-75 {
    margin-left: 75px !important;
  }
  .m-sm-80 {
    margin: 80px !important;
  }
  .mt-sm-80,
  .my-sm-80 {
    margin-top: 80px !important;
  }
  .mr-sm-80,
  .mx-sm-80 {
    margin-right: 80px !important;
  }
  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 80px !important;
  }
  .ml-sm-80,
  .mx-sm-80 {
    margin-left: 80px !important;
  }
  .m-sm-85 {
    margin: 85px !important;
  }
  .mt-sm-85,
  .my-sm-85 {
    margin-top: 85px !important;
  }
  .mr-sm-85,
  .mx-sm-85 {
    margin-right: 85px !important;
  }
  .mb-sm-85,
  .my-sm-85 {
    margin-bottom: 85px !important;
  }
  .ml-sm-85,
  .mx-sm-85 {
    margin-left: 85px !important;
  }
  .m-sm-90 {
    margin: 90px !important;
  }
  .mt-sm-90,
  .my-sm-90 {
    margin-top: 90px !important;
  }
  .mr-sm-90,
  .mx-sm-90 {
    margin-right: 90px !important;
  }
  .mb-sm-90,
  .my-sm-90 {
    margin-bottom: 90px !important;
  }
  .ml-sm-90,
  .mx-sm-90 {
    margin-left: 90px !important;
  }
  .m-sm-95 {
    margin: 95px !important;
  }
  .mt-sm-95,
  .my-sm-95 {
    margin-top: 95px !important;
  }
  .mr-sm-95,
  .mx-sm-95 {
    margin-right: 95px !important;
  }
  .mb-sm-95,
  .my-sm-95 {
    margin-bottom: 95px !important;
  }
  .ml-sm-95,
  .mx-sm-95 {
    margin-left: 95px !important;
  }
  .m-sm-100 {
    margin: 100px !important;
  }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 100px !important;
  }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 100px !important;
  }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 100px !important;
  }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 100px !important;
  }
  .m-sm-130 {
    margin: 130px !important;
  }
  .mt-sm-130,
  .my-sm-130 {
    margin-top: 130px !important;
  }
  .mr-sm-130,
  .mx-sm-130 {
    margin-right: 130px !important;
  }
  .mb-sm-130,
  .my-sm-130 {
    margin-bottom: 130px !important;
  }
  .ml-sm-130,
  .mx-sm-130 {
    margin-left: 130px !important;
  }
  .m-sm-165 {
    margin: 165px !important;
  }
  .mt-sm-165,
  .my-sm-165 {
    margin-top: 165px !important;
  }
  .mr-sm-165,
  .mx-sm-165 {
    margin-right: 165px !important;
  }
  .mb-sm-165,
  .my-sm-165 {
    margin-bottom: 165px !important;
  }
  .ml-sm-165,
  .mx-sm-165 {
    margin-left: 165px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 5px !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5px !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5px !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5px !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5px !important;
  }
  .p-sm-10 {
    padding: 10px !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10px !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10px !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10px !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10px !important;
  }
  .p-sm-15 {
    padding: 15px !important;
  }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15px !important;
  }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15px !important;
  }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15px !important;
  }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15px !important;
  }
  .p-sm-20 {
    padding: 20px !important;
  }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 20px !important;
  }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 20px !important;
  }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 20px !important;
  }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 20px !important;
  }
  .p-sm-25 {
    padding: 25px !important;
  }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 25px !important;
  }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 25px !important;
  }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 25px !important;
  }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 25px !important;
  }
  .p-sm-30 {
    padding: 30px !important;
  }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 30px !important;
  }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 30px !important;
  }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 30px !important;
  }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 30px !important;
  }
  .p-sm-35 {
    padding: 35px !important;
  }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 35px !important;
  }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 35px !important;
  }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 35px !important;
  }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 35px !important;
  }
  .p-sm-40 {
    padding: 40px !important;
  }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 40px !important;
  }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 40px !important;
  }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 40px !important;
  }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 40px !important;
  }
  .p-sm-45 {
    padding: 45px !important;
  }
  .pt-sm-45,
  .py-sm-45 {
    padding-top: 45px !important;
  }
  .pr-sm-45,
  .px-sm-45 {
    padding-right: 45px !important;
  }
  .pb-sm-45,
  .py-sm-45 {
    padding-bottom: 45px !important;
  }
  .pl-sm-45,
  .px-sm-45 {
    padding-left: 45px !important;
  }
  .p-sm-50 {
    padding: 50px !important;
  }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 50px !important;
  }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 50px !important;
  }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 50px !important;
  }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 50px !important;
  }
  .p-sm-55 {
    padding: 55px !important;
  }
  .pt-sm-55,
  .py-sm-55 {
    padding-top: 55px !important;
  }
  .pr-sm-55,
  .px-sm-55 {
    padding-right: 55px !important;
  }
  .pb-sm-55,
  .py-sm-55 {
    padding-bottom: 55px !important;
  }
  .pl-sm-55,
  .px-sm-55 {
    padding-left: 55px !important;
  }
  .p-sm-60 {
    padding: 60px !important;
  }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 60px !important;
  }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 60px !important;
  }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 60px !important;
  }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 60px !important;
  }
  .p-sm-65 {
    padding: 65px !important;
  }
  .pt-sm-65,
  .py-sm-65 {
    padding-top: 65px !important;
  }
  .pr-sm-65,
  .px-sm-65 {
    padding-right: 65px !important;
  }
  .pb-sm-65,
  .py-sm-65 {
    padding-bottom: 65px !important;
  }
  .pl-sm-65,
  .px-sm-65 {
    padding-left: 65px !important;
  }
  .p-sm-70 {
    padding: 70px !important;
  }
  .pt-sm-70,
  .py-sm-70 {
    padding-top: 70px !important;
  }
  .pr-sm-70,
  .px-sm-70 {
    padding-right: 70px !important;
  }
  .pb-sm-70,
  .py-sm-70 {
    padding-bottom: 70px !important;
  }
  .pl-sm-70,
  .px-sm-70 {
    padding-left: 70px !important;
  }
  .p-sm-75 {
    padding: 75px !important;
  }
  .pt-sm-75,
  .py-sm-75 {
    padding-top: 75px !important;
  }
  .pr-sm-75,
  .px-sm-75 {
    padding-right: 75px !important;
  }
  .pb-sm-75,
  .py-sm-75 {
    padding-bottom: 75px !important;
  }
  .pl-sm-75,
  .px-sm-75 {
    padding-left: 75px !important;
  }
  .p-sm-80 {
    padding: 80px !important;
  }
  .pt-sm-80,
  .py-sm-80 {
    padding-top: 80px !important;
  }
  .pr-sm-80,
  .px-sm-80 {
    padding-right: 80px !important;
  }
  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 80px !important;
  }
  .pl-sm-80,
  .px-sm-80 {
    padding-left: 80px !important;
  }
  .p-sm-85 {
    padding: 85px !important;
  }
  .pt-sm-85,
  .py-sm-85 {
    padding-top: 85px !important;
  }
  .pr-sm-85,
  .px-sm-85 {
    padding-right: 85px !important;
  }
  .pb-sm-85,
  .py-sm-85 {
    padding-bottom: 85px !important;
  }
  .pl-sm-85,
  .px-sm-85 {
    padding-left: 85px !important;
  }
  .p-sm-90 {
    padding: 90px !important;
  }
  .pt-sm-90,
  .py-sm-90 {
    padding-top: 90px !important;
  }
  .pr-sm-90,
  .px-sm-90 {
    padding-right: 90px !important;
  }
  .pb-sm-90,
  .py-sm-90 {
    padding-bottom: 90px !important;
  }
  .pl-sm-90,
  .px-sm-90 {
    padding-left: 90px !important;
  }
  .p-sm-95 {
    padding: 95px !important;
  }
  .pt-sm-95,
  .py-sm-95 {
    padding-top: 95px !important;
  }
  .pr-sm-95,
  .px-sm-95 {
    padding-right: 95px !important;
  }
  .pb-sm-95,
  .py-sm-95 {
    padding-bottom: 95px !important;
  }
  .pl-sm-95,
  .px-sm-95 {
    padding-left: 95px !important;
  }
  .p-sm-100 {
    padding: 100px !important;
  }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 100px !important;
  }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 100px !important;
  }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 100px !important;
  }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 100px !important;
  }
  .p-sm-130 {
    padding: 130px !important;
  }
  .pt-sm-130,
  .py-sm-130 {
    padding-top: 130px !important;
  }
  .pr-sm-130,
  .px-sm-130 {
    padding-right: 130px !important;
  }
  .pb-sm-130,
  .py-sm-130 {
    padding-bottom: 130px !important;
  }
  .pl-sm-130,
  .px-sm-130 {
    padding-left: 130px !important;
  }
  .p-sm-165 {
    padding: 165px !important;
  }
  .pt-sm-165,
  .py-sm-165 {
    padding-top: 165px !important;
  }
  .pr-sm-165,
  .px-sm-165 {
    padding-right: 165px !important;
  }
  .pb-sm-165,
  .py-sm-165 {
    padding-bottom: 165px !important;
  }
  .pl-sm-165,
  .px-sm-165 {
    padding-left: 165px !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -5px !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5px !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5px !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5px !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5px !important;
  }
  .m-sm-n10 {
    margin: -10px !important;
  }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10px !important;
  }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10px !important;
  }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10px !important;
  }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10px !important;
  }
  .m-sm-n15 {
    margin: -15px !important;
  }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -15px !important;
  }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -15px !important;
  }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -15px !important;
  }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -15px !important;
  }
  .m-sm-n20 {
    margin: -20px !important;
  }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -20px !important;
  }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -20px !important;
  }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -20px !important;
  }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -20px !important;
  }
  .m-sm-n25 {
    margin: -25px !important;
  }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -25px !important;
  }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -25px !important;
  }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -25px !important;
  }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -25px !important;
  }
  .m-sm-n30 {
    margin: -30px !important;
  }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -30px !important;
  }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -30px !important;
  }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -30px !important;
  }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -30px !important;
  }
  .m-sm-n35 {
    margin: -35px !important;
  }
  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -35px !important;
  }
  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -35px !important;
  }
  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -35px !important;
  }
  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -35px !important;
  }
  .m-sm-n40 {
    margin: -40px !important;
  }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -40px !important;
  }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -40px !important;
  }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -40px !important;
  }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -40px !important;
  }
  .m-sm-n45 {
    margin: -45px !important;
  }
  .mt-sm-n45,
  .my-sm-n45 {
    margin-top: -45px !important;
  }
  .mr-sm-n45,
  .mx-sm-n45 {
    margin-right: -45px !important;
  }
  .mb-sm-n45,
  .my-sm-n45 {
    margin-bottom: -45px !important;
  }
  .ml-sm-n45,
  .mx-sm-n45 {
    margin-left: -45px !important;
  }
  .m-sm-n50 {
    margin: -50px !important;
  }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -50px !important;
  }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -50px !important;
  }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -50px !important;
  }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -50px !important;
  }
  .m-sm-n55 {
    margin: -55px !important;
  }
  .mt-sm-n55,
  .my-sm-n55 {
    margin-top: -55px !important;
  }
  .mr-sm-n55,
  .mx-sm-n55 {
    margin-right: -55px !important;
  }
  .mb-sm-n55,
  .my-sm-n55 {
    margin-bottom: -55px !important;
  }
  .ml-sm-n55,
  .mx-sm-n55 {
    margin-left: -55px !important;
  }
  .m-sm-n60 {
    margin: -60px !important;
  }
  .mt-sm-n60,
  .my-sm-n60 {
    margin-top: -60px !important;
  }
  .mr-sm-n60,
  .mx-sm-n60 {
    margin-right: -60px !important;
  }
  .mb-sm-n60,
  .my-sm-n60 {
    margin-bottom: -60px !important;
  }
  .ml-sm-n60,
  .mx-sm-n60 {
    margin-left: -60px !important;
  }
  .m-sm-n65 {
    margin: -65px !important;
  }
  .mt-sm-n65,
  .my-sm-n65 {
    margin-top: -65px !important;
  }
  .mr-sm-n65,
  .mx-sm-n65 {
    margin-right: -65px !important;
  }
  .mb-sm-n65,
  .my-sm-n65 {
    margin-bottom: -65px !important;
  }
  .ml-sm-n65,
  .mx-sm-n65 {
    margin-left: -65px !important;
  }
  .m-sm-n70 {
    margin: -70px !important;
  }
  .mt-sm-n70,
  .my-sm-n70 {
    margin-top: -70px !important;
  }
  .mr-sm-n70,
  .mx-sm-n70 {
    margin-right: -70px !important;
  }
  .mb-sm-n70,
  .my-sm-n70 {
    margin-bottom: -70px !important;
  }
  .ml-sm-n70,
  .mx-sm-n70 {
    margin-left: -70px !important;
  }
  .m-sm-n75 {
    margin: -75px !important;
  }
  .mt-sm-n75,
  .my-sm-n75 {
    margin-top: -75px !important;
  }
  .mr-sm-n75,
  .mx-sm-n75 {
    margin-right: -75px !important;
  }
  .mb-sm-n75,
  .my-sm-n75 {
    margin-bottom: -75px !important;
  }
  .ml-sm-n75,
  .mx-sm-n75 {
    margin-left: -75px !important;
  }
  .m-sm-n80 {
    margin: -80px !important;
  }
  .mt-sm-n80,
  .my-sm-n80 {
    margin-top: -80px !important;
  }
  .mr-sm-n80,
  .mx-sm-n80 {
    margin-right: -80px !important;
  }
  .mb-sm-n80,
  .my-sm-n80 {
    margin-bottom: -80px !important;
  }
  .ml-sm-n80,
  .mx-sm-n80 {
    margin-left: -80px !important;
  }
  .m-sm-n85 {
    margin: -85px !important;
  }
  .mt-sm-n85,
  .my-sm-n85 {
    margin-top: -85px !important;
  }
  .mr-sm-n85,
  .mx-sm-n85 {
    margin-right: -85px !important;
  }
  .mb-sm-n85,
  .my-sm-n85 {
    margin-bottom: -85px !important;
  }
  .ml-sm-n85,
  .mx-sm-n85 {
    margin-left: -85px !important;
  }
  .m-sm-n90 {
    margin: -90px !important;
  }
  .mt-sm-n90,
  .my-sm-n90 {
    margin-top: -90px !important;
  }
  .mr-sm-n90,
  .mx-sm-n90 {
    margin-right: -90px !important;
  }
  .mb-sm-n90,
  .my-sm-n90 {
    margin-bottom: -90px !important;
  }
  .ml-sm-n90,
  .mx-sm-n90 {
    margin-left: -90px !important;
  }
  .m-sm-n95 {
    margin: -95px !important;
  }
  .mt-sm-n95,
  .my-sm-n95 {
    margin-top: -95px !important;
  }
  .mr-sm-n95,
  .mx-sm-n95 {
    margin-right: -95px !important;
  }
  .mb-sm-n95,
  .my-sm-n95 {
    margin-bottom: -95px !important;
  }
  .ml-sm-n95,
  .mx-sm-n95 {
    margin-left: -95px !important;
  }
  .m-sm-n100 {
    margin: -100px !important;
  }
  .mt-sm-n100,
  .my-sm-n100 {
    margin-top: -100px !important;
  }
  .mr-sm-n100,
  .mx-sm-n100 {
    margin-right: -100px !important;
  }
  .mb-sm-n100,
  .my-sm-n100 {
    margin-bottom: -100px !important;
  }
  .ml-sm-n100,
  .mx-sm-n100 {
    margin-left: -100px !important;
  }
  .m-sm-n130 {
    margin: -130px !important;
  }
  .mt-sm-n130,
  .my-sm-n130 {
    margin-top: -130px !important;
  }
  .mr-sm-n130,
  .mx-sm-n130 {
    margin-right: -130px !important;
  }
  .mb-sm-n130,
  .my-sm-n130 {
    margin-bottom: -130px !important;
  }
  .ml-sm-n130,
  .mx-sm-n130 {
    margin-left: -130px !important;
  }
  .m-sm-n165 {
    margin: -165px !important;
  }
  .mt-sm-n165,
  .my-sm-n165 {
    margin-top: -165px !important;
  }
  .mr-sm-n165,
  .mx-sm-n165 {
    margin-right: -165px !important;
  }
  .mb-sm-n165,
  .my-sm-n165 {
    margin-bottom: -165px !important;
  }
  .ml-sm-n165,
  .mx-sm-n165 {
    margin-left: -165px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 5px !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5px !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5px !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5px !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5px !important;
  }
  .m-md-10 {
    margin: 10px !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10px !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10px !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10px !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10px !important;
  }
  .m-md-15 {
    margin: 15px !important;
  }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15px !important;
  }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15px !important;
  }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15px !important;
  }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15px !important;
  }
  .m-md-20 {
    margin: 20px !important;
  }
  .mt-md-20,
  .my-md-20 {
    margin-top: 20px !important;
  }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 20px !important;
  }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 20px !important;
  }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 20px !important;
  }
  .m-md-25 {
    margin: 25px !important;
  }
  .mt-md-25,
  .my-md-25 {
    margin-top: 25px !important;
  }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 25px !important;
  }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 25px !important;
  }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 25px !important;
  }
  .m-md-30 {
    margin: 30px !important;
  }
  .mt-md-30,
  .my-md-30 {
    margin-top: 30px !important;
  }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 30px !important;
  }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 30px !important;
  }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 30px !important;
  }
  .m-md-35 {
    margin: 35px !important;
  }
  .mt-md-35,
  .my-md-35 {
    margin-top: 35px !important;
  }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 35px !important;
  }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 35px !important;
  }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 35px !important;
  }
  .m-md-40 {
    margin: 40px !important;
  }
  .mt-md-40,
  .my-md-40 {
    margin-top: 40px !important;
  }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 40px !important;
  }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 40px !important;
  }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 40px !important;
  }
  .m-md-45 {
    margin: 45px !important;
  }
  .mt-md-45,
  .my-md-45 {
    margin-top: 45px !important;
  }
  .mr-md-45,
  .mx-md-45 {
    margin-right: 45px !important;
  }
  .mb-md-45,
  .my-md-45 {
    margin-bottom: 45px !important;
  }
  .ml-md-45,
  .mx-md-45 {
    margin-left: 45px !important;
  }
  .m-md-50 {
    margin: 50px !important;
  }
  .mt-md-50,
  .my-md-50 {
    margin-top: 50px !important;
  }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 50px !important;
  }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 50px !important;
  }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 50px !important;
  }
  .m-md-55 {
    margin: 55px !important;
  }
  .mt-md-55,
  .my-md-55 {
    margin-top: 55px !important;
  }
  .mr-md-55,
  .mx-md-55 {
    margin-right: 55px !important;
  }
  .mb-md-55,
  .my-md-55 {
    margin-bottom: 55px !important;
  }
  .ml-md-55,
  .mx-md-55 {
    margin-left: 55px !important;
  }
  .m-md-60 {
    margin: 60px !important;
  }
  .mt-md-60,
  .my-md-60 {
    margin-top: 60px !important;
  }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 60px !important;
  }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 60px !important;
  }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 60px !important;
  }
  .m-md-65 {
    margin: 65px !important;
  }
  .mt-md-65,
  .my-md-65 {
    margin-top: 65px !important;
  }
  .mr-md-65,
  .mx-md-65 {
    margin-right: 65px !important;
  }
  .mb-md-65,
  .my-md-65 {
    margin-bottom: 65px !important;
  }
  .ml-md-65,
  .mx-md-65 {
    margin-left: 65px !important;
  }
  .m-md-70 {
    margin: 70px !important;
  }
  .mt-md-70,
  .my-md-70 {
    margin-top: 70px !important;
  }
  .mr-md-70,
  .mx-md-70 {
    margin-right: 70px !important;
  }
  .mb-md-70,
  .my-md-70 {
    margin-bottom: 70px !important;
  }
  .ml-md-70,
  .mx-md-70 {
    margin-left: 70px !important;
  }
  .m-md-75 {
    margin: 75px !important;
  }
  .mt-md-75,
  .my-md-75 {
    margin-top: 75px !important;
  }
  .mr-md-75,
  .mx-md-75 {
    margin-right: 75px !important;
  }
  .mb-md-75,
  .my-md-75 {
    margin-bottom: 75px !important;
  }
  .ml-md-75,
  .mx-md-75 {
    margin-left: 75px !important;
  }
  .m-md-80 {
    margin: 80px !important;
  }
  .mt-md-80,
  .my-md-80 {
    margin-top: 80px !important;
  }
  .mr-md-80,
  .mx-md-80 {
    margin-right: 80px !important;
  }
  .mb-md-80,
  .my-md-80 {
    margin-bottom: 80px !important;
  }
  .ml-md-80,
  .mx-md-80 {
    margin-left: 80px !important;
  }
  .m-md-85 {
    margin: 85px !important;
  }
  .mt-md-85,
  .my-md-85 {
    margin-top: 85px !important;
  }
  .mr-md-85,
  .mx-md-85 {
    margin-right: 85px !important;
  }
  .mb-md-85,
  .my-md-85 {
    margin-bottom: 85px !important;
  }
  .ml-md-85,
  .mx-md-85 {
    margin-left: 85px !important;
  }
  .m-md-90 {
    margin: 90px !important;
  }
  .mt-md-90,
  .my-md-90 {
    margin-top: 90px !important;
  }
  .mr-md-90,
  .mx-md-90 {
    margin-right: 90px !important;
  }
  .mb-md-90,
  .my-md-90 {
    margin-bottom: 90px !important;
  }
  .ml-md-90,
  .mx-md-90 {
    margin-left: 90px !important;
  }
  .m-md-95 {
    margin: 95px !important;
  }
  .mt-md-95,
  .my-md-95 {
    margin-top: 95px !important;
  }
  .mr-md-95,
  .mx-md-95 {
    margin-right: 95px !important;
  }
  .mb-md-95,
  .my-md-95 {
    margin-bottom: 95px !important;
  }
  .ml-md-95,
  .mx-md-95 {
    margin-left: 95px !important;
  }
  .m-md-100 {
    margin: 100px !important;
  }
  .mt-md-100,
  .my-md-100 {
    margin-top: 100px !important;
  }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 100px !important;
  }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 100px !important;
  }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 100px !important;
  }
  .m-md-130 {
    margin: 130px !important;
  }
  .mt-md-130,
  .my-md-130 {
    margin-top: 130px !important;
  }
  .mr-md-130,
  .mx-md-130 {
    margin-right: 130px !important;
  }
  .mb-md-130,
  .my-md-130 {
    margin-bottom: 130px !important;
  }
  .ml-md-130,
  .mx-md-130 {
    margin-left: 130px !important;
  }
  .m-md-165 {
    margin: 165px !important;
  }
  .mt-md-165,
  .my-md-165 {
    margin-top: 165px !important;
  }
  .mr-md-165,
  .mx-md-165 {
    margin-right: 165px !important;
  }
  .mb-md-165,
  .my-md-165 {
    margin-bottom: 165px !important;
  }
  .ml-md-165,
  .mx-md-165 {
    margin-left: 165px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 5px !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5px !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5px !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5px !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5px !important;
  }
  .p-md-10 {
    padding: 10px !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10px !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10px !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10px !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10px !important;
  }
  .p-md-15 {
    padding: 15px !important;
  }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15px !important;
  }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15px !important;
  }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15px !important;
  }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15px !important;
  }
  .p-md-20 {
    padding: 20px !important;
  }
  .pt-md-20,
  .py-md-20 {
    padding-top: 20px !important;
  }
  .pr-md-20,
  .px-md-20 {
    padding-right: 20px !important;
  }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 20px !important;
  }
  .pl-md-20,
  .px-md-20 {
    padding-left: 20px !important;
  }
  .p-md-25 {
    padding: 25px !important;
  }
  .pt-md-25,
  .py-md-25 {
    padding-top: 25px !important;
  }
  .pr-md-25,
  .px-md-25 {
    padding-right: 25px !important;
  }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 25px !important;
  }
  .pl-md-25,
  .px-md-25 {
    padding-left: 25px !important;
  }
  .p-md-30 {
    padding: 30px !important;
  }
  .pt-md-30,
  .py-md-30 {
    padding-top: 30px !important;
  }
  .pr-md-30,
  .px-md-30 {
    padding-right: 30px !important;
  }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 30px !important;
  }
  .pl-md-30,
  .px-md-30 {
    padding-left: 30px !important;
  }
  .p-md-35 {
    padding: 35px !important;
  }
  .pt-md-35,
  .py-md-35 {
    padding-top: 35px !important;
  }
  .pr-md-35,
  .px-md-35 {
    padding-right: 35px !important;
  }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 35px !important;
  }
  .pl-md-35,
  .px-md-35 {
    padding-left: 35px !important;
  }
  .p-md-40 {
    padding: 40px !important;
  }
  .pt-md-40,
  .py-md-40 {
    padding-top: 40px !important;
  }
  .pr-md-40,
  .px-md-40 {
    padding-right: 40px !important;
  }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 40px !important;
  }
  .pl-md-40,
  .px-md-40 {
    padding-left: 40px !important;
  }
  .p-md-45 {
    padding: 45px !important;
  }
  .pt-md-45,
  .py-md-45 {
    padding-top: 45px !important;
  }
  .pr-md-45,
  .px-md-45 {
    padding-right: 45px !important;
  }
  .pb-md-45,
  .py-md-45 {
    padding-bottom: 45px !important;
  }
  .pl-md-45,
  .px-md-45 {
    padding-left: 45px !important;
  }
  .p-md-50 {
    padding: 50px !important;
  }
  .pt-md-50,
  .py-md-50 {
    padding-top: 50px !important;
  }
  .pr-md-50,
  .px-md-50 {
    padding-right: 50px !important;
  }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 50px !important;
  }
  .pl-md-50,
  .px-md-50 {
    padding-left: 50px !important;
  }
  .p-md-55 {
    padding: 55px !important;
  }
  .pt-md-55,
  .py-md-55 {
    padding-top: 55px !important;
  }
  .pr-md-55,
  .px-md-55 {
    padding-right: 55px !important;
  }
  .pb-md-55,
  .py-md-55 {
    padding-bottom: 55px !important;
  }
  .pl-md-55,
  .px-md-55 {
    padding-left: 55px !important;
  }
  .p-md-60 {
    padding: 60px !important;
  }
  .pt-md-60,
  .py-md-60 {
    padding-top: 60px !important;
  }
  .pr-md-60,
  .px-md-60 {
    padding-right: 60px !important;
  }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 60px !important;
  }
  .pl-md-60,
  .px-md-60 {
    padding-left: 60px !important;
  }
  .p-md-65 {
    padding: 65px !important;
  }
  .pt-md-65,
  .py-md-65 {
    padding-top: 65px !important;
  }
  .pr-md-65,
  .px-md-65 {
    padding-right: 65px !important;
  }
  .pb-md-65,
  .py-md-65 {
    padding-bottom: 65px !important;
  }
  .pl-md-65,
  .px-md-65 {
    padding-left: 65px !important;
  }
  .p-md-70 {
    padding: 70px !important;
  }
  .pt-md-70,
  .py-md-70 {
    padding-top: 70px !important;
  }
  .pr-md-70,
  .px-md-70 {
    padding-right: 70px !important;
  }
  .pb-md-70,
  .py-md-70 {
    padding-bottom: 70px !important;
  }
  .pl-md-70,
  .px-md-70 {
    padding-left: 70px !important;
  }
  .p-md-75 {
    padding: 75px !important;
  }
  .pt-md-75,
  .py-md-75 {
    padding-top: 75px !important;
  }
  .pr-md-75,
  .px-md-75 {
    padding-right: 75px !important;
  }
  .pb-md-75,
  .py-md-75 {
    padding-bottom: 75px !important;
  }
  .pl-md-75,
  .px-md-75 {
    padding-left: 75px !important;
  }
  .p-md-80 {
    padding: 80px !important;
  }
  .pt-md-80,
  .py-md-80 {
    padding-top: 80px !important;
  }
  .pr-md-80,
  .px-md-80 {
    padding-right: 80px !important;
  }
  .pb-md-80,
  .py-md-80 {
    padding-bottom: 80px !important;
  }
  .pl-md-80,
  .px-md-80 {
    padding-left: 80px !important;
  }
  .p-md-85 {
    padding: 85px !important;
  }
  .pt-md-85,
  .py-md-85 {
    padding-top: 85px !important;
  }
  .pr-md-85,
  .px-md-85 {
    padding-right: 85px !important;
  }
  .pb-md-85,
  .py-md-85 {
    padding-bottom: 85px !important;
  }
  .pl-md-85,
  .px-md-85 {
    padding-left: 85px !important;
  }
  .p-md-90 {
    padding: 90px !important;
  }
  .pt-md-90,
  .py-md-90 {
    padding-top: 90px !important;
  }
  .pr-md-90,
  .px-md-90 {
    padding-right: 90px !important;
  }
  .pb-md-90,
  .py-md-90 {
    padding-bottom: 90px !important;
  }
  .pl-md-90,
  .px-md-90 {
    padding-left: 90px !important;
  }
  .p-md-95 {
    padding: 95px !important;
  }
  .pt-md-95,
  .py-md-95 {
    padding-top: 95px !important;
  }
  .pr-md-95,
  .px-md-95 {
    padding-right: 95px !important;
  }
  .pb-md-95,
  .py-md-95 {
    padding-bottom: 95px !important;
  }
  .pl-md-95,
  .px-md-95 {
    padding-left: 95px !important;
  }
  .p-md-100 {
    padding: 100px !important;
  }
  .pt-md-100,
  .py-md-100 {
    padding-top: 100px !important;
  }
  .pr-md-100,
  .px-md-100 {
    padding-right: 100px !important;
  }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 100px !important;
  }
  .pl-md-100,
  .px-md-100 {
    padding-left: 100px !important;
  }
  .p-md-130 {
    padding: 130px !important;
  }
  .pt-md-130,
  .py-md-130 {
    padding-top: 130px !important;
  }
  .pr-md-130,
  .px-md-130 {
    padding-right: 130px !important;
  }
  .pb-md-130,
  .py-md-130 {
    padding-bottom: 130px !important;
  }
  .pl-md-130,
  .px-md-130 {
    padding-left: 130px !important;
  }
  .p-md-165 {
    padding: 165px !important;
  }
  .pt-md-165,
  .py-md-165 {
    padding-top: 165px !important;
  }
  .pr-md-165,
  .px-md-165 {
    padding-right: 165px !important;
  }
  .pb-md-165,
  .py-md-165 {
    padding-bottom: 165px !important;
  }
  .pl-md-165,
  .px-md-165 {
    padding-left: 165px !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -5px !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5px !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5px !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5px !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5px !important;
  }
  .m-md-n10 {
    margin: -10px !important;
  }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10px !important;
  }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10px !important;
  }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10px !important;
  }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10px !important;
  }
  .m-md-n15 {
    margin: -15px !important;
  }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -15px !important;
  }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -15px !important;
  }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -15px !important;
  }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -15px !important;
  }
  .m-md-n20 {
    margin: -20px !important;
  }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -20px !important;
  }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -20px !important;
  }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -20px !important;
  }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -20px !important;
  }
  .m-md-n25 {
    margin: -25px !important;
  }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -25px !important;
  }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -25px !important;
  }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -25px !important;
  }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -25px !important;
  }
  .m-md-n30 {
    margin: -30px !important;
  }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -30px !important;
  }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -30px !important;
  }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -30px !important;
  }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -30px !important;
  }
  .m-md-n35 {
    margin: -35px !important;
  }
  .mt-md-n35,
  .my-md-n35 {
    margin-top: -35px !important;
  }
  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -35px !important;
  }
  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -35px !important;
  }
  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -35px !important;
  }
  .m-md-n40 {
    margin: -40px !important;
  }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -40px !important;
  }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -40px !important;
  }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -40px !important;
  }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -40px !important;
  }
  .m-md-n45 {
    margin: -45px !important;
  }
  .mt-md-n45,
  .my-md-n45 {
    margin-top: -45px !important;
  }
  .mr-md-n45,
  .mx-md-n45 {
    margin-right: -45px !important;
  }
  .mb-md-n45,
  .my-md-n45 {
    margin-bottom: -45px !important;
  }
  .ml-md-n45,
  .mx-md-n45 {
    margin-left: -45px !important;
  }
  .m-md-n50 {
    margin: -50px !important;
  }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -50px !important;
  }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -50px !important;
  }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -50px !important;
  }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -50px !important;
  }
  .m-md-n55 {
    margin: -55px !important;
  }
  .mt-md-n55,
  .my-md-n55 {
    margin-top: -55px !important;
  }
  .mr-md-n55,
  .mx-md-n55 {
    margin-right: -55px !important;
  }
  .mb-md-n55,
  .my-md-n55 {
    margin-bottom: -55px !important;
  }
  .ml-md-n55,
  .mx-md-n55 {
    margin-left: -55px !important;
  }
  .m-md-n60 {
    margin: -60px !important;
  }
  .mt-md-n60,
  .my-md-n60 {
    margin-top: -60px !important;
  }
  .mr-md-n60,
  .mx-md-n60 {
    margin-right: -60px !important;
  }
  .mb-md-n60,
  .my-md-n60 {
    margin-bottom: -60px !important;
  }
  .ml-md-n60,
  .mx-md-n60 {
    margin-left: -60px !important;
  }
  .m-md-n65 {
    margin: -65px !important;
  }
  .mt-md-n65,
  .my-md-n65 {
    margin-top: -65px !important;
  }
  .mr-md-n65,
  .mx-md-n65 {
    margin-right: -65px !important;
  }
  .mb-md-n65,
  .my-md-n65 {
    margin-bottom: -65px !important;
  }
  .ml-md-n65,
  .mx-md-n65 {
    margin-left: -65px !important;
  }
  .m-md-n70 {
    margin: -70px !important;
  }
  .mt-md-n70,
  .my-md-n70 {
    margin-top: -70px !important;
  }
  .mr-md-n70,
  .mx-md-n70 {
    margin-right: -70px !important;
  }
  .mb-md-n70,
  .my-md-n70 {
    margin-bottom: -70px !important;
  }
  .ml-md-n70,
  .mx-md-n70 {
    margin-left: -70px !important;
  }
  .m-md-n75 {
    margin: -75px !important;
  }
  .mt-md-n75,
  .my-md-n75 {
    margin-top: -75px !important;
  }
  .mr-md-n75,
  .mx-md-n75 {
    margin-right: -75px !important;
  }
  .mb-md-n75,
  .my-md-n75 {
    margin-bottom: -75px !important;
  }
  .ml-md-n75,
  .mx-md-n75 {
    margin-left: -75px !important;
  }
  .m-md-n80 {
    margin: -80px !important;
  }
  .mt-md-n80,
  .my-md-n80 {
    margin-top: -80px !important;
  }
  .mr-md-n80,
  .mx-md-n80 {
    margin-right: -80px !important;
  }
  .mb-md-n80,
  .my-md-n80 {
    margin-bottom: -80px !important;
  }
  .ml-md-n80,
  .mx-md-n80 {
    margin-left: -80px !important;
  }
  .m-md-n85 {
    margin: -85px !important;
  }
  .mt-md-n85,
  .my-md-n85 {
    margin-top: -85px !important;
  }
  .mr-md-n85,
  .mx-md-n85 {
    margin-right: -85px !important;
  }
  .mb-md-n85,
  .my-md-n85 {
    margin-bottom: -85px !important;
  }
  .ml-md-n85,
  .mx-md-n85 {
    margin-left: -85px !important;
  }
  .m-md-n90 {
    margin: -90px !important;
  }
  .mt-md-n90,
  .my-md-n90 {
    margin-top: -90px !important;
  }
  .mr-md-n90,
  .mx-md-n90 {
    margin-right: -90px !important;
  }
  .mb-md-n90,
  .my-md-n90 {
    margin-bottom: -90px !important;
  }
  .ml-md-n90,
  .mx-md-n90 {
    margin-left: -90px !important;
  }
  .m-md-n95 {
    margin: -95px !important;
  }
  .mt-md-n95,
  .my-md-n95 {
    margin-top: -95px !important;
  }
  .mr-md-n95,
  .mx-md-n95 {
    margin-right: -95px !important;
  }
  .mb-md-n95,
  .my-md-n95 {
    margin-bottom: -95px !important;
  }
  .ml-md-n95,
  .mx-md-n95 {
    margin-left: -95px !important;
  }
  .m-md-n100 {
    margin: -100px !important;
  }
  .mt-md-n100,
  .my-md-n100 {
    margin-top: -100px !important;
  }
  .mr-md-n100,
  .mx-md-n100 {
    margin-right: -100px !important;
  }
  .mb-md-n100,
  .my-md-n100 {
    margin-bottom: -100px !important;
  }
  .ml-md-n100,
  .mx-md-n100 {
    margin-left: -100px !important;
  }
  .m-md-n130 {
    margin: -130px !important;
  }
  .mt-md-n130,
  .my-md-n130 {
    margin-top: -130px !important;
  }
  .mr-md-n130,
  .mx-md-n130 {
    margin-right: -130px !important;
  }
  .mb-md-n130,
  .my-md-n130 {
    margin-bottom: -130px !important;
  }
  .ml-md-n130,
  .mx-md-n130 {
    margin-left: -130px !important;
  }
  .m-md-n165 {
    margin: -165px !important;
  }
  .mt-md-n165,
  .my-md-n165 {
    margin-top: -165px !important;
  }
  .mr-md-n165,
  .mx-md-n165 {
    margin-right: -165px !important;
  }
  .mb-md-n165,
  .my-md-n165 {
    margin-bottom: -165px !important;
  }
  .ml-md-n165,
  .mx-md-n165 {
    margin-left: -165px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 5px !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5px !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5px !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5px !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5px !important;
  }
  .m-lg-10 {
    margin: 10px !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10px !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10px !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10px !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10px !important;
  }
  .m-lg-15 {
    margin: 15px !important;
  }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15px !important;
  }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15px !important;
  }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15px !important;
  }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15px !important;
  }
  .m-lg-20 {
    margin: 20px !important;
  }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 20px !important;
  }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 20px !important;
  }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 20px !important;
  }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 20px !important;
  }
  .m-lg-25 {
    margin: 25px !important;
  }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 25px !important;
  }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 25px !important;
  }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 25px !important;
  }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 25px !important;
  }
  .m-lg-30 {
    margin: 30px !important;
  }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 30px !important;
  }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 30px !important;
  }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 30px !important;
  }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 30px !important;
  }
  .m-lg-35 {
    margin: 35px !important;
  }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 35px !important;
  }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 35px !important;
  }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 35px !important;
  }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 35px !important;
  }
  .m-lg-40 {
    margin: 40px !important;
  }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 40px !important;
  }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 40px !important;
  }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 40px !important;
  }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 40px !important;
  }
  .m-lg-45 {
    margin: 45px !important;
  }
  .mt-lg-45,
  .my-lg-45 {
    margin-top: 45px !important;
  }
  .mr-lg-45,
  .mx-lg-45 {
    margin-right: 45px !important;
  }
  .mb-lg-45,
  .my-lg-45 {
    margin-bottom: 45px !important;
  }
  .ml-lg-45,
  .mx-lg-45 {
    margin-left: 45px !important;
  }
  .m-lg-50 {
    margin: 50px !important;
  }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 50px !important;
  }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 50px !important;
  }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 50px !important;
  }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 50px !important;
  }
  .m-lg-55 {
    margin: 55px !important;
  }
  .mt-lg-55,
  .my-lg-55 {
    margin-top: 55px !important;
  }
  .mr-lg-55,
  .mx-lg-55 {
    margin-right: 55px !important;
  }
  .mb-lg-55,
  .my-lg-55 {
    margin-bottom: 55px !important;
  }
  .ml-lg-55,
  .mx-lg-55 {
    margin-left: 55px !important;
  }
  .m-lg-60 {
    margin: 60px !important;
  }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 60px !important;
  }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 60px !important;
  }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 60px !important;
  }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 60px !important;
  }
  .m-lg-65 {
    margin: 65px !important;
  }
  .mt-lg-65,
  .my-lg-65 {
    margin-top: 65px !important;
  }
  .mr-lg-65,
  .mx-lg-65 {
    margin-right: 65px !important;
  }
  .mb-lg-65,
  .my-lg-65 {
    margin-bottom: 65px !important;
  }
  .ml-lg-65,
  .mx-lg-65 {
    margin-left: 65px !important;
  }
  .m-lg-70 {
    margin: 70px !important;
  }
  .mt-lg-70,
  .my-lg-70 {
    margin-top: 70px !important;
  }
  .mr-lg-70,
  .mx-lg-70 {
    margin-right: 70px !important;
  }
  .mb-lg-70,
  .my-lg-70 {
    margin-bottom: 70px !important;
  }
  .ml-lg-70,
  .mx-lg-70 {
    margin-left: 70px !important;
  }
  .m-lg-75 {
    margin: 75px !important;
  }
  .mt-lg-75,
  .my-lg-75 {
    margin-top: 75px !important;
  }
  .mr-lg-75,
  .mx-lg-75 {
    margin-right: 75px !important;
  }
  .mb-lg-75,
  .my-lg-75 {
    margin-bottom: 75px !important;
  }
  .ml-lg-75,
  .mx-lg-75 {
    margin-left: 75px !important;
  }
  .m-lg-80 {
    margin: 80px !important;
  }
  .mt-lg-80,
  .my-lg-80 {
    margin-top: 80px !important;
  }
  .mr-lg-80,
  .mx-lg-80 {
    margin-right: 80px !important;
  }
  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 80px !important;
  }
  .ml-lg-80,
  .mx-lg-80 {
    margin-left: 80px !important;
  }
  .m-lg-85 {
    margin: 85px !important;
  }
  .mt-lg-85,
  .my-lg-85 {
    margin-top: 85px !important;
  }
  .mr-lg-85,
  .mx-lg-85 {
    margin-right: 85px !important;
  }
  .mb-lg-85,
  .my-lg-85 {
    margin-bottom: 85px !important;
  }
  .ml-lg-85,
  .mx-lg-85 {
    margin-left: 85px !important;
  }
  .m-lg-90 {
    margin: 90px !important;
  }
  .mt-lg-90,
  .my-lg-90 {
    margin-top: 90px !important;
  }
  .mr-lg-90,
  .mx-lg-90 {
    margin-right: 90px !important;
  }
  .mb-lg-90,
  .my-lg-90 {
    margin-bottom: 90px !important;
  }
  .ml-lg-90,
  .mx-lg-90 {
    margin-left: 90px !important;
  }
  .m-lg-95 {
    margin: 95px !important;
  }
  .mt-lg-95,
  .my-lg-95 {
    margin-top: 95px !important;
  }
  .mr-lg-95,
  .mx-lg-95 {
    margin-right: 95px !important;
  }
  .mb-lg-95,
  .my-lg-95 {
    margin-bottom: 95px !important;
  }
  .ml-lg-95,
  .mx-lg-95 {
    margin-left: 95px !important;
  }
  .m-lg-100 {
    margin: 100px !important;
  }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 100px !important;
  }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 100px !important;
  }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 100px !important;
  }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 100px !important;
  }
  .m-lg-130 {
    margin: 130px !important;
  }
  .mt-lg-130,
  .my-lg-130 {
    margin-top: 130px !important;
  }
  .mr-lg-130,
  .mx-lg-130 {
    margin-right: 130px !important;
  }
  .mb-lg-130,
  .my-lg-130 {
    margin-bottom: 130px !important;
  }
  .ml-lg-130,
  .mx-lg-130 {
    margin-left: 130px !important;
  }
  .m-lg-165 {
    margin: 165px !important;
  }
  .mt-lg-165,
  .my-lg-165 {
    margin-top: 165px !important;
  }
  .mr-lg-165,
  .mx-lg-165 {
    margin-right: 165px !important;
  }
  .mb-lg-165,
  .my-lg-165 {
    margin-bottom: 165px !important;
  }
  .ml-lg-165,
  .mx-lg-165 {
    margin-left: 165px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 5px !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5px !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5px !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5px !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5px !important;
  }
  .p-lg-10 {
    padding: 10px !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10px !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10px !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10px !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10px !important;
  }
  .p-lg-15 {
    padding: 15px !important;
  }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15px !important;
  }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15px !important;
  }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15px !important;
  }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15px !important;
  }
  .p-lg-20 {
    padding: 20px !important;
  }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 20px !important;
  }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 20px !important;
  }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 20px !important;
  }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 20px !important;
  }
  .p-lg-25 {
    padding: 25px !important;
  }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 25px !important;
  }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 25px !important;
  }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 25px !important;
  }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 25px !important;
  }
  .p-lg-30 {
    padding: 30px !important;
  }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 30px !important;
  }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 30px !important;
  }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 30px !important;
  }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 30px !important;
  }
  .p-lg-35 {
    padding: 35px !important;
  }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 35px !important;
  }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 35px !important;
  }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 35px !important;
  }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 35px !important;
  }
  .p-lg-40 {
    padding: 40px !important;
  }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 40px !important;
  }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 40px !important;
  }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 40px !important;
  }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 40px !important;
  }
  .p-lg-45 {
    padding: 45px !important;
  }
  .pt-lg-45,
  .py-lg-45 {
    padding-top: 45px !important;
  }
  .pr-lg-45,
  .px-lg-45 {
    padding-right: 45px !important;
  }
  .pb-lg-45,
  .py-lg-45 {
    padding-bottom: 45px !important;
  }
  .pl-lg-45,
  .px-lg-45 {
    padding-left: 45px !important;
  }
  .p-lg-50 {
    padding: 50px !important;
  }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 50px !important;
  }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 50px !important;
  }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 50px !important;
  }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 50px !important;
  }
  .p-lg-55 {
    padding: 55px !important;
  }
  .pt-lg-55,
  .py-lg-55 {
    padding-top: 55px !important;
  }
  .pr-lg-55,
  .px-lg-55 {
    padding-right: 55px !important;
  }
  .pb-lg-55,
  .py-lg-55 {
    padding-bottom: 55px !important;
  }
  .pl-lg-55,
  .px-lg-55 {
    padding-left: 55px !important;
  }
  .p-lg-60 {
    padding: 60px !important;
  }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 60px !important;
  }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 60px !important;
  }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 60px !important;
  }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 60px !important;
  }
  .p-lg-65 {
    padding: 65px !important;
  }
  .pt-lg-65,
  .py-lg-65 {
    padding-top: 65px !important;
  }
  .pr-lg-65,
  .px-lg-65 {
    padding-right: 65px !important;
  }
  .pb-lg-65,
  .py-lg-65 {
    padding-bottom: 65px !important;
  }
  .pl-lg-65,
  .px-lg-65 {
    padding-left: 65px !important;
  }
  .p-lg-70 {
    padding: 70px !important;
  }
  .pt-lg-70,
  .py-lg-70 {
    padding-top: 70px !important;
  }
  .pr-lg-70,
  .px-lg-70 {
    padding-right: 70px !important;
  }
  .pb-lg-70,
  .py-lg-70 {
    padding-bottom: 70px !important;
  }
  .pl-lg-70,
  .px-lg-70 {
    padding-left: 70px !important;
  }
  .p-lg-75 {
    padding: 75px !important;
  }
  .pt-lg-75,
  .py-lg-75 {
    padding-top: 75px !important;
  }
  .pr-lg-75,
  .px-lg-75 {
    padding-right: 75px !important;
  }
  .pb-lg-75,
  .py-lg-75 {
    padding-bottom: 75px !important;
  }
  .pl-lg-75,
  .px-lg-75 {
    padding-left: 75px !important;
  }
  .p-lg-80 {
    padding: 80px !important;
  }
  .pt-lg-80,
  .py-lg-80 {
    padding-top: 80px !important;
  }
  .pr-lg-80,
  .px-lg-80 {
    padding-right: 80px !important;
  }
  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 80px !important;
  }
  .pl-lg-80,
  .px-lg-80 {
    padding-left: 80px !important;
  }
  .p-lg-85 {
    padding: 85px !important;
  }
  .pt-lg-85,
  .py-lg-85 {
    padding-top: 85px !important;
  }
  .pr-lg-85,
  .px-lg-85 {
    padding-right: 85px !important;
  }
  .pb-lg-85,
  .py-lg-85 {
    padding-bottom: 85px !important;
  }
  .pl-lg-85,
  .px-lg-85 {
    padding-left: 85px !important;
  }
  .p-lg-90 {
    padding: 90px !important;
  }
  .pt-lg-90,
  .py-lg-90 {
    padding-top: 90px !important;
  }
  .pr-lg-90,
  .px-lg-90 {
    padding-right: 90px !important;
  }
  .pb-lg-90,
  .py-lg-90 {
    padding-bottom: 90px !important;
  }
  .pl-lg-90,
  .px-lg-90 {
    padding-left: 90px !important;
  }
  .p-lg-95 {
    padding: 95px !important;
  }
  .pt-lg-95,
  .py-lg-95 {
    padding-top: 95px !important;
  }
  .pr-lg-95,
  .px-lg-95 {
    padding-right: 95px !important;
  }
  .pb-lg-95,
  .py-lg-95 {
    padding-bottom: 95px !important;
  }
  .pl-lg-95,
  .px-lg-95 {
    padding-left: 95px !important;
  }
  .p-lg-100 {
    padding: 100px !important;
  }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 100px !important;
  }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 100px !important;
  }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 100px !important;
  }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 100px !important;
  }
  .p-lg-130 {
    padding: 130px !important;
  }
  .pt-lg-130,
  .py-lg-130 {
    padding-top: 130px !important;
  }
  .pr-lg-130,
  .px-lg-130 {
    padding-right: 130px !important;
  }
  .pb-lg-130,
  .py-lg-130 {
    padding-bottom: 130px !important;
  }
  .pl-lg-130,
  .px-lg-130 {
    padding-left: 130px !important;
  }
  .p-lg-165 {
    padding: 165px !important;
  }
  .pt-lg-165,
  .py-lg-165 {
    padding-top: 165px !important;
  }
  .pr-lg-165,
  .px-lg-165 {
    padding-right: 165px !important;
  }
  .pb-lg-165,
  .py-lg-165 {
    padding-bottom: 165px !important;
  }
  .pl-lg-165,
  .px-lg-165 {
    padding-left: 165px !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -5px !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5px !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5px !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5px !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5px !important;
  }
  .m-lg-n10 {
    margin: -10px !important;
  }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10px !important;
  }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10px !important;
  }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10px !important;
  }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10px !important;
  }
  .m-lg-n15 {
    margin: -15px !important;
  }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -15px !important;
  }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -15px !important;
  }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -15px !important;
  }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -15px !important;
  }
  .m-lg-n20 {
    margin: -20px !important;
  }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -20px !important;
  }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -20px !important;
  }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -20px !important;
  }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -20px !important;
  }
  .m-lg-n25 {
    margin: -25px !important;
  }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -25px !important;
  }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -25px !important;
  }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -25px !important;
  }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -25px !important;
  }
  .m-lg-n30 {
    margin: -30px !important;
  }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -30px !important;
  }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -30px !important;
  }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -30px !important;
  }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -30px !important;
  }
  .m-lg-n35 {
    margin: -35px !important;
  }
  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -35px !important;
  }
  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -35px !important;
  }
  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -35px !important;
  }
  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -35px !important;
  }
  .m-lg-n40 {
    margin: -40px !important;
  }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -40px !important;
  }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -40px !important;
  }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -40px !important;
  }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -40px !important;
  }
  .m-lg-n45 {
    margin: -45px !important;
  }
  .mt-lg-n45,
  .my-lg-n45 {
    margin-top: -45px !important;
  }
  .mr-lg-n45,
  .mx-lg-n45 {
    margin-right: -45px !important;
  }
  .mb-lg-n45,
  .my-lg-n45 {
    margin-bottom: -45px !important;
  }
  .ml-lg-n45,
  .mx-lg-n45 {
    margin-left: -45px !important;
  }
  .m-lg-n50 {
    margin: -50px !important;
  }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -50px !important;
  }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -50px !important;
  }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -50px !important;
  }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -50px !important;
  }
  .m-lg-n55 {
    margin: -55px !important;
  }
  .mt-lg-n55,
  .my-lg-n55 {
    margin-top: -55px !important;
  }
  .mr-lg-n55,
  .mx-lg-n55 {
    margin-right: -55px !important;
  }
  .mb-lg-n55,
  .my-lg-n55 {
    margin-bottom: -55px !important;
  }
  .ml-lg-n55,
  .mx-lg-n55 {
    margin-left: -55px !important;
  }
  .m-lg-n60 {
    margin: -60px !important;
  }
  .mt-lg-n60,
  .my-lg-n60 {
    margin-top: -60px !important;
  }
  .mr-lg-n60,
  .mx-lg-n60 {
    margin-right: -60px !important;
  }
  .mb-lg-n60,
  .my-lg-n60 {
    margin-bottom: -60px !important;
  }
  .ml-lg-n60,
  .mx-lg-n60 {
    margin-left: -60px !important;
  }
  .m-lg-n65 {
    margin: -65px !important;
  }
  .mt-lg-n65,
  .my-lg-n65 {
    margin-top: -65px !important;
  }
  .mr-lg-n65,
  .mx-lg-n65 {
    margin-right: -65px !important;
  }
  .mb-lg-n65,
  .my-lg-n65 {
    margin-bottom: -65px !important;
  }
  .ml-lg-n65,
  .mx-lg-n65 {
    margin-left: -65px !important;
  }
  .m-lg-n70 {
    margin: -70px !important;
  }
  .mt-lg-n70,
  .my-lg-n70 {
    margin-top: -70px !important;
  }
  .mr-lg-n70,
  .mx-lg-n70 {
    margin-right: -70px !important;
  }
  .mb-lg-n70,
  .my-lg-n70 {
    margin-bottom: -70px !important;
  }
  .ml-lg-n70,
  .mx-lg-n70 {
    margin-left: -70px !important;
  }
  .m-lg-n75 {
    margin: -75px !important;
  }
  .mt-lg-n75,
  .my-lg-n75 {
    margin-top: -75px !important;
  }
  .mr-lg-n75,
  .mx-lg-n75 {
    margin-right: -75px !important;
  }
  .mb-lg-n75,
  .my-lg-n75 {
    margin-bottom: -75px !important;
  }
  .ml-lg-n75,
  .mx-lg-n75 {
    margin-left: -75px !important;
  }
  .m-lg-n80 {
    margin: -80px !important;
  }
  .mt-lg-n80,
  .my-lg-n80 {
    margin-top: -80px !important;
  }
  .mr-lg-n80,
  .mx-lg-n80 {
    margin-right: -80px !important;
  }
  .mb-lg-n80,
  .my-lg-n80 {
    margin-bottom: -80px !important;
  }
  .ml-lg-n80,
  .mx-lg-n80 {
    margin-left: -80px !important;
  }
  .m-lg-n85 {
    margin: -85px !important;
  }
  .mt-lg-n85,
  .my-lg-n85 {
    margin-top: -85px !important;
  }
  .mr-lg-n85,
  .mx-lg-n85 {
    margin-right: -85px !important;
  }
  .mb-lg-n85,
  .my-lg-n85 {
    margin-bottom: -85px !important;
  }
  .ml-lg-n85,
  .mx-lg-n85 {
    margin-left: -85px !important;
  }
  .m-lg-n90 {
    margin: -90px !important;
  }
  .mt-lg-n90,
  .my-lg-n90 {
    margin-top: -90px !important;
  }
  .mr-lg-n90,
  .mx-lg-n90 {
    margin-right: -90px !important;
  }
  .mb-lg-n90,
  .my-lg-n90 {
    margin-bottom: -90px !important;
  }
  .ml-lg-n90,
  .mx-lg-n90 {
    margin-left: -90px !important;
  }
  .m-lg-n95 {
    margin: -95px !important;
  }
  .mt-lg-n95,
  .my-lg-n95 {
    margin-top: -95px !important;
  }
  .mr-lg-n95,
  .mx-lg-n95 {
    margin-right: -95px !important;
  }
  .mb-lg-n95,
  .my-lg-n95 {
    margin-bottom: -95px !important;
  }
  .ml-lg-n95,
  .mx-lg-n95 {
    margin-left: -95px !important;
  }
  .m-lg-n100 {
    margin: -100px !important;
  }
  .mt-lg-n100,
  .my-lg-n100 {
    margin-top: -100px !important;
  }
  .mr-lg-n100,
  .mx-lg-n100 {
    margin-right: -100px !important;
  }
  .mb-lg-n100,
  .my-lg-n100 {
    margin-bottom: -100px !important;
  }
  .ml-lg-n100,
  .mx-lg-n100 {
    margin-left: -100px !important;
  }
  .m-lg-n130 {
    margin: -130px !important;
  }
  .mt-lg-n130,
  .my-lg-n130 {
    margin-top: -130px !important;
  }
  .mr-lg-n130,
  .mx-lg-n130 {
    margin-right: -130px !important;
  }
  .mb-lg-n130,
  .my-lg-n130 {
    margin-bottom: -130px !important;
  }
  .ml-lg-n130,
  .mx-lg-n130 {
    margin-left: -130px !important;
  }
  .m-lg-n165 {
    margin: -165px !important;
  }
  .mt-lg-n165,
  .my-lg-n165 {
    margin-top: -165px !important;
  }
  .mr-lg-n165,
  .mx-lg-n165 {
    margin-right: -165px !important;
  }
  .mb-lg-n165,
  .my-lg-n165 {
    margin-bottom: -165px !important;
  }
  .ml-lg-n165,
  .mx-lg-n165 {
    margin-left: -165px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 5px !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5px !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5px !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5px !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5px !important;
  }
  .m-xl-10 {
    margin: 10px !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10px !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10px !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10px !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10px !important;
  }
  .m-xl-15 {
    margin: 15px !important;
  }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15px !important;
  }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15px !important;
  }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15px !important;
  }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15px !important;
  }
  .m-xl-20 {
    margin: 20px !important;
  }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 20px !important;
  }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 20px !important;
  }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 20px !important;
  }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 20px !important;
  }
  .m-xl-25 {
    margin: 25px !important;
  }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 25px !important;
  }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 25px !important;
  }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 25px !important;
  }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 25px !important;
  }
  .m-xl-30 {
    margin: 30px !important;
  }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 30px !important;
  }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 30px !important;
  }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 30px !important;
  }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 30px !important;
  }
  .m-xl-35 {
    margin: 35px !important;
  }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 35px !important;
  }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 35px !important;
  }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 35px !important;
  }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 35px !important;
  }
  .m-xl-40 {
    margin: 40px !important;
  }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 40px !important;
  }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 40px !important;
  }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 40px !important;
  }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 40px !important;
  }
  .m-xl-45 {
    margin: 45px !important;
  }
  .mt-xl-45,
  .my-xl-45 {
    margin-top: 45px !important;
  }
  .mr-xl-45,
  .mx-xl-45 {
    margin-right: 45px !important;
  }
  .mb-xl-45,
  .my-xl-45 {
    margin-bottom: 45px !important;
  }
  .ml-xl-45,
  .mx-xl-45 {
    margin-left: 45px !important;
  }
  .m-xl-50 {
    margin: 50px !important;
  }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 50px !important;
  }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 50px !important;
  }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 50px !important;
  }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 50px !important;
  }
  .m-xl-55 {
    margin: 55px !important;
  }
  .mt-xl-55,
  .my-xl-55 {
    margin-top: 55px !important;
  }
  .mr-xl-55,
  .mx-xl-55 {
    margin-right: 55px !important;
  }
  .mb-xl-55,
  .my-xl-55 {
    margin-bottom: 55px !important;
  }
  .ml-xl-55,
  .mx-xl-55 {
    margin-left: 55px !important;
  }
  .m-xl-60 {
    margin: 60px !important;
  }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 60px !important;
  }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 60px !important;
  }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 60px !important;
  }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 60px !important;
  }
  .m-xl-65 {
    margin: 65px !important;
  }
  .mt-xl-65,
  .my-xl-65 {
    margin-top: 65px !important;
  }
  .mr-xl-65,
  .mx-xl-65 {
    margin-right: 65px !important;
  }
  .mb-xl-65,
  .my-xl-65 {
    margin-bottom: 65px !important;
  }
  .ml-xl-65,
  .mx-xl-65 {
    margin-left: 65px !important;
  }
  .m-xl-70 {
    margin: 70px !important;
  }
  .mt-xl-70,
  .my-xl-70 {
    margin-top: 70px !important;
  }
  .mr-xl-70,
  .mx-xl-70 {
    margin-right: 70px !important;
  }
  .mb-xl-70,
  .my-xl-70 {
    margin-bottom: 70px !important;
  }
  .ml-xl-70,
  .mx-xl-70 {
    margin-left: 70px !important;
  }
  .m-xl-75 {
    margin: 75px !important;
  }
  .mt-xl-75,
  .my-xl-75 {
    margin-top: 75px !important;
  }
  .mr-xl-75,
  .mx-xl-75 {
    margin-right: 75px !important;
  }
  .mb-xl-75,
  .my-xl-75 {
    margin-bottom: 75px !important;
  }
  .ml-xl-75,
  .mx-xl-75 {
    margin-left: 75px !important;
  }
  .m-xl-80 {
    margin: 80px !important;
  }
  .mt-xl-80,
  .my-xl-80 {
    margin-top: 80px !important;
  }
  .mr-xl-80,
  .mx-xl-80 {
    margin-right: 80px !important;
  }
  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 80px !important;
  }
  .ml-xl-80,
  .mx-xl-80 {
    margin-left: 80px !important;
  }
  .m-xl-85 {
    margin: 85px !important;
  }
  .mt-xl-85,
  .my-xl-85 {
    margin-top: 85px !important;
  }
  .mr-xl-85,
  .mx-xl-85 {
    margin-right: 85px !important;
  }
  .mb-xl-85,
  .my-xl-85 {
    margin-bottom: 85px !important;
  }
  .ml-xl-85,
  .mx-xl-85 {
    margin-left: 85px !important;
  }
  .m-xl-90 {
    margin: 90px !important;
  }
  .mt-xl-90,
  .my-xl-90 {
    margin-top: 90px !important;
  }
  .mr-xl-90,
  .mx-xl-90 {
    margin-right: 90px !important;
  }
  .mb-xl-90,
  .my-xl-90 {
    margin-bottom: 90px !important;
  }
  .ml-xl-90,
  .mx-xl-90 {
    margin-left: 90px !important;
  }
  .m-xl-95 {
    margin: 95px !important;
  }
  .mt-xl-95,
  .my-xl-95 {
    margin-top: 95px !important;
  }
  .mr-xl-95,
  .mx-xl-95 {
    margin-right: 95px !important;
  }
  .mb-xl-95,
  .my-xl-95 {
    margin-bottom: 95px !important;
  }
  .ml-xl-95,
  .mx-xl-95 {
    margin-left: 95px !important;
  }
  .m-xl-100 {
    margin: 100px !important;
  }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 100px !important;
  }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 100px !important;
  }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 100px !important;
  }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 100px !important;
  }
  .m-xl-130 {
    margin: 130px !important;
  }
  .mt-xl-130,
  .my-xl-130 {
    margin-top: 130px !important;
  }
  .mr-xl-130,
  .mx-xl-130 {
    margin-right: 130px !important;
  }
  .mb-xl-130,
  .my-xl-130 {
    margin-bottom: 130px !important;
  }
  .ml-xl-130,
  .mx-xl-130 {
    margin-left: 130px !important;
  }
  .m-xl-165 {
    margin: 165px !important;
  }
  .mt-xl-165,
  .my-xl-165 {
    margin-top: 165px !important;
  }
  .mr-xl-165,
  .mx-xl-165 {
    margin-right: 165px !important;
  }
  .mb-xl-165,
  .my-xl-165 {
    margin-bottom: 165px !important;
  }
  .ml-xl-165,
  .mx-xl-165 {
    margin-left: 165px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 5px !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5px !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5px !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5px !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5px !important;
  }
  .p-xl-10 {
    padding: 10px !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10px !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10px !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10px !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10px !important;
  }
  .p-xl-15 {
    padding: 15px !important;
  }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15px !important;
  }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15px !important;
  }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15px !important;
  }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15px !important;
  }
  .p-xl-20 {
    padding: 20px !important;
  }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 20px !important;
  }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 20px !important;
  }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 20px !important;
  }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 20px !important;
  }
  .p-xl-25 {
    padding: 25px !important;
  }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 25px !important;
  }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 25px !important;
  }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 25px !important;
  }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 25px !important;
  }
  .p-xl-30 {
    padding: 30px !important;
  }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 30px !important;
  }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 30px !important;
  }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 30px !important;
  }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 30px !important;
  }
  .p-xl-35 {
    padding: 35px !important;
  }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 35px !important;
  }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 35px !important;
  }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 35px !important;
  }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 35px !important;
  }
  .p-xl-40 {
    padding: 40px !important;
  }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 40px !important;
  }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 40px !important;
  }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 40px !important;
  }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 40px !important;
  }
  .p-xl-45 {
    padding: 45px !important;
  }
  .pt-xl-45,
  .py-xl-45 {
    padding-top: 45px !important;
  }
  .pr-xl-45,
  .px-xl-45 {
    padding-right: 45px !important;
  }
  .pb-xl-45,
  .py-xl-45 {
    padding-bottom: 45px !important;
  }
  .pl-xl-45,
  .px-xl-45 {
    padding-left: 45px !important;
  }
  .p-xl-50 {
    padding: 50px !important;
  }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 50px !important;
  }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 50px !important;
  }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 50px !important;
  }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 50px !important;
  }
  .p-xl-55 {
    padding: 55px !important;
  }
  .pt-xl-55,
  .py-xl-55 {
    padding-top: 55px !important;
  }
  .pr-xl-55,
  .px-xl-55 {
    padding-right: 55px !important;
  }
  .pb-xl-55,
  .py-xl-55 {
    padding-bottom: 55px !important;
  }
  .pl-xl-55,
  .px-xl-55 {
    padding-left: 55px !important;
  }
  .p-xl-60 {
    padding: 60px !important;
  }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 60px !important;
  }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 60px !important;
  }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 60px !important;
  }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 60px !important;
  }
  .p-xl-65 {
    padding: 65px !important;
  }
  .pt-xl-65,
  .py-xl-65 {
    padding-top: 65px !important;
  }
  .pr-xl-65,
  .px-xl-65 {
    padding-right: 65px !important;
  }
  .pb-xl-65,
  .py-xl-65 {
    padding-bottom: 65px !important;
  }
  .pl-xl-65,
  .px-xl-65 {
    padding-left: 65px !important;
  }
  .p-xl-70 {
    padding: 70px !important;
  }
  .pt-xl-70,
  .py-xl-70 {
    padding-top: 70px !important;
  }
  .pr-xl-70,
  .px-xl-70 {
    padding-right: 70px !important;
  }
  .pb-xl-70,
  .py-xl-70 {
    padding-bottom: 70px !important;
  }
  .pl-xl-70,
  .px-xl-70 {
    padding-left: 70px !important;
  }
  .p-xl-75 {
    padding: 75px !important;
  }
  .pt-xl-75,
  .py-xl-75 {
    padding-top: 75px !important;
  }
  .pr-xl-75,
  .px-xl-75 {
    padding-right: 75px !important;
  }
  .pb-xl-75,
  .py-xl-75 {
    padding-bottom: 75px !important;
  }
  .pl-xl-75,
  .px-xl-75 {
    padding-left: 75px !important;
  }
  .p-xl-80 {
    padding: 80px !important;
  }
  .pt-xl-80,
  .py-xl-80 {
    padding-top: 80px !important;
  }
  .pr-xl-80,
  .px-xl-80 {
    padding-right: 80px !important;
  }
  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 80px !important;
  }
  .pl-xl-80,
  .px-xl-80 {
    padding-left: 80px !important;
  }
  .p-xl-85 {
    padding: 85px !important;
  }
  .pt-xl-85,
  .py-xl-85 {
    padding-top: 85px !important;
  }
  .pr-xl-85,
  .px-xl-85 {
    padding-right: 85px !important;
  }
  .pb-xl-85,
  .py-xl-85 {
    padding-bottom: 85px !important;
  }
  .pl-xl-85,
  .px-xl-85 {
    padding-left: 85px !important;
  }
  .p-xl-90 {
    padding: 90px !important;
  }
  .pt-xl-90,
  .py-xl-90 {
    padding-top: 90px !important;
  }
  .pr-xl-90,
  .px-xl-90 {
    padding-right: 90px !important;
  }
  .pb-xl-90,
  .py-xl-90 {
    padding-bottom: 90px !important;
  }
  .pl-xl-90,
  .px-xl-90 {
    padding-left: 90px !important;
  }
  .p-xl-95 {
    padding: 95px !important;
  }
  .pt-xl-95,
  .py-xl-95 {
    padding-top: 95px !important;
  }
  .pr-xl-95,
  .px-xl-95 {
    padding-right: 95px !important;
  }
  .pb-xl-95,
  .py-xl-95 {
    padding-bottom: 95px !important;
  }
  .pl-xl-95,
  .px-xl-95 {
    padding-left: 95px !important;
  }
  .p-xl-100 {
    padding: 100px !important;
  }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 100px !important;
  }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 100px !important;
  }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 100px !important;
  }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 100px !important;
  }
  .p-xl-130 {
    padding: 130px !important;
  }
  .pt-xl-130,
  .py-xl-130 {
    padding-top: 130px !important;
  }
  .pr-xl-130,
  .px-xl-130 {
    padding-right: 130px !important;
  }
  .pb-xl-130,
  .py-xl-130 {
    padding-bottom: 130px !important;
  }
  .pl-xl-130,
  .px-xl-130 {
    padding-left: 130px !important;
  }
  .p-xl-165 {
    padding: 165px !important;
  }
  .pt-xl-165,
  .py-xl-165 {
    padding-top: 165px !important;
  }
  .pr-xl-165,
  .px-xl-165 {
    padding-right: 165px !important;
  }
  .pb-xl-165,
  .py-xl-165 {
    padding-bottom: 165px !important;
  }
  .pl-xl-165,
  .px-xl-165 {
    padding-left: 165px !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -5px !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5px !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5px !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5px !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5px !important;
  }
  .m-xl-n10 {
    margin: -10px !important;
  }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10px !important;
  }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10px !important;
  }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10px !important;
  }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10px !important;
  }
  .m-xl-n15 {
    margin: -15px !important;
  }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -15px !important;
  }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -15px !important;
  }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -15px !important;
  }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -15px !important;
  }
  .m-xl-n20 {
    margin: -20px !important;
  }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -20px !important;
  }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -20px !important;
  }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -20px !important;
  }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -20px !important;
  }
  .m-xl-n25 {
    margin: -25px !important;
  }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -25px !important;
  }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -25px !important;
  }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -25px !important;
  }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -25px !important;
  }
  .m-xl-n30 {
    margin: -30px !important;
  }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -30px !important;
  }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -30px !important;
  }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -30px !important;
  }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -30px !important;
  }
  .m-xl-n35 {
    margin: -35px !important;
  }
  .mt-xl-n35,
  .my-xl-n35 {
    margin-top: -35px !important;
  }
  .mr-xl-n35,
  .mx-xl-n35 {
    margin-right: -35px !important;
  }
  .mb-xl-n35,
  .my-xl-n35 {
    margin-bottom: -35px !important;
  }
  .ml-xl-n35,
  .mx-xl-n35 {
    margin-left: -35px !important;
  }
  .m-xl-n40 {
    margin: -40px !important;
  }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -40px !important;
  }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -40px !important;
  }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -40px !important;
  }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -40px !important;
  }
  .m-xl-n45 {
    margin: -45px !important;
  }
  .mt-xl-n45,
  .my-xl-n45 {
    margin-top: -45px !important;
  }
  .mr-xl-n45,
  .mx-xl-n45 {
    margin-right: -45px !important;
  }
  .mb-xl-n45,
  .my-xl-n45 {
    margin-bottom: -45px !important;
  }
  .ml-xl-n45,
  .mx-xl-n45 {
    margin-left: -45px !important;
  }
  .m-xl-n50 {
    margin: -50px !important;
  }
  .mt-xl-n50,
  .my-xl-n50 {
    margin-top: -50px !important;
  }
  .mr-xl-n50,
  .mx-xl-n50 {
    margin-right: -50px !important;
  }
  .mb-xl-n50,
  .my-xl-n50 {
    margin-bottom: -50px !important;
  }
  .ml-xl-n50,
  .mx-xl-n50 {
    margin-left: -50px !important;
  }
  .m-xl-n55 {
    margin: -55px !important;
  }
  .mt-xl-n55,
  .my-xl-n55 {
    margin-top: -55px !important;
  }
  .mr-xl-n55,
  .mx-xl-n55 {
    margin-right: -55px !important;
  }
  .mb-xl-n55,
  .my-xl-n55 {
    margin-bottom: -55px !important;
  }
  .ml-xl-n55,
  .mx-xl-n55 {
    margin-left: -55px !important;
  }
  .m-xl-n60 {
    margin: -60px !important;
  }
  .mt-xl-n60,
  .my-xl-n60 {
    margin-top: -60px !important;
  }
  .mr-xl-n60,
  .mx-xl-n60 {
    margin-right: -60px !important;
  }
  .mb-xl-n60,
  .my-xl-n60 {
    margin-bottom: -60px !important;
  }
  .ml-xl-n60,
  .mx-xl-n60 {
    margin-left: -60px !important;
  }
  .m-xl-n65 {
    margin: -65px !important;
  }
  .mt-xl-n65,
  .my-xl-n65 {
    margin-top: -65px !important;
  }
  .mr-xl-n65,
  .mx-xl-n65 {
    margin-right: -65px !important;
  }
  .mb-xl-n65,
  .my-xl-n65 {
    margin-bottom: -65px !important;
  }
  .ml-xl-n65,
  .mx-xl-n65 {
    margin-left: -65px !important;
  }
  .m-xl-n70 {
    margin: -70px !important;
  }
  .mt-xl-n70,
  .my-xl-n70 {
    margin-top: -70px !important;
  }
  .mr-xl-n70,
  .mx-xl-n70 {
    margin-right: -70px !important;
  }
  .mb-xl-n70,
  .my-xl-n70 {
    margin-bottom: -70px !important;
  }
  .ml-xl-n70,
  .mx-xl-n70 {
    margin-left: -70px !important;
  }
  .m-xl-n75 {
    margin: -75px !important;
  }
  .mt-xl-n75,
  .my-xl-n75 {
    margin-top: -75px !important;
  }
  .mr-xl-n75,
  .mx-xl-n75 {
    margin-right: -75px !important;
  }
  .mb-xl-n75,
  .my-xl-n75 {
    margin-bottom: -75px !important;
  }
  .ml-xl-n75,
  .mx-xl-n75 {
    margin-left: -75px !important;
  }
  .m-xl-n80 {
    margin: -80px !important;
  }
  .mt-xl-n80,
  .my-xl-n80 {
    margin-top: -80px !important;
  }
  .mr-xl-n80,
  .mx-xl-n80 {
    margin-right: -80px !important;
  }
  .mb-xl-n80,
  .my-xl-n80 {
    margin-bottom: -80px !important;
  }
  .ml-xl-n80,
  .mx-xl-n80 {
    margin-left: -80px !important;
  }
  .m-xl-n85 {
    margin: -85px !important;
  }
  .mt-xl-n85,
  .my-xl-n85 {
    margin-top: -85px !important;
  }
  .mr-xl-n85,
  .mx-xl-n85 {
    margin-right: -85px !important;
  }
  .mb-xl-n85,
  .my-xl-n85 {
    margin-bottom: -85px !important;
  }
  .ml-xl-n85,
  .mx-xl-n85 {
    margin-left: -85px !important;
  }
  .m-xl-n90 {
    margin: -90px !important;
  }
  .mt-xl-n90,
  .my-xl-n90 {
    margin-top: -90px !important;
  }
  .mr-xl-n90,
  .mx-xl-n90 {
    margin-right: -90px !important;
  }
  .mb-xl-n90,
  .my-xl-n90 {
    margin-bottom: -90px !important;
  }
  .ml-xl-n90,
  .mx-xl-n90 {
    margin-left: -90px !important;
  }
  .m-xl-n95 {
    margin: -95px !important;
  }
  .mt-xl-n95,
  .my-xl-n95 {
    margin-top: -95px !important;
  }
  .mr-xl-n95,
  .mx-xl-n95 {
    margin-right: -95px !important;
  }
  .mb-xl-n95,
  .my-xl-n95 {
    margin-bottom: -95px !important;
  }
  .ml-xl-n95,
  .mx-xl-n95 {
    margin-left: -95px !important;
  }
  .m-xl-n100 {
    margin: -100px !important;
  }
  .mt-xl-n100,
  .my-xl-n100 {
    margin-top: -100px !important;
  }
  .mr-xl-n100,
  .mx-xl-n100 {
    margin-right: -100px !important;
  }
  .mb-xl-n100,
  .my-xl-n100 {
    margin-bottom: -100px !important;
  }
  .ml-xl-n100,
  .mx-xl-n100 {
    margin-left: -100px !important;
  }
  .m-xl-n130 {
    margin: -130px !important;
  }
  .mt-xl-n130,
  .my-xl-n130 {
    margin-top: -130px !important;
  }
  .mr-xl-n130,
  .mx-xl-n130 {
    margin-right: -130px !important;
  }
  .mb-xl-n130,
  .my-xl-n130 {
    margin-bottom: -130px !important;
  }
  .ml-xl-n130,
  .mx-xl-n130 {
    margin-left: -130px !important;
  }
  .m-xl-n165 {
    margin: -165px !important;
  }
  .mt-xl-n165,
  .my-xl-n165 {
    margin-top: -165px !important;
  }
  .mr-xl-n165,
  .mx-xl-n165 {
    margin-right: -165px !important;
  }
  .mb-xl-n165,
  .my-xl-n165 {
    margin-bottom: -165px !important;
  }
  .ml-xl-n165,
  .mx-xl-n165 {
    margin-left: -165px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 100 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #919091 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #6b6a6b !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #919091 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #6b6a6b !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #000 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #c8c7c8;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #c8c7c8;
  }
}
/*!
 * Reinhart Realtors Agent Theme - Base
 * Authored by MavTech Consulting (https://www.mavtechconsulting.com)
 * Based on the Bootstrap 4 framework
 */
/*!
 * Reinhart Realtors Website Theme
 *
 * Set typography values from Reinhart style guide
 * Replaces Bootstrap _type.scss, which is commendted out
 * Affects following Bootstrap styles:
 *    h1 - h6, .h1 - .h6, .lead, display-1 - diplay-4, hr, small/.small, mark/.mark
 *    .list-unstyled, .list-inline, .list-inline-item, .initialism, .blockquote, .blockqoute-footer
 * If any of these styles from Bootstrap are desired copy from the Bootstrap _type.scss and
 * insert in this file
 */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #000;
}

h1, .h1 {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
}

h2, .h2 {
  font-weight: 300;
  font-size: 28px;
  line-height: 36px;
}

h3, .h3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

h4, .h4 {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
}

h5, .h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

h6, .h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 38px;
    line-height: 50px;
  }
  h2, .h2 {
    font-size: 34px;
    line-height: 44px;
  }
  h3, .h3 {
    font-size: 27px;
    line-height: 36px;
  }
  h4, .h4 {
    font-size: 27px;
    line-height: 36px;
  }
  h5, .h5 {
    font-size: 15px;
    line-height: 20px;
  }
  h6, .h6 {
    font-size: 15px;
    line-height: 22px;
  }
}
@media (min-width: 992px) {
  h1, .h1 {
    font-size: 46px;
    line-height: 56px;
  }
  h2, .h2 {
    font-size: 38px;
    line-height: 48px;
  }
  h3, .h3 {
    font-size: 30px;
    line-height: 40px;
  }
  h4, .h4 {
    font-size: 30px;
    line-height: 40px;
  }
  h5, .h5 {
    font-size: 16px;
    line-height: 20px;
  }
  h6, .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 46px;
    line-height: 56px;
  }
  h2, .h2 {
    font-size: 38px;
    line-height: 48px;
  }
  h3, .h3 {
    font-size: 30px;
    line-height: 40px;
  }
  h4, .h4 {
    font-size: 30px;
    line-height: 40px;
  }
  h5, .h5 {
    font-size: 16px;
    line-height: 20px;
  }
  h6, .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}
h1.lg {
  font-size: 46px;
  line-height: 56px;
}

h2.lg {
  font-size: 38px;
  line-height: 48px;
}

h3.lg {
  font-size: 30px;
  line-height: 40px;
}

h4.lg {
  font-size: 30px;
  line-height: 40px;
}

h5.lg {
  font-size: 16px;
  line-height: 24px;
}

h6.lg {
  font-size: 16px;
  line-height: 24px;
}

h1.md {
  font-size: 46px;
  line-height: 56px;
}

h2.md {
  font-size: 38px;
  line-height: 48px;
}

h3.md {
  font-size: 30px;
  line-height: 40px;
}

h4.md {
  font-size: 30px;
  line-height: 40px;
}

h5.md {
  font-size: 16px;
  line-height: 24px;
}

h6.md {
  font-size: 16px;
  line-height: 24px;
}

h1.sm {
  font-size: 38px;
  line-height: 50px;
}

h2.sm {
  font-size: 34px;
  line-height: 44px;
}

h3.sm {
  font-size: 27px;
  line-height: 36px;
}

h4.sm {
  font-size: 27px;
  line-height: 36px;
}

h5.sm {
  font-size: 15px;
  line-height: 22px;
}

h6.sm {
  font-size: 15px;
  line-height: 22px;
}

h1.xs {
  font-size: 30px;
  line-height: 40px;
}

h2.xs {
  font-size: 28px;
  line-height: 36px;
}

h3.xs {
  font-size: 24px;
  line-height: 30px;
}

h4.xs {
  font-size: 24px;
  line-height: 30px;
}

h5.xs {
  font-size: 14px;
  line-height: 20px;
}

h6.xs {
  font-size: 14px;
  line-height: 20px;
}

p, .p {
  line-height: 24px;
  color: #000;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

a:focus {
  color: #2cae9c;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Resets component default values, complementary and overrides values in
 * bootstrap/_reboot.scss
 *
 */
body, html {
  letter-spacing: 0.01em;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border-top: 1px solid #c8c7c8;
}
hr.off-white {
  border-color: #dedede;
}
hr.light {
  border-color: #dedede;
}
hr.medium {
  border-color: #919091;
}
hr.dark {
  border-color: #000;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Set color style properties from Reinhart style guide
 * 
 */
.carbon-gray {
  color: #000;
}

.dark-gray {
  color: #000;
}

.dark-charcoal-gray {
  color: #404041;
}

.med-gray {
  color: #919091;
}

.light-gray {
  color: #c8c7c8;
}

.off-white {
  color: #dedede;
}

.white {
  color: #fff;
}

.white-hover:hover, .white-hover:focus {
  color: #fff;
}

.black {
  color: #000;
}

.black-hover:hover, .black-hover:focus {
  color: #000;
}

.dark-cool-gray {
  color: #000;
}

.med-cool-gray {
  color: #919091;
}

.light-cool-gray {
  color: #919091;
}

.dark-red {
  color: #279c8c;
}

.bright-red {
  color: #2cae9c;
}

.rebrand-red {
  color: #bc2023;
}

.bg-rebrand-red {
  background-color: #bc2023 !important;
}

.brand-primary {
  color: #000;
}

.bg-carbon-gray {
  background-color: #000;
}

.bg-dark-gray {
  background-color: #000;
}

.bg-dark-charcoal-gray {
  background-color: #404041;
}

.bg-med-gray {
  background-color: #919091;
}

.bg-light-gray {
  background-color: #c8c7c8;
}

.bg-off-white {
  background-color: #dedede;
}

.bg-white-75 {
  background-color: rgba(255, 255, 255, 0.75);
  transition: background-color 0.25s ease-in;
}

.bg-dark-cool-gray {
  background-color: #000;
}

.bg-med-cool-gray {
  background-color: #919091;
}

.bg-light-cool-gray {
  background-color: #919091;
}

.bg-dark-red {
  background-color: #279c8c;
}

.bg-bright-red {
  background-color: #2cae9c;
}

.bg-rebrand-red {
  background-color: #bc2023;
}

.bg-black {
  background-color: #000;
}

.bg-black-75 {
  background-color: rgba(0, 0, 0, 0.75);
}

.border-carbon-gray {
  border: 1px solid #000;
}

.border-dark-cool-gray {
  border: 1px solid #000;
}

.border-med-cool-gray {
  border: 1px solid #919091;
}

.border-light-cool-gray {
  border: 1px solid #919091;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Text utilities for font sizes, font weights, line heights, etc.
 * Complementary to _typopgraphy.scss file and bootstrap/utilities/_text.scss
 *
 * NOTE AREAS BELOW WHERE REINHART STYLES ARE BEING REPLACED WITH BOOTSTRAP TEXT UTILITY CLASSES
 */
.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-44 {
  font-size: 44px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-50 {
  font-size: 50px !important;
}

.font-72 {
  font-size: 48px !important;
}
@media (min-width: 992px) {
  .font-72 {
    font-size: 72px !important;
  }
}
@media (min-width: 1200px) {
  .font-72 {
    font-size: 72px !important;
  }
}

.font-xs-12 {
  font-size: 12px;
  line-height: 19px;
}
@media (min-width: 768px) {
  .font-xs-12 {
    font-size: 16px;
    line-height: 24px;
  }
}

/*! 
 * replace following styles with Bootstrap values
 * .light => font weight 100 (no bootstrap equivalent)
 * .regular-light => .font-weight-light (Bootstrap utility - font-weight 300)
 * .regular => .font-weight-normal
 * .regular-italic => .font-italic
 * .bold => .font-weight-bold
 */
.light {
  font-weight: 100;
}

.regular-light {
  font-weight: 300;
}

.line-1-5 {
  line-height: 1.5;
}

.line-18 {
  line-height: 18px;
}

.line-20 {
  line-height: 20px;
}

.line-22 {
  line-height: 22px;
}

.line-30 {
  line-height: 30px;
}

.underline {
  text-decoration: underline;
}

.strike {
  text-decoration: line-through;
}

.letters-02 {
  letter-spacing: 0.32px;
}

.letters-08 {
  letter-spacing: 0.8px;
}

.footer-text, .fine-print {
  font-weight: 400;
  font-size: 0.875rem;
  color: #919091;
  line-height: 1.25rem;
  letter-spacing: 0.02em;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Icon styling classes, primarily for svg icons
 *
 * NOTE AREAS BELOW WHERE REINHART STYLES ARE BEING REPLACED WITH BOOTSTRAP TEXT UTILITY CLASSES
 */
.svg-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.svg-icon.dark-red {
  fill: #279c8c;
}

.svg-icon.light-gray {
  fill: #c8c7c8;
}

.svg-icon.med-gray {
  fill: #919091;
}

.svg-icon.white {
  fill: #fff;
}

.svg-icon.bottom {
  vertical-align: bottom;
}

.svg-icon.top {
  vertical-align: top;
}

.svg-icon.sub {
  vertical-align: sub;
}

.svg-icon.text-top {
  vertical-align: text-top;
}

.svg-icon.text-bottom {
  vertical-align: text-bottom;
}

.svg-icon.top-two {
  position: relative;
  top: -2px;
}

.svg-icon.top-one {
  position: relative;
  top: 1px;
}

.svg-icon.top-1 {
  position: relative;
  top: -1px;
}

.svg-icon.lre, .svg-icon.lux-port {
  max-width: 135px;
}

.icon-video-play {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 42px;
  height: 42px;
  cursor: pointer;
  line-height: 42px;
  font-size: 42px;
  transform: translate(-50%, -50%);
  z-index: 102;
}

.flip-link svg {
  -webkit-transition: transform 0.25s ease-in-out;
  -moz-transition: transform 0.25s ease-in-out;
  -ms-transition: transform 0.25s ease-in-out;
  -o-transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transform: rotate(0);
}

.flip-link[aria-expanded=true] svg {
  transform: rotate(180deg);
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Site-wide style updates for Google Maps
 * 
 */
.google-map-canvas *, .google-map-canvas :before, .google-map-canvas :after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.google-map-canvas img {
  max-width: none;
}

.google-map-canvas .gm-style, .google-map-canvas .gm-style-iw {
  font-family: inherit;
  font-size: inherit;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Global layout classes for pages
 * 
 */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#main-content {
  margin-top: 80px;
  flex-grow: 1;
}

@media (min-width: 992px) {
  .rr-hero-transparent #main-content {
    margin-top: 0px;
  }
}

.bg-image-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.min-height-24 {
  min-height: 24px;
}

.min-height-52 {
  min-height: 52px;
}

.min-height-80 {
  min-height: 80px;
}

.full-width {
  width: 100%;
}

.width-50 {
  width: 50%;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Buttons
 *
 * Overrides Bootstrap values
 * 
 */
.btn {
  letter-spacing: 0.8px;
  font-weight: 700;
  line-height: 1.429;
  text-transform: uppercase;
  outline: 0;
  height: 50px;
}

.btn.agent-search-reset,
.btn.property-search-reset {
  height: unset;
}
.btn.agent-search-reset:hover, .btn.agent-search-reset:focus,
.btn.property-search-reset:hover,
.btn.property-search-reset:focus {
  text-decoration: none;
}
.btn.agent-search-reset:focus,
.btn.property-search-reset:focus {
  box-shadow: none;
}

.btn-primary,
.btn-warning {
  color: #fff;
  outline: 0;
  padding: 12px 20px;
  border-width: 2px;
  border-style: solid;
}
.btn-primary span,
.btn-warning span {
  position: relative;
  display: inline-block;
  transition: padding 0.25s ease-in-out;
}
.btn-primary span svg,
.btn-warning span svg {
  transition: opacity 0.25s ease-in-out;
  transform: rotate(180deg);
  position: absolute;
  right: 0;
  top: 5px;
  font-size: 11px;
  opacity: 0;
}
.btn-primary:hover,
.btn-warning:hover {
  color: #fff;
  outline: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #404041;
  border-color: #404041;
}
.btn-primary:hover span svg,
.btn-warning:hover span svg {
  opacity: 1;
}
.btn-primary:focus,
.btn-warning:focus {
  color: #fff;
  outline: 0;
  box-shadow: none;
  background-color: #404041;
  border-color: #404041;
  outline-offset: 0;
}
.btn-primary:focus span svg,
.btn-warning:focus span svg {
  opacity: 1;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active {
  outline-width: 0;
  color: #fff;
  background-color: #404041;
  border-color: #919091;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.36);
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.btn-secondary {
  color: #919091;
  background-color: #fff;
  border-color: #919091;
  outline: 0;
  transition: all 0.25s ease;
  box-shadow: none;
  padding: 12px 20px;
  border-width: 2px;
  border-style: solid;
}
.btn-secondary span {
  position: relative;
  display: inline-block;
  transition: padding 0.25s ease-in-out;
}
.btn-secondary span svg {
  transition: opacity 0.25s ease-in-out;
  transform: rotate(180deg);
  position: absolute;
  right: 0px;
  top: 5px;
  font-size: 11px;
  opacity: 0;
}
.btn-secondary:hover {
  color: #919091;
  outline: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-color: #000;
}
.btn-secondary:hover span svg {
  opacity: 1;
}
.btn-secondary:focus {
  color: #919091;
  outline: 0;
  box-shadow: none;
  background-color: #fff;
  border-color: #000;
  outline-offset: 0;
}
.btn-secondary:focus span svg {
  opacity: 1;
}
.btn-secondary:active {
  outline-width: 0;
  color: #919091;
  background-color: #fff;
  border-color: #919091;
}

.show > .btn-primary.dropdown-toggle {
  outline-width: 0;
  color: #fff;
  background-color: #404041;
  border-color: #919091;
  box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.36);
}

.btn-sm, .btn-group-sm > .btn {
  height: auto;
  padding: 8px 15px;
  line-height: 1.125rem;
}

.button-medium,
.btn-med {
  font-weight: 700;
  font-size: 16px !important;
  line-height: 20px;
  height: 50px;
  padding: 0 40px;
}

.btn-link {
  color: #2cae9c;
  font-weight: 400;
  border-radius: 0;
}

.btn-red {
  color: #fff;
  background-color: #2cae9c;
  border-color: #2cae9c;
  outline: 0;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 12px 20px;
  border-width: 2px;
  border-style: solid;
}
.btn-red:hover {
  color: #fff;
  outline: 0;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  background-color: #279c8c;
  border-color: #279c8c;
}
.btn-red:focus {
  color: #fff;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #279c8c;
  border-color: #279c8c;
  outline-offset: 0;
}
.btn-red:active, .btn-red:not(:disabled):not(.disabled):active {
  outline-width: 0;
  color: #fff;
  background-color: #279c8c;
  border-color: #2cae9c;
  -webkit-box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.36);
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.36);
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Card and container components
 * 
 */
.wdign-container .background-image {
  height: 195px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}
.wdign-container .wdign-row {
  gap: 30px;
}
@media (min-width: 768px) {
  .wdign-container .wdign-row {
    gap: unset;
  }
  .wdign-container .wdign-row .equal-height {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .wdign-container .wdign-row .equal-height .next-details {
    min-height: 105px;
  }
}
.wdign-container .equal-height {
  background-color: #fff;
  transition: box-shadow 0.5s ease-in-out;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.03), 0 2px 7px 0 rgba(0, 0, 0, 0.03);
}
@media (min-width: 768px) {
  .wdign-container .equal-height {
    min-height: 300px;
  }
}
.wdign-container .equal-height:hover {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.wdign-container .equal-height:hover .wdign-name {
  color: #279c8c;
}
.wdign-container .equal-height a {
  color: #000;
  text-decoration: none !important;
}
.wdign-container .equal-height img {
  width: 100%;
}
.wdign-container .equal-height .next-details {
  padding: 10px 15px 20px;
  display: block;
  min-height: 122px;
}
.wdign-container .equal-height span.h5 {
  color: #000;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Carousel components - bootstrap, splide, others
 * 
 */
#communityDetailsCarousel .splide__arrow--prev,
#propertyDetailsCarousel .splide__arrow--prev,
#propertyDetailsGallery .splide__arrow--prev {
  left: 0;
}
#communityDetailsCarousel .splide__arrow--next,
#propertyDetailsCarousel .splide__arrow--next,
#propertyDetailsGallery .splide__arrow--next {
  right: 0;
}
#communityDetailsCarousel .splide__arrow,
#propertyDetailsCarousel .splide__arrow,
#propertyDetailsGallery .splide__arrow {
  background: #404041;
  border-radius: 0;
  height: 2rem;
  width: 2rem;
}
@media (min-width: 768px) {
  #communityDetailsCarousel .splide__arrow,
  #propertyDetailsCarousel .splide__arrow,
  #propertyDetailsGallery .splide__arrow {
    height: 3rem;
    width: 3rem;
  }
}
#communityDetailsCarousel .splide__arrow svg,
#propertyDetailsCarousel .splide__arrow svg,
#propertyDetailsGallery .splide__arrow svg {
  fill: #fff;
  height: 1.125rem;
  width: 1.125rem;
}
@media (min-width: 768px) {
  #communityDetailsCarousel .splide__arrow svg,
  #propertyDetailsCarousel .splide__arrow svg,
  #propertyDetailsGallery .splide__arrow svg {
    height: 1.625rem;
    width: 1.625rem;
  }
}

#communityDetailsCarousel .splide__slide img,
#propertyDetailsCarousel .splide__slide img {
  height: 100%;
}

#propertyDetailsGallery .splide__slide img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Site-wide footer component
 * 
 */
footer.agent-footer {
  background-color: #000;
  color: #fff;
}
footer.agent-footer .site-logos {
  flex-direction: column-reverse;
  gap: 30px;
}
@media (min-width: 768px) {
  footer.agent-footer .site-logos {
    gap: unset;
  }
}
footer.agent-footer .site-links {
  flex-direction: column;
}
footer.agent-footer .site-logos,
footer.agent-footer .site-links {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  footer.agent-footer .site-logos,
  footer.agent-footer .site-links {
    flex-direction: row;
    justify-content: space-between;
  }
}
footer.agent-footer .rr-agent-container-footer {
  text-align: center;
}
footer.agent-footer .rr-agent-container-footer a {
  text-decoration: none;
}
@media (min-width: 768px) {
  footer.agent-footer .rr-agent-container-footer {
    text-align: right;
  }
}
footer.agent-footer .rr-agent-footer-name {
  font-size: 28px;
  line-height: 1;
}
footer.agent-footer .rr-agent-footer-title {
  font-size: 16px;
  margin-top: 5px;
}
footer.agent-footer .rr-agent-footer-logo {
  max-width: min(100%, 300px);
  max-height: 100px;
}
footer.agent-footer .site-links {
  margin-top: 15px;
  text-align: center;
}
@media (min-width: 768px) {
  footer.agent-footer .site-links {
    align-items: flex-end;
    text-align: unset;
  }
}
footer.agent-footer ul.footer-social-list,
footer.agent-footer ul.footer-nav {
  margin-bottom: 0;
}
footer.agent-footer .partner-logos {
  margin-top: 15px;
}
@media (min-width: 768px) {
  footer.agent-footer .partner-logos {
    margin-top: 0px;
  }
}
footer.agent-footer .agent-copyright {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.agent-footer a {
  color: #fff;
}

#company-footer > .row {
  margin: 0;
  padding: 20px 10px 10px;
}

#company-footer .icon-social {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

#company-footer .right-icons {
  width: 25px;
}

#company-footer a:hover {
  color: #dee2e6;
}

/*!
 * The social icons are implemented as svg vector files and imported through a use statement.
 * The svg element in the _footer.html does not contain a 'viewbox' statement since the individual
 * files have a viewbox defined. Here are the dimensions of the viewboxes:
 *    Facebook:   w:9.18  h:19.2
 *    Twitter:    w:23.75 h:19.14
 *    Instagram:  w:19.2  h:19.2
 *    LinkedIn:   w:19.14 h:19.14
 *    Blog:       w:48    h:24
 *
 * The outer container svg needs to have a width and height set, and the inner svg viewbox will
 * be scaled to fit that width and height, being constrained by the first dimension it bumps
 * against. For example, if the blog outer svg is defined as 96px x 24px, the icon will stay the same
 * height (24px) because this matches the viewport, and the icon will be centered with 24px padding
 * in the horizontal direction. If an offset is desired, then the x and y viewbox coordinates of the
 * inner svg container need to be modified (these are currently set as 0,0 - center of the container.
 * Likewise, if the blog outer svg is set to 96px x 48px, then the svg will double in size. The same
 * ratio works to reduce the svg size by cutting the outer svg container width and height values.
 *
 * For styling purposes, the elements will be made a consistent height, and
 * the container svg width and height will be overridden appropriately as shown below
 *
 */
.footer-social-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
@media (min-width: 768px) {
  .footer-social-list {
    justify-content: flex-start;
  }
}

.footer-social-link {
  display: inline-block;
  padding: 10px;
}
.footer-social-link .svg-icon {
  width: 26px;
  height: 26px;
}
.footer-social-link .svg-icon.facebook {
  width: 16px;
  height: 26px;
}
.footer-social-link .svg-icon.instagram,
.footer-social-link .svg-icon.linkedin,
.footer-social-link .svg-icon.pinterest,
.footer-social-link .svg-icon.reddit,
.footer-social-link .svg-icon.tumblr,
.footer-social-link .svg-icon.twitter-x {
  width: 26px;
  height: 26px;
}
.footer-social-link .svg-icon.youtube {
  width: 37px;
  height: 26px;
}
.footer-social-link .svg-icon.instagram {
  margin-top: 2px;
}
.footer-social-link .svg-icon.blog {
  width: 60px;
  height: 30px;
  margin-top: 12px;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Forms
 *
 * Overrides Bootstrap values
 * 
 */
.form-control {
  border-bottom-width: 1px;
  padding: 0 10px 8px 10px;
  box-shadow: none;
}
.form-control:disabled {
  color: #919091;
  background-color: #fff;
  opacity: 0.5;
  cursor: not-allowed;
}
.form-control[disabled] + svg {
  color: #919091;
  opacity: 0.5;
}

label, .label {
  font-family: Lato, Arial, Helvetica, san-serif;
  margin: 0;
  color: #c8c7c8;
  text-transform: uppercase;
  font-weight: 400;
}

.radio label,
.checkbox label {
  color: #919091;
  text-transform: unset;
}

.radio input[type=radio]:checked + label,
.checkbox input[type=checkbox]:checked + label {
  color: #000;
}

.float-label-wrap {
  position: relative;
}
.float-label-wrap label {
  font-size: 13px;
  position: absolute;
  top: -23px;
  letter-spacing: 0.8px;
  font-weight: 400;
  left: 0;
  color: #919091;
  pointer-events: none;
  opacity: 0;
  margin: 0;
  transition: top 0.25s linear, opacity 0.25s linear;
}
.float-label-wrap label.show {
  top: -23px;
  opacity: 1;
  pointer-events: auto;
}
.float-label-wrap label.error {
  z-index: 2;
  background: #fff;
  opacity: 1;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.checkbox label {
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px !important;
  min-height: 0;
  color: #000;
  line-height: 30px;
}

#save-search-form label {
  color: #000;
}

.search-additional-filters .checkbox {
  white-space: nowrap;
  overflow: hidden;
}
.search-additional-filters .checkbox input {
  accent-color: #404041;
}

.icon-input {
  position: relative;
}
.icon-input svg {
  position: absolute;
  right: 0;
  top: 5px;
}

/** 
* ====================================================================== 
* popup-form styles
* ======================================================================= 
**/
.tt-menu {
  width: 100%;
  background-color: #fff;
  border: 1px solid #919091;
  border-top: 0;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.09), 0 2px 7px 0 rgba(0, 0, 0, 0.06);
}

.tt-suggestion {
  padding: 2px 15px;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #000;
}
.tt-suggestion:hover {
  background-color: #919091;
  cursor: pointer;
  color: #fff;
}

.tt-suggestions {
  background-color: #fff;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.03), 0 2px 7px 0 rgba(0, 0, 0, 0.03);
  transition: box-shadow 0.25s ease-in;
  border: 1px solid #dedede;
  padding: 0;
}

.tt-suggestions > :not(a) {
  padding: 0 15px;
}

.tt-suggestions a:not([role=button]) {
  color: #000;
}

.tt-suggestions.card-off-white {
  border: none;
}

.tt-suggestions:hover {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.tt-suggestion {
  padding: 2px 15px;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #000;
}

.tt-suggestion p {
  margin: 0;
  color: #000;
}

.tt-suggestion.tt-cursor, .tt-suggestion.tt-cursor *, .tt-suggestion:hover {
  background-color: #919091;
  cursor: pointer;
  color: #fff;
}

.tt-suggestion.tt-cursor p, .tt-suggestion.tt-cursor * p, .tt-suggestion:hover p {
  color: #fff;
}

.tt-menu {
  width: 100%;
  background-color: #fff;
  border: 1px solid #919091;
  border-top: 0;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.09), 0 2px 7px 0 rgba(0, 0, 0, 0.06);
}

/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Site-wide header component
 * 
 */
body > header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 105;
  transition: top 0.3s ease, background-color 0.3s ease;
}
body > header.rr-nav-scroll {
  position: fixed;
}
body > header.rr-nav-scroll .rr-header-background {
  background-color: #2cae9c;
}
@media (min-width: 992px) {
  body > header.rr-nav-scroll .rr-header-background {
    background-color: #000;
  }
}
body > header.hide-nav {
  top: -150px;
}

body.homepage > header.rr-nav-scroll .rr-header-background {
  background-color: #2cae9c;
}
@media (min-width: 992px) {
  body.homepage > header.rr-nav-scroll .rr-header-background {
    background-color: #000;
  }
}

@media (min-width: 992px) {
  main#main-content {
    margin-top: 100px;
  }
}

div.rr-header-background {
  background-color: #2cae9c;
  transition: background-color 0.3s ease;
}
@media (min-width: 992px) {
  div.rr-header-background {
    background-color: #000;
  }
}

@media (min-width: 992px) {
  .rr-hero-transparent div.rr-header-background,
  .rr-hero-transparent-80 div.rr-header-background,
  .homepage.rr-hero-transparent-80 div.rr-header-background {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

@media (min-width: 992px) {
  .rr-hero-transparent-60 div.rr-header-background,
  .homepage.rr-hero-transparent div.rr-header-background {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.breadcrumb {
  background: none;
  color: #404041;
  padding: 15px 15px 12px 0;
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-radius: 0;
}
.breadcrumb a {
  color: #000;
}
.breadcrumb li {
  margin-left: 0;
}

.breadcrumb > li {
  display: inline-flex;
  padding-bottom: 0;
}

.breadcrumb > li + li:before {
  color: #404041;
  font-family: "bootstrap-icons";
  content: "\f285";
  padding: 0 5px;
}

.breadcrumb > .active {
  color: #919091;
}

.breadcrumb-bar {
  position: relative;
  background-color: rgba(255, 255, 255, 0.75);
}
@media (min-width: 992px) {
  .breadcrumb-bar .rr-prop-compare-container {
    position: absolute;
    right: max(80px, (100vw - 1040px) / 2);
  }
}
@media (min-width: 1200px) {
  .breadcrumb-bar .rr-prop-compare-container {
    right: calc((100vw - 1140px) / 2 + 80px);
  }
}
@media (min-width: 992px) {
  .breadcrumb-bar .rr-prop-compare-container.my-account {
    position: absolute;
    right: max(115px, (100vw - 970px) / 2);
  }
}
@media (min-width: 1200px) {
  .breadcrumb-bar .rr-prop-compare-container.my-account {
    right: calc((100vw - 1140px) / 2 + 115px);
  }
}

@media (max-width: 991.98px) {
  .rr-nav-account a.btn-account,
  .rr-nav-account button.btn-account {
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #fff;
  }
  .rr-nav-account a.btn-account.my-account,
  .rr-nav-account button.btn-account.my-account {
    background-color: #fff;
    border-color: #fff;
  }
  .rr-nav-account a.btn-account.my-account svg,
  .rr-nav-account button.btn-account.my-account svg {
    color: #000;
  }
  .rr-nav-account a.btn-account.my-account:hover,
  .rr-nav-account button.btn-account.my-account:hover {
    background-color: #919091;
    border-color: #919091;
  }
  .rr-nav-account a.btn-account:hover,
  .rr-nav-account button.btn-account:hover {
    border-color: #404041;
    background-color: #404041;
  }
  .rr-nav-account a.btn-account svg,
  .rr-nav-account button.btn-account svg {
    height: 20px;
    width: 20px;
    margin-bottom: 12px;
  }
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Nav, nav tabs, nav pills
 *
 * Overrides Bootstrap style values
 * 
 */
.nav.nav-tabs {
  background-color: #919091;
  border: 0;
  border-bottom: none;
}
.nav.nav-tabs > li, .nav.nav-tabs.nav-justified > li {
  padding-bottom: 0;
  padding-top: 15px;
  position: relative;
  border: none;
  margin-bottom: 1px;
  margin-top: 1px;
  text-align: center;
  vertical-align: bottom;
}
@media (min-width: 992px) {
  .nav.nav-tabs > li, .nav.nav-tabs.nav-justified > li {
    background-color: transparent;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.nav.nav-tabs > li > a, .nav.nav-tabs.nav-justified > li > a {
  display: block;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border: 0;
  border-radius: 0;
  position: relative;
  width: 100%;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .nav.nav-tabs > li > a, .nav.nav-tabs.nav-justified > li > a {
    padding: 14px 30px;
  }
}
@media (min-width: 992px) {
  .nav.nav-tabs > li > a, .nav.nav-tabs.nav-justified > li > a {
    padding: 14px 20px;
  }
}
.nav.nav-tabs > li > a:hover, .nav.nav-tabs > li > a:active, .nav.nav-tabs > li > a:focus, .nav.nav-tabs.nav-justified > li > a:hover, .nav.nav-tabs.nav-justified > li > a:active, .nav.nav-tabs.nav-justified > li > a:focus {
  background-color: #404041;
  border: 0;
}
.nav.nav-tabs > li > a.active, .nav.nav-tabs.nav-justified > li > a.active {
  color: #404041;
  background-color: #fff;
  opacity: 1;
}

/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Site-wide navigation component for all themes
 * Overrides the navigation styling on the main site/parent theme
 * 
 */
@media (min-width: 992px) {
  .rr-hero-transparent .navbar .navbar-agent-info-container {
    background-color: rgba(44, 174, 156, 0.8);
  }
}

.navbar {
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 80px;
  margin-left: -15px;
  padding: 0;
}
@media (min-width: 992px) {
  .navbar {
    min-height: 100px;
    margin-left: 0px;
  }
}
.navbar .navbar-toggler {
  z-index: 5;
  font-size: 2rem;
  padding: 0 0.25rem 0.625rem 0;
  padding-left: 11px;
  border-color: transparent;
}
.navbar .navbar-brand {
  flex: 1 0 0;
  text-align: center;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media (min-width: 992px) {
  .navbar .navbar-brand {
    flex: 0 0 auto;
    text-align: left;
  }
}
.navbar .navbar-agent-info-container {
  display: inline-flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
}
@media (min-width: 992px) {
  .navbar .navbar-agent-info-container {
    min-width: 2750px;
    margin-left: -2500px;
    padding-left: 2500px;
    padding-right: 30px;
    background-color: #2cae9c;
  }
}
.navbar .navbar-agent-info-container img {
  max-height: 60px;
  max-width: min(100% - 10px, 450px);
}
.navbar .navbar-agent-info-container,
.navbar .mobile-menu-brand {
  color: #fff;
  text-decoration: none;
}
.navbar .navbar-agent-info-container .rr-agent-header-name,
.navbar .mobile-menu-brand .rr-agent-header-name {
  font-size: 20px;
  letter-spacing: 0.025em;
  white-space: normal;
}
@media (min-width: 992px) {
  .navbar .navbar-agent-info-container .rr-agent-header-name,
  .navbar .mobile-menu-brand .rr-agent-header-name {
    white-space: nowrap;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-agent-info-container .rr-agent-header-name,
  .navbar .mobile-menu-brand .rr-agent-header-name {
    font-size: 24px;
  }
}
.navbar .navbar-agent-info-container .rr-agent-header-title,
.navbar .mobile-menu-brand .rr-agent-header-title {
  font-size: 14px;
  white-space: normal;
}
@media (min-width: 992px) {
  .navbar .navbar-agent-info-container .rr-agent-header-title,
  .navbar .mobile-menu-brand .rr-agent-header-title {
    white-space: nowrap;
  }
}
@media (min-width: 1200px) {
  .navbar .navbar-agent-info-container .rr-agent-header-title,
  .navbar .mobile-menu-brand .rr-agent-header-title {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-nav {
    flex: 1 0;
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-nav .nav-item {
    margin: 0 10px;
  }
  .navbar .navbar-nav .nav-item.dropdown.show .nav-link {
    background-color: #919091;
  }
  .navbar .navbar-nav .nav-item.dropdown.show .nav-link:focus, .navbar .navbar-nav .nav-item.dropdown.show .nav-link:active {
    background-color: #919091;
  }
}
.navbar .navbar-nav .nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s linear;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.navbar .navbar-nav .nav-link > div {
  font-size: 1.25rem;
  letter-spacing: 0.0375em;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .nav-link > div {
    font-size: 1.125rem;
  }
}
.navbar .navbar-nav .nav-link > i {
  font-size: 1.375rem;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .nav-link:hover {
    background-color: #919091;
  }
}
@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown-menu {
    margin: 0;
    box-shadow: 1px 1.73px 10px 0 rgba(0, 0, 0, 0.16), 1px 1.73px 5px 0 rgba(0, 0, 0, 0.26);
    border: none;
    display: block;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.2s linear;
  }
  .navbar .navbar-nav .dropdown-menu.show {
    opacity: 1;
    max-height: 750px;
  }
}
.navbar .navbar-nav .dropdown-item:hover, .navbar .navbar-nav .dropdown-item:focus, .navbar .navbar-nav .dropdown-item:active {
  color: #fff;
  background-color: #919091;
}
@media (max-width: 991.98px) {
  .navbar .navbar-nav .dropdown-item {
    padding: 1rem 1.25rem;
    font-size: 1.125rem;
    font-size: clamp(0.875rem, 4.375vw, 1.25rem);
    letter-spacing: 0.0375em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.rr-agent-header-logo {
  display: none;
  justify-content: flex-end;
  align-items: center;
  flex: 1 2 80px;
  max-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (min-width: 1200px) {
  .rr-agent-header-logo {
    flex: 1 1 120px;
  }
}
.rr-agent-header-logo img {
  max-height: 100%;
}
@media (min-width: 992px) {
  .rr-agent-header-logo {
    display: flex;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    flex: 2 1 auto;
    align-self: stretch;
    align-items: stretch;
  }
}
@media (min-width: 992px) and (min-width: 1200px) {
  .navbar-expand-lg .navbar-collapse {
    flex: 1 1 auto;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse .navbar-nav {
    flex-wrap: wrap;
    padding: 0 15px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
    justify-content: flex-start;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-link > i {
    display: none;
  }
}

.nav-header > div {
  height: 80px;
  padding: 0 1.25rem;
  color: #fff;
  background-color: #2cae9c;
}

.mobile-menu-divider {
  height: 0;
  overflow: hidden;
  border-top: 4px solid #c8c7c8;
}

.navbar-collapse.show .nav-item.show .nav-link {
  background-color: rgba(108, 117, 125, 0.25);
}

@media (max-width: 991.98px) {
  .rr-nav-account {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .rr-nav-account .label-account {
    display: none;
  }
}
@media (min-width: 992px) {
  .rr-nav-account {
    position: absolute;
    right: 0;
    bottom: -38px;
    z-index: 10;
  }
}
.rr-nav-account .btn-account {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 0.1em 0.25em;
  color: #fff;
  background: #fff;
  border: none;
  border-radius: 0.25em;
}
@media (min-width: 992px) {
  .rr-nav-account .btn-account {
    font-size: 0.75rem;
    padding: 0.1em 1em 0.3em;
    border-radius: 15px;
    background-color: #2cae9c;
  }
}
.rr-nav-account .btn-account:hover {
  background-color: #919091;
  text-decoration: none;
}
@media (min-width: 992px) {
  .rr-nav-account .btn-account:hover {
    background-color: #279c8c;
  }
}
.rr-nav-account .icon-account,
.rr-nav-account .icon-contact {
  padding: 10px;
  color: #fff;
}
.rr-nav-account a.icon-account,
.rr-nav-account a.icon-contact {
  text-decoration: none;
}
.rr-nav-account button.icon-account {
  background: transparent;
  margin: 0;
  box-shadow: none;
  border: none;
}
.rr-nav-account .icon-account {
  font-size: 1.875rem;
}
.rr-nav-account .icon-contact {
  font-size: 1.75rem;
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    position: absolute;
    top: 0;
    z-index: 10;
    overflow-x: hidden;
  }
  .navbar-collapse .navbar-nav {
    width: 75vw;
    min-width: 250px;
    height: 100vh;
    background-color: #fff;
  }
}

@media (max-width: 991.98px) {
  .nav-item.dropdown {
    position: unset;
  }
  .nav-item.dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    margin: 0;
    border-radius: 0;
    width: 100%;
    min-height: 100%;
    transform: translateX(100%);
    transition: transform 0.4s ease;
  }
  .nav-item.dropdown .dropdown-menu.shift-left {
    transform: translateX(0);
  }
  .nav-item.dropdown .dropdown-menu.d-block-show {
    display: block;
    border-width: 0px;
  }
}
@media (min-width: 992px) {
  .nav-item.dropdown .dropdown-menu {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.mobile-menu-back {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 0 1.25rem;
  color: #000;
  white-space: nowrap;
  transition: background-color 0.2s linear;
}
.mobile-menu-back:hover, .mobile-menu-back:focus, .mobile-menu-back:active {
  color: #000;
  background-color: rgba(108, 117, 125, 0.25);
  text-decoration: none;
}

.nav-item.dropdown .dropdown-divider {
  border-top: 5px solid #c8c7c8;
}

.nav-item.dropdown .dropdown-header {
  padding: 0.5rem 1.25rem;
  font-size: 1.5rem;
  font-size: clamp(1rem, 5vw, 1.5rem);
  font-weight: bold;
  color: #000;
}

@media (max-width: 991.98px) {
  .fixed-body-mobile-nav {
    overflow: hidden;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .fixed-navbar-nav {
    position: fixed;
    overflow-y: auto;
  }
}

/*
 * Don't particularly like using the max-width breakpoint, but want to allow Bootstrap styles to
 * take precedence when not using the mobile slideout drawer
 */
@media (max-width: 991.98px) {
  .navbar-nav .nav-link {
    padding: 1rem 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-collapse .navbar-nav .nav-link {
    color: #000;
  }
}

#mobileNavBackground {
  display: none;
  position: fixed;
  inset: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  transition: opacity 0.3s ease;
}
#mobileNavBackground.show {
  display: block;
}
@media (min-width: 992px) {
  #mobileNavBackground.show {
    display: none;
  }
}
#mobileNavBackground .close-icon {
  position: fixed;
  top: 25px;
  right: 9vw;
  height: 32px;
  width: 32px;
  fill: var(--white);
}

.mobile-quick-nav {
  display: flex;
  overflow-x: auto;
  margin: 0 -15px;
  padding: 0;
  list-style: none;
  scrollbar-width: none;
  background-color: #fff;
}
.mobile-quick-nav li {
  padding: 10px 15px;
  white-space: nowrap;
  background-color: inherit;
}
.mobile-quick-nav li a {
  color: #000;
  text-decoration: none;
}
.mobile-quick-nav::-webkit-scrollbar {
  display: none;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Tabbed accordion
 * A special component when collapsable elements are contained within tabs
 * Currently, this component is limited to the agent details and community details pages
 * If this becomes more common across the site, move to a specialized class so it doesn't
 * affect the operation of other generic Bootstrap components across the site
 * 
 */
@media (min-width: 992px) {
  #realtor-bio-page .tab-content > .tab-pane > .collapse,
  .property-details-page .tab-content > .tab-pane > .collapse {
    display: block;
  }
  #realtor-bio-page .tab-content > .tab-pane > .collapsing,
  .property-details-page .tab-content > .tab-pane > .collapsing {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  #realtor-bio-page .tab-content > .tab-pane,
  .property-details-page .tab-content > .tab-pane {
    display: block;
    opacity: 1;
  }
}
@media (min-width: 992px) {
  #realtor-bio-page .mobileTrigger,
  .property-details-page .mobileTrigger {
    display: none;
  }
}

@media (min-width: 768px) {
  #community-bio-content > .tab-content > .tab-pane > .collapse {
    display: block;
  }
  #community-bio-content > .tab-content > .tab-pane > .collapsing {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  #community-bio-content > .tab-content > .tab-pane {
    display: block;
    opacity: 1;
  }
}
@media (min-width: 768px) {
  #community-bio-content .mobileTrigger {
    display: none;
  }
}

/*!
 * Reinhart Realtor Website Theme
 *
 * Page specific styles for https://www.reinhartrealtors.com/
 */
.homepage .rr-hero-container {
  height: 300px;
  height: clamp(350px, 50px + 75vw, 500px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media (min-width: 768px) {
  .homepage .rr-hero-container {
    height: 500px;
    height: clamp(500px, 100vw - 300px, 600px);
  }
}
@media (min-width: 992px) {
  .homepage .rr-hero-container {
    height: 700px;
    height: clamp(600px, 400px + 20vw, 800px);
  }
}
.homepage .rr-quick-search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.homepage .rr-quick-search-container .rr-quick-search-tagline {
  width: 225px;
  color: #fff;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  letter-spacing: 0.5pt;
  text-shadow: 0px 0px 8px #000;
}
@media (min-width: 576px) {
  .homepage .rr-quick-search-container .rr-quick-search-tagline {
    width: auto;
  }
}
@media (min-width: 768px) {
  .homepage .rr-quick-search-container .rr-quick-search-tagline {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .homepage .rr-quick-search-container .rr-quick-search-tagline {
    font-size: 290%;
  }
}
.homepage .rr-quick-search-container .rr-quick-search-form {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 500px;
}
@media (min-width: 768px) {
  .homepage .rr-quick-search-container .rr-quick-search-form {
    width: 500px;
    max-width: unset;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 992px) {
  .homepage .rr-quick-search-container .rr-quick-search-form {
    width: 550px;
  }
}
.homepage .rr-quick-search-container .rr-quick-search-form form {
  display: flex;
  position: relative;
  width: 100%;
}
.homepage .rr-quick-search-container .rr-quick-search-form form input {
  width: 100%;
  padding: 10px 70px 10px 30px;
  border-radius: 30px;
  border: none;
  outline: none;
  font-size: 16px;
}
.homepage .rr-quick-search-container .rr-quick-search-form form input::placeholder {
  color: #000;
}
@media (min-width: 768px) {
  .homepage .rr-quick-search-container .rr-quick-search-form form input {
    padding: 12px 80px 14px 35px;
  }
}
.homepage .rr-quick-search-container .rr-quick-search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #2cae9c;
  color: #fff;
  border-radius: 30px;
  border: none;
  width: 70px;
  padding: 0;
  text-align: center;
  font-size: 28px;
}
@media (min-width: 576px) {
  .homepage .rr-quick-search-container .rr-quick-search-form form button {
    width: 80px;
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .homepage .rr-quick-search-container .rr-quick-search-form form button {
    width: 90px;
  }
}
.homepage .rr-quick-search-container .rr-quick-search-links {
  display: flex;
  justify-content: space-evenly;
}
@media (min-width: 768px) {
  .homepage .rr-quick-search-container .rr-quick-search-links {
    width: 500px;
  }
}
@media (min-width: 992px) {
  .homepage .rr-quick-search-container .rr-quick-search-links {
    width: 550px;
  }
}
.homepage .rr-quick-search-container .rr-quick-search-links a {
  height: auto;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 10px;
  text-shadow: 0px 0px 8px #000;
}
@media (min-width: 576px) {
  .homepage .rr-quick-search-container .rr-quick-search-links a {
    font-size: 16px;
    padding: 10px 15px;
  }
}
@media (min-width: 992px) {
  .homepage .rr-quick-search-container .rr-quick-search-links a {
    font-size: 18px;
  }
}
.homepage .rr-quick-search-container .rr-quick-search-links a:hover, .homepage .rr-quick-search-container .rr-quick-search-links a:focus {
  text-decoration: underline;
}
.homepage .rr-quick-search-container .rr-quick-search-links a:focus {
  box-shadow: none;
}
.homepage .rr-intro-heading {
  color: #2cae9c;
  font-size: clamp(32px, 0.75px + 12.5vw, 64px);
  font-weight: 300;
  letter-spacing: 0.025em;
  line-height: 1.2;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .homepage .rr-intro-heading {
    margin-left: 60px;
    font-size: 64px;
  }
}
@media (min-width: 1200px) {
  .homepage .rr-intro-heading {
    margin-left: 150px;
  }
}
.homepage .rr-intro-text {
  font-size: clamp(16px, 12.25px + 1.5vw, 20px);
  line-height: 1.8;
}
@media (min-width: 992px) {
  .homepage .rr-intro-text {
    width: 340px;
  }
}
.homepage .rr-tools-row {
  justify-content: center;
}
@media (min-width: 576px) {
  .homepage .rr-tools-row {
    justify-content: unset;
  }
}
@media (min-width: 768px) {
  .homepage .rr-tools-row {
    justify-content: space-evenly;
  }
}
.homepage .rr-tool-container a {
  min-width: 170px;
  min-width: clamp(140px, 30vw, 170px);
}
@media (min-width: 576px) {
  .homepage .rr-tool-container a {
    min-width: unset;
  }
}
.homepage .rr-tool-container a:hover, .homepage .rr-tool-container a:focus {
  color: #fff;
}
.homepage .rr-tool-container img {
  width: auto;
  height: 130px;
}
.homepage .rr-tool-container svg {
  display: block;
  width: 120px;
  height: 130px;
  margin: 0 auto 15px;
  padding-top: 10px;
  color: #fff;
}
.homepage #communityWrapper .community {
  text-align: center;
}
.homepage #communityWrapper .community a {
  display: block;
  transition: box-shadow 0.25s ease-in;
}
.homepage #communityWrapper .community a:hover {
  text-decoration: none !important;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.09), 0 2px 7px 0 rgba(0, 0, 0, 0.06);
}
.homepage #communityWrapper .community a:hover span {
  color: #279c8c;
}
.homepage #communityWrapper .community img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}
.homepage #market-stats .card-shadow {
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.1), 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 30px;
  min-height: 180px;
  min-width: 165px;
}
@media (min-width: 992px) {
  .homepage #market-stats .card-shadow {
    min-height: 200px;
  }
}
.homepage #market-stats .card-shadow h5 {
  min-height: 40px;
}
.homepage #market-stats .card-shadow h5.h1 {
  letter-spacing: unset;
}
.homepage #featuredBlogContainer {
  position: relative;
  min-height: 125px;
}
.homepage .featured-blog-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background-color: #fff;
  transition: opacity 1s linear;
  z-index: 5;
}
.homepage .featured-blog-loading.loading {
  opacity: 1;
}
.homepage .sm-blog-img {
  height: max(190px, 35vw);
}
@media (min-width: 768px) {
  .homepage .sm-blog-img {
    height: 190px;
  }
}
.homepage .popular_feed a {
  color: #2cae9c;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}
.homepage .popular_feed a:hover, .homepage .popular_feed a:active {
  color: #279c8c;
  border-bottom: 1px solid #279c8c;
}
.homepage .popular_feed .sm-blog-img {
  height: max(190px, 35vw);
}
@media (min-width: 768px) {
  .homepage .popular_feed .sm-blog-img {
    height: 400px;
  }
}
.homepage h3.home-blog-sidebar {
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  font-weight: 400;
}
.homepage h3.home-blog-sidebar a {
  color: #2cae9c;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}
.homepage h3.home-blog-sidebar a:hover, .homepage h3.home-blog-sidebar a:active {
  color: #279c8c;
  border-bottom: 1px solid #279c8c;
}

#quick-search-bar {
  background-color: transparent;
  bottom: 0;
  padding: 0;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #quick-search-bar {
    margin-top: 2px;
  }
}
@media screen and (min-width: 768px) {
  #quick-search-bar:before {
    box-shadow: inset 0 -230px 160px -170px #000;
    height: 250px;
    display: block;
    width: 100%;
    content: "";
    position: absolute;
    z-index: 1;
    margin-top: -250px;
  }
}
#quick-search-bar .tabClass .tab-content {
  margin-top: 0;
}

@media screen and (min-width: 768px) {
  #quick-search-bar .tabClass .tab-content {
    padding: 20px 0 0;
  }
}
@media screen and (min-width: 992px) {
  #quick-search-bar .tabClass .tab-content {
    padding: 20px 0;
  }
}
#quick-search-bar .address-city-zip {
  height: 42px;
  padding: 8px 15px;
  display: block;
  color: #8c8c8c;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  border: 1px solid #8c8c8c;
}

#quick-search-bar .address-city-zip.sm-typeahead-field {
  padding: 7px 50px 8px 15px;
}

#quick-search-bar .address-city-zip[placeholder] {
  text-overflow: ellipsis;
}

#quick-search-bar .qs-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  height: 42px;
  min-width: 0;
}

#quick-search-bar .nav.nav-tabs {
  background-color: transparent;
  padding: 0;
  position: absolute;
  z-index: 1;
  left: 0;
  top: -42px;
}

#quick-search-bar .nav.nav-tabs span.h5 {
  color: inherit;
}

#quick-search-bar .nav.nav-tabs > li {
  background-color: transparent;
  text-indent: 0 !important;
  padding-top: 0;
}

#quick-search-bar .nav.nav-tabs > li > a {
  color: #fff !important;
  opacity: 1;
  padding: 10px 19px;
}

@media screen and (min-width: 1200px) {
  #quick-search-bar .nav.nav-tabs > li > a {
    padding: 10px 29px;
  }
}
#quick-search-bar .nav.nav-tabs > li > a span {
  border: 0;
  color: #fff;
}

#quick-search-bar .nav.nav-tabs > li > a:hover, #quick-search-bar .nav.nav-tabs > li > a:active, #quick-search-bar .nav.nav-tabs > li > a:focus {
  background-color: #626e7a;
}

#quick-search-bar .nav.nav-tabs > li > a:hover span, #quick-search-bar .nav.nav-tabs > li > a:active span, #quick-search-bar .nav.nav-tabs > li > a:focus span {
  border: 0;
  color: #fff;
  opacity: 1;
}

#quick-search-bar .nav.nav-tabs > li > a:hover span:after, #quick-search-bar .nav.nav-tabs > li > a:active span:after, #quick-search-bar .nav.nav-tabs > li > a:focus span:after {
  width: 100%;
}

#quick-search-bar .nav.nav-tabs > li.active {
  background-color: #f5f5f5;
}

#quick-search-bar .nav.nav-tabs > li.active span {
  color: #2e2e2e;
}

#quick-search-bar .nav.nav-tabs > li.active > a {
  color: #2e2e2e;
  background-color: transparent !important;
}

#quick-search-bar .nav.nav-tabs > li.active > a:hover, #quick-search-bar .nav.nav-tabs > li.active > a:active, #quick-search-bar .nav.nav-tabs > li.active > a:focus {
  background-color: transparent;
}

#quick-search-bar .nav.nav-tabs > li.active > a:hover span, #quick-search-bar .nav.nav-tabs > li.active > a:active span, #quick-search-bar .nav.nav-tabs > li.active > a:focus span {
  border: 0;
  color: #2e2e2e;
  opacity: 1;
}

#quick-search-bar .nav.nav-tabs > li.active > a:hover span:after, #quick-search-bar .nav.nav-tabs > li.active > a:active span:after, #quick-search-bar .nav.nav-tabs > li.active > a:focus span:after {
  width: 100%;
}

#quick-search-bar .mobile-selection a {
  color: #a41832;
}

#quick-search-bar .mobile-selection a:hover, #quick-search-bar .mobile-selection a:active, #quick-search-bar .mobile-selection a:focus {
  text-decoration: none;
}

#quick-search-bar .mobile-selection a:hover span, #quick-search-bar .mobile-selection a:active span, #quick-search-bar .mobile-selection a:focus span {
  color: #c4122f;
}

.tools a:hover, .tools a:active {
  color: #fff;
  text-decoration: underline;
}

#quick-search-tabs .nav-tabs {
  border-bottom: none;
}

#quick-search-tabs li {
  padding-bottom: 0;
}

/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Home page styles
 * 
 */
body.homepage .btn-primary {
  background-color: #2cae9c;
  border-color: #2cae9c;
  color: #fff;
}
body.homepage .btn-primary:hover, body.homepage .btn-primary:focus {
  background-color: #279c8c;
  border-color: #279c8c;
  color: #fff;
}
body.homepage .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #279c8c;
  border-color: #279c8c;
  color: #fff;
  box-shadow: none;
}
body.homepage .btn-secondary {
  background-color: #fff;
  border-color: #2cae9c;
  color: #2cae9c;
}
body.homepage .btn-secondary:hover {
  background-color: #279c8c;
  border-color: #279c8c;
  color: #fff;
}
body.homepage #testimonials .btn-primary {
  background-color: #2cae9c;
  border-color: #2cae9c;
  color: #fff;
}
body.homepage #testimonials .btn-primary:hover, body.homepage #testimonials .btn-primary:focus {
  background-color: #279c8c;
  border-color: #279c8c;
  color: #fff;
}
body.homepage #testimonials .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #279c8c;
  border-color: #279c8c;
  color: #fff;
  box-shadow: none;
}

section.rr-agent-home-about {
  margin-top: 30px;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  section.rr-agent-home-about {
    margin-top: 60px;
  }
}
section.rr-agent-home-about .home-about-photo-container {
  overflow: hidden;
  width: 250px;
  height: 250px;
  max-width: calc(100vw - 30px);
  max-height: calc(100vw - 30px);
  border-radius: 50%;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  section.rr-agent-home-about .home-about-photo-container {
    width: 200px;
    height: 200px;
    margin-bottom: 50px;
  }
}
@media (min-width: 992px) {
  section.rr-agent-home-about .home-about-photo-container {
    width: 250px;
    height: 250px;
  }
}
@media (min-width: 1200px) {
  section.rr-agent-home-about .home-about-photo-container {
    width: 275px;
    height: 275px;
  }
}
section.rr-agent-home-about .home-about-photo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
section.rr-agent-home-about .home-about-logo-container {
  width: 300px;
  max-width: calc(100vw - 30px);
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  section.rr-agent-home-about .home-about-logo-container {
    margin-left: unset;
    margin-right: unset;
  }
}
@media (min-width: 992px) {
  section.rr-agent-home-about .home-about-logo-container {
    width: 350px;
  }
}
@media (min-width: 1200px) {
  section.rr-agent-home-about .home-about-logo-container {
    width: 400px;
  }
}
section.rr-agent-home-about .home-about-logo-container img {
  width: 100%;
  height: 100%;
  max-height: 150px;
  object-fit: contain;
  object-position: center;
}
@media (min-width: 768px) {
  section.rr-agent-home-about .home-about-logo-container img {
    object-position: left;
    max-height: 120px;
  }
}
@media (min-width: 992px) {
  section.rr-agent-home-about .home-about-logo-container img {
    max-height: 150px;
  }
}
section.rr-agent-home-about #agentBio {
  position: relative;
}
section.rr-agent-home-about #agentBio.collapse:not(.show) {
  display: block;
  height: 350px;
  overflow: hidden;
}
@media (min-width: 768px) {
  section.rr-agent-home-about #agentBio.collapse:not(.show) {
    height: 275px;
  }
}
@media (min-width: 992px) {
  section.rr-agent-home-about #agentBio.collapse:not(.show) {
    height: 325px;
  }
}
@media (min-width: 1200px) {
  section.rr-agent-home-about #agentBio.collapse:not(.show) {
    height: 350px;
  }
}
section.rr-agent-home-about #agentBio.collapse:not(.show):not(.show)::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 10%, white 80%);
}
section.rr-agent-home-about #agentBio.collapsing {
  height: 350px;
}
@media (min-width: 768px) {
  section.rr-agent-home-about #agentBio.collapsing {
    height: 275px;
  }
}
@media (min-width: 992px) {
  section.rr-agent-home-about #agentBio.collapsing {
    height: 325px;
  }
}
@media (min-width: 1200px) {
  section.rr-agent-home-about #agentBio.collapsing {
    height: 350px;
  }
}
section.rr-agent-home-about #btnReadMore {
  height: unset;
  padding: 0;
  text-decoration: none;
}
section.rr-agent-home-about #btnReadMore:focus {
  box-shadow: none;
}
section.rr-agent-home-about #btnReadMore i {
  display: inline-block;
  transition: transform 0.4s linear;
}
section.rr-agent-home-about #btnReadMore[aria-expanded=true] i {
  transform: rotate(-180deg);
}

section.testimonials-listings {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  section.testimonials-listings {
    flex-direction: column-reverse;
  }
}

.rr-agent-home-listings-container .rr-agent-home-listings-select {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 30px;
}
@media (min-width: 992px) {
  .rr-agent-home-listings-container .rr-agent-home-listings-select {
    justify-content: flex-start;
  }
  .rr-agent-home-listings-container .rr-agent-home-listings-select ul {
    justify-content: center;
    flex-grow: 1;
    padding-left: 52px;
  }
}
.rr-agent-home-listings-container .results-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
@media (min-width: 768px) {
  .rr-agent-home-listings-container .results-grid {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: unset;
  }
}
.rr-agent-home-listings-container .rr-prop-compare-container {
  display: none;
  padding: 0;
}
@media (min-width: 992px) {
  .rr-agent-home-listings-container .rr-prop-compare-container {
    display: block;
  }
}
.rr-agent-home-listings-container .rr-prop-compare-container button {
  border: none;
  font-weight: 400;
}
.rr-agent-home-listings-container .nav.nav-tabs {
  background-color: transparent;
}
.rr-agent-home-listings-container .nav.nav-tabs > li {
  padding-top: 0;
}
.rr-agent-home-listings-container .nav-link {
  color: #000;
  border: none;
  font-weight: 400;
}
.rr-agent-home-listings-container .nav-link .radio-checked {
  display: none;
}
.rr-agent-home-listings-container .nav-link .svg-icon {
  margin-bottom: 4px;
}
.rr-agent-home-listings-container .nav-link.active {
  border: none;
}
.rr-agent-home-listings-container .nav-link.active .radio-checked {
  display: inline-block;
}
.rr-agent-home-listings-container .nav-link.active .radio-unchecked {
  display: none;
}
.rr-agent-home-listings-container button {
  font-size: inherit;
  color: inherit;
  text-transform: inherit;
  text-decoration: none;
  font-weight: 300;
  padding: 0;
}
.rr-agent-home-listings-container button i {
  font-size: 30px;
  transition: transform 0.2s linear;
}
@media (max-width: 991.98px) {
  .rr-agent-home-listings-container button i {
    font-size: 24px;
  }
}
.rr-agent-home-listings-container button:not(.collapsed) i {
  transform: rotate(-180deg);
}
.rr-agent-home-listings-container button:hover, .rr-agent-home-listings-container button:focus, .rr-agent-home-listings-container button:active {
  color: #2cae9c;
}
.rr-agent-home-listings-container button:focus {
  box-shadow: none;
}

.rr-agent-home-testimonials-container {
  background-color: #404041;
  margin-top: 40px;
  padding: 30px 0;
}
@media (min-width: 768px) {
  .rr-agent-home-testimonials-container {
    margin-top: 0px;
    margin-bottom: 60px;
  }
}
.rr-agent-home-testimonials-container h2 {
  text-align: center;
  color: #fff;
}
.rr-agent-home-testimonials-container p {
  color: #fff;
}
.rr-agent-home-testimonials-container .home-testimonials-header {
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .rr-agent-home-testimonials-container .home-testimonials-header {
    margin-bottom: 30px;
  }
}
.rr-agent-home-testimonials-container .home-testimonials-icon {
  text-align: center;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .rr-agent-home-testimonials-container .home-testimonials-icon {
    margin-top: -10px;
    margin-bottom: 0;
  }
}
.rr-agent-home-testimonials-container .home-testimonials-icon svg {
  color: #fff;
  width: 72px;
  height: 72px;
}
.rr-agent-home-testimonials-container .home-testimonials-content {
  position: relative;
  overflow: hidden;
  max-height: 375px;
}
.rr-agent-home-testimonials-container .home-testimonials-content:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: calc(100% - 300px);
  background: linear-gradient(180deg, rgba(64, 64, 65, 0) 0%, rgba(64, 64, 65, 0) 10%, #404041 80%);
}

.rr-agent-home-custom-content {
  margin: 60px 0;
}

.rr-agent-wells-container {
  background-color: #dedede;
  padding: 60px 0 30px;
}
.rr-agent-wells-container .agent-wells {
  position: relative;
  height: 100%;
  background-color: #fff;
}
@media (min-width: 992px) {
  .rr-agent-wells-container .agent-wells {
    min-height: 500px;
  }
}
.rr-agent-wells-container .wells-image {
  height: 150px;
}
@media (min-width: 768px) {
  .rr-agent-wells-container .wells-image {
    height: 250px;
  }
}
@media (min-width: 992px) {
  .rr-agent-wells-container .wells-image {
    height: 290px;
  }
}
.rr-agent-wells-container .wells-title {
  min-height: 75px;
}
@media (min-width: 992px) {
  .rr-agent-wells-container .wells-title {
    min-height: 90px;
  }
}
.rr-agent-wells-container .wells-text {
  min-height: 65px;
}
.rr-agent-wells-container #toolsYouNeed h4 {
  padding-top: 20px;
  margin-bottom: 65px;
  text-align: center;
}
.rr-agent-wells-container #toolsYouNeed a {
  color: #2cae9c;
}
.rr-agent-wells-container #toolsYouNeed a:hover, .rr-agent-wells-container #toolsYouNeed a:focus {
  color: #279c8c;
}
.rr-agent-wells-container #toolsYouNeed svg {
  width: 125px;
  height: 100px;
}
.rr-agent-wells-container #agentJustListedContainer {
  padding: 20px;
}
.rr-agent-wells-container #agentJustListedContainer h4 {
  margin-bottom: 20px;
  text-align: center;
}
.rr-agent-wells-container #agentJustListedContainer .carousel-item {
  transition-duration: 1.5s;
}
.rr-agent-wells-container #agentJustListedContainer .carousel-control-prev,
.rr-agent-wells-container #agentJustListedContainer .carousel-control-next {
  width: 10%;
  opacity: 0.8;
}
.rr-agent-wells-container #agentJustListedContainer .carousel-control-prev:hover,
.rr-agent-wells-container #agentJustListedContainer .carousel-control-next:hover {
  opacity: 1;
}
.rr-agent-wells-container #agentJustListedContainer .carousel-control-prev span,
.rr-agent-wells-container #agentJustListedContainer .carousel-control-next span {
  margin-bottom: 145px;
}
@media (min-width: 576px) {
  .rr-agent-wells-container #agentJustListedContainer .carousel-control-prev span,
  .rr-agent-wells-container #agentJustListedContainer .carousel-control-next span {
    margin-bottom: 65px;
  }
}
.rr-agent-wells-container #agentJustListedContainer #justListedCarousel a {
  text-decoration: none;
  color: #000;
}
.rr-agent-wells-container #agentJustListedContainer #justListedCarousel a:hover {
  color: #279c8c;
}
.rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-img-container {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  overflow: hidden;
}
.rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-details-container {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
}
@media (min-width: 576px) {
  .rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-details-container {
    text-align: left;
  }
}
.rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-details-container .property-address,
.rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-details-container .property-details {
  margin-bottom: 10px;
}
.rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-details-container .property-address {
  font-weight: bold;
}
@media (min-width: 576px) {
  .rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-details-container .property-details {
    text-align: right;
  }
}
.rr-agent-wells-container #agentJustListedContainer .just-listed-container .property-details-container .property-details span {
  margin-left: 5px;
}
.rr-agent-wells-container #agentFeaturedBlogContainer .agent-featured-blog-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: #fff;
  transition: opacity 1s linear;
  z-index: 5;
}
.rr-agent-wells-container #agentFeaturedBlogContainer .agent-featured-blog-loading.loading {
  opacity: 1;
}
.rr-agent-wells-container #agentFeaturedBlogContainer .agent-featured-blog-loading p {
  margin-top: 160px;
}
.rr-agent-wells-container #agentFeaturedBlogContainer .agent-featured-blog-loading span {
  color: #919091;
  font-size: 30px;
}
.rr-agent-wells-container #agentHomeValueContainer button {
  width: 100%;
}
@media (min-width: 576px) {
  .rr-agent-wells-container #agentHomeValueContainer button {
    width: unset;
  }
}

/*!
 * Reinhart Realtor Website Theme
 *
 * Page specific styles for https://www.reinhartrealtors.com/offices/
 */
#office-roster-page .office-roster-map-container {
  position: relative;
  min-height: 600px;
}

#office-roster-page .office-roster-map-container .office-roster-map {
  background: #ced4da;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  height: -webkit-calc(100% - 46px);
  height: -moz-calc(100% - 46px);
  height: calc(100% - 46px);
}

@media (min-width: 768px) {
  #office-roster-page .office-roster-map-container .office-roster-map {
    height: 50%;
  }
}

@media (min-width: 992px) {
  #office-roster-page .office-roster-map-container .office-roster-map {
    height: 100%;
    width: -webkit-calc(100% - 550px);
    width: -moz-calc(100% - 550px);
    width: calc(100% - 550px);
  }
}

@media (min-width: 1200px) {
  #office-roster-page .office-roster-map-container .office-roster-map {
    width: -webkit-calc(100% - 580px);
    width: -moz-calc(100% - 580px);
    width: calc(100% - 580px);
  }
}

#office-roster-page .office-roster-map-container .office-roster-list {
  height: 46px;
  top: -webkit-calc(100% - 46px);
  top: -moz-calc(100% - 46px);
  top: calc(100% - 46px);
  max-height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transition: top 0.25s ease, height 0.25s ease;
}

@media (max-width: 991.98px) {
  #office-roster-page .office-roster-map-container .office-roster-list {
    padding-bottom: 30px;
  }
}

@media (min-width: 768px) {
  #office-roster-page .office-roster-map-container .office-roster-list {
    padding-bottom: 0;
    top: 50%;
    height: 50%;
    z-index: 102;
  }
}

@media (min-width: 992px) {
  #office-roster-page .office-roster-map-container .office-roster-list {
    top: 0;
    left: auto;
    width: 550px;
    height: 100%;
    padding-left: 5px;
  }
}

@media (min-width: 1200px) {
  #office-roster-page .office-roster-map-container .office-roster-list {
    width: 580px;
  }
}

#office-roster-page .office-roster-map-container .office-roster-list .office-roster-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  height: auto;
}

#office-roster-page .office-roster-map-container.open .office-roster-list {
  top: 0;
  height: 100%;
}

#office-roster-page .office-roster-map-container.open .office-roster-list .icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  transition: transform 0.25s ease-in-out;
}

#office-roster-page .office-roster-map-container .single-office {
  background-color: #fff;
  border: 1px solid #adb5bd;
  -webkit-transition: box-shadow 0.25s ease;
  -moz-transition: box-shadow 0.25s ease;
  -ms-transition: box-shadow 0.25s ease;
  -o-transition: box-shadow 0.25s ease;
  transition: box-shadow 0.25s ease;
}

#office-roster-page .office-roster-map-container .single-office.active, #office-roster-page .office-roster-map-container .single-office:hover {
  background-color: #f8f9fa;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.35);
}

#office-roster-page .office-roster-map-container .single-office.active .office-name, #office-roster-page .office-roster-map-container .single-office:hover .office-name {
  color: #279c8c;
}

@media (min-width: 768px) {
  #office-roster-page .office-roster-map-container .single-office .office-image, #office-roster-page .office-roster-map-container .single-office .office-info {
    float: left;
  }
}

@media (min-width: 768px) {
  #office-roster-page .office-roster-map-container .single-office .office-image {
    width: 45%;
    max-width: 247px;
  }
}

@media (min-width: 768px) {
  #office-roster-page .office-roster-map-container .single-office .office-info {
    width: 50%;
    margin-left: 2.5% !important;
  }
}

#office-roster-page .office-roster-map-container .single-office address {
  line-height: 22px;
}

#office-roster-page .office-roster-map-container .single-office a:hover {
  text-decoration: none;
}

#office-roster-page .office-roster-map-container .resultsPropertyBubble {
  min-width: 370px;
  min-height: 116px;
  overflow: hidden;
}

#office-roster-page .office-roster-map-container .gm-style img {
  width: 100%;
  max-width: 140px;
}

#office-roster-page .office-roster-map-container .gm-style-iw > div > div {
  overflow: hidden !important;
}

#office-bio-page .office-info-padding {
  padding: 20px;
}

@media (min-width: 768px) {
  #office-bio-page .office-info-padding {
    padding: 0;
  }
}

@media (min-width: 992px) {
  #office-bio-page .office-info-padding {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  #office-bio-page .office-name {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  #office-bio-page .office-name {
    font-size: 34px;
  }
}

#office-bio-page .video-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 62px;
  width: 62px;
  height: 62px;
  display: block;
}

#office-bio-page .office-results-map {
  width: 100%;
  height: 300px;
  border-bottom: 3px solid #fff;
}

.gm-style .gm-style-iw {
  font: inherit;
  font-size: 16px;
  font-family: inherit;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Agent search form and results styles
 * 
 */
input#realtor_name::placeholder {
  color: #000;
}

.agent-search #roster-result-container,
#team-members .agent-team-member-container {
  display: flex;
  justify-content: center;
}

.agent-search-grid {
  display: grid;
  gap: 40px 30px;
  grid-template-columns: minmax(100%, 270px);
  justify-items: center;
}
@media (min-width: 768px) {
  .agent-search-grid {
    grid-template-columns: repeat(2, 270px);
    gap: 50px 60px;
  }
}
@media (min-width: 992px) {
  .agent-search-grid {
    grid-template-columns: repeat(3, 270px);
    gap: 50px 60px;
  }
}
@media (min-width: 1200px) {
  .agent-search-grid {
    grid-template-columns: repeat(4, 255px);
    gap: 50px 30px;
  }
}

.agent-img-container {
  width: 100%;
  aspect-ratio: 3/4;
  overflow: hidden;
}
@supports not (aspect-ratio: 3/4) {
  .agent-img-container {
    height: min(60vw, 260px);
  }
  @media (min-width: 768px) {
    .agent-img-container {
      height: 220px;
    }
  }
  @media (min-width: 992px) {
    .agent-img-container {
      height: 200px;
    }
  }
  @media (min-width: 1200px) {
    .agent-img-container {
      height: 240px;
    }
  }
}
.agent-img-container a {
  width: 100%;
  height: 100%;
}
.agent-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.entity-agent a {
  color: #2e2e2e;
}
.entity-agent a:hover {
  text-decoration: none;
}
.entity-agent a:hover span {
  border-bottom: 1px solid #404041;
}
.entity-agent a.entity-phone {
  color: #000;
}
.entity-agent a.entity-phone:hover {
  color: #279c8c;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .entity-agent .min-height-52, .entity-agent .min-height-80 {
    min-height: 0;
  }
}
/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Content (CMS) page styles
 * 
 */
.cms-page {
  margin-top: 0px;
  margin-bottom: 60px;
  font-family: Lato, Arial, Helvetica, san-serif !important;
}
.cms-page h1, .cms-page h2, .cms-page h3, .cms-page h4 {
  margin-bottom: 25px;
}
.cms-page h5, .cms-page h6 {
  margin-bottom: 10px;
}
.cms-page .cms-page-content {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .cms-page .cms-page-content {
    margin-top: 30px;
  }
}
.cms-page a {
  text-decoration: underline;
}
.cms-page p, .cms-page ul li, .cms-page ol li, .cms-page span {
  font-family: Lato, Arial, Helvetica, san-serif !important;
}
.cms-page ul, .cms-page ol {
  margin-bottom: 20px;
}
.cms-page table, .cms-page caption, .cms-page tbody, .cms-page tfoot, .cms-page thead, .cms-page tr, .cms-page th, .cms-page td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.cms-page table {
  border-collapse: collapse;
  border-spacing: 0;
}
.cms-page .cms-page-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 160px;
}
@media (min-width: 768px) {
  .cms-page .cms-page-hero {
    height: 230px;
  }
}
@media (min-width: 992px) {
  .cms-page .cms-page-hero {
    height: 300px;
  }
}
@media (min-width: 1200px) {
  .cms-page .cms-page-hero {
    height: 400px;
  }
}

.a-lot-of-factors-go {
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 30px;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Landing page styles
 * 
 */
.landing-page-image {
  height: 220px;
}
@media (min-width: 768px) {
  .landing-page-image {
    height: 360px;
  }
}
@media (min-width: 992px) {
  .landing-page-image {
    height: 460px;
  }
}
@media (min-width: 1200px) {
  .landing-page-image {
    height: 520px;
  }
}

.go-next-box {
  position: relative;
  height: 200px;
}
@media (min-width: 768px) {
  .go-next-box {
    height: 217px;
  }
}
@media (min-width: 992px) {
  .go-next-box {
    height: 306px;
  }
}

.go-next-block {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: background-color 0.5s ease-in;
  background-color: rgba(0, 0, 0, 0.1);
}
.go-next-block:focus {
  text-decoration: none;
}
@media (min-width: 768px) {
  .go-next-block:hover {
    color: #000;
    background-color: rgba(255, 255, 255, 0.75);
    transition: background-color 0.25s ease-in;
    text-decoration: none;
    text-shadow: none;
  }
  .go-next-block:hover span {
    color: black;
  }
  .go-next-block:hover span:after {
    background-color: #000;
    box-shadow: none;
  }
}
.go-next-block span {
  color: #fff;
  transition: background-color 0.5s ease-in;
  margin-top: 5px;
}
.go-next-block span:after {
  content: " ";
  display: block;
  width: 114px;
  height: 3px;
  top: 20px;
  position: relative;
  background-color: #fff;
  margin: auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.8);
}
@media (min-width: 768px) {
  .go-next-block span {
    margin: auto;
  }
}
@media (max-width: 767.98px) {
  .go-next-block {
    color: #000;
    background-color: rgba(255, 255, 255, 0.75);
    transition: background-color 0.25s ease-in;
    text-decoration: none;
    text-shadow: none;
  }
  .go-next-block span {
    color: #000;
  }
  .go-next-block span:after {
    background-color: #000;
    box-shadow: none;
  }
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Login form page styles
 * 
 */
#web_user_account-page .bg-image-cover {
  height: clamp(250px, 40vw, 80vh);
}
@media (min-width: 992px) {
  #web_user_account-page .bg-image-cover {
    height: clamp(300px, 60vh, 450px);
  }
}
#web_user_account-page .reinhart-web-account-signup-box {
  display: block;
  position: relative;
  width: 100%;
  margin: 30px 0px 0px 0px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.03), 0 2px 7px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  transition: none;
}
@media (min-width: 576px) {
  #web_user_account-page .reinhart-web-account-signup-box {
    position: absolute;
    bottom: 0;
    margin: 0;
  }
}
#web_user_account-page .reinhart-web-account-signup-box h5 {
  letter-spacing: 0.8px;
  line-height: 24px;
}
@media (min-width: 768px) {
  #web_user_account-page .info-box {
    min-height: 200px;
  }
}
@media (min-width: 992px) {
  #web_user_account-page .info-box {
    min-height: 180px;
  }
}

@media (max-width: 575.98px) {
  #createAccountFormModal .modal-dialog,
  #vowModal .modal-dialog {
    margin: 1rem;
  }
}
#createAccountFormModal .modal-content,
#vowModal .modal-content {
  margin-top: 10vh;
}
#createAccountFormModal .nav-tabs .nav-link,
#vowModal .nav-tabs .nav-link {
  color: #fff;
  border: none;
  margin: 0;
  border-radius: 0px;
}
@media (max-width: 575.98px) {
  #createAccountFormModal .nav-tabs .nav-link,
  #vowModal .nav-tabs .nav-link {
    width: 100%;
  }
}
#createAccountFormModal .nav-tabs .nav-link:hover,
#vowModal .nav-tabs .nav-link:hover {
  border: none;
  background-color: #919091;
  margin: 0;
}
#createAccountFormModal .nav-tabs .nav-link.active,
#vowModal .nav-tabs .nav-link.active {
  border: none;
  margin: 0;
  background-color: #fff;
  color: #404041;
}
@media (max-width: 575.98px) {
  #createAccountFormModal .nav.nav-tabs,
  #vowModal .nav.nav-tabs {
    padding: 15px;
  }
}
#createAccountFormModal .nav.nav-tabs > li,
#vowModal .nav.nav-tabs > li {
  margin: 0;
}
@media (max-width: 575.98px) {
  #createAccountFormModal .nav.nav-tabs > li,
  #vowModal .nav.nav-tabs > li {
    display: block;
    width: 100% !important;
    padding: 0px;
    border: 1px solid #fff;
  }
  #createAccountFormModal .nav.nav-tabs > li:first-child,
  #vowModal .nav.nav-tabs > li:first-child {
    margin-bottom: 15px;
  }
}

.modal-header > .rr-modal-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  padding: 0;
  background: #000;
  color: #fff;
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
}
.modal-header > .rr-modal-close i:before {
  vertical-align: 0.1em;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Mobile page styles
 * 
 */
.mobile-app-page .hero-img {
  height: 416px;
}
.mobile-app-page .hero-img .absolute-md {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
.mobile-app-page .hero-img .mobile-slogan {
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}
@media (max-width: 991.98px) {
  .mobile-app-page .hero-img .mobile-slogan {
    font-size: 36px;
  }
}
.mobile-app-page .phone-number-validation input {
  height: 50px;
  width: 400px;
  padding-left: 10px;
  padding-bottom: 2px;
}
.mobile-app-page .phone-number-validation button {
  border: none;
}
.mobile-app-page .phone-img {
  bottom: 0;
  width: 100%;
}
.mobile-app-page .phone-img img {
  margin-left: 55px;
}
.mobile-app-page .mobile-wells {
  padding-top: 40px;
}
.mobile-app-page .mobile-wells img {
  border: 1px solid #ced4da;
}
.mobile-app-page .mobile-well-desc {
  margin: auto;
}
.mobile-app-page .ipad-img {
  top: -35px;
}
.mobile-app-page #mobile-apps-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5;
}
.mobile-app-page .app-store-icon {
  width: 130px;
}
@media (min-width: 992px) {
  .mobile-app-page .app-store-icon {
    width: 160px;
  }
}
.mobile-app-page #company-footer {
  padding-bottom: 60px;
}
@media (min-width: 768px) {
  .mobile-app-page #company-footer {
    padding-bottom: 75px;
  }
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Agent details/bio page styles
 * 
 */
@media (min-width: 768px) {
  #realtor-bio-page .agent-info {
    display: flex;
  }
}
#realtor-bio-page .agent-image-wrap {
  width: 270px;
  height: 100%;
  max-width: 100%;
  aspect-ratio: 3/4;
  overflow: hidden;
  margin: 0 auto;
}
@media (min-width: 768px) {
  #realtor-bio-page .agent-image-wrap {
    flex: 0 0 210px;
    width: 100%;
    margin-left: 0;
    margin-right: 20px;
  }
}
@media (min-width: 992px) {
  #realtor-bio-page .agent-image-wrap {
    flex: 0 0 255px;
  }
}
@media (min-width: 1200px) {
  #realtor-bio-page .agent-image-wrap {
    flex: 0 0 270px;
  }
}
@supports not (aspect-ratio: 3/4) {
  #realtor-bio-page .agent-image-wrap {
    height: min(120vw, 360px);
  }
}
#realtor-bio-page .agent-image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
#realtor-bio-page .preferred-realtor {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  height: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  #realtor-bio-page .agent-details-wrap {
    flex-grow: 1;
  }
}
@media (min-width: 768px) {
  #realtor-bio-page .agent-contact-actions {
    display: flex;
  }
}
@media (min-width: 768px) {
  #realtor-bio-page .agent-contact-info {
    flex-grow: 1;
    margin-right: 15px;
  }
}
@media (min-width: 768px) {
  #realtor-bio-page .agent-action-buttons {
    flex: 0 0 210px;
  }
}
@media (min-width: 992px) {
  #realtor-bio-page .agent-action-buttons {
    flex: 0 0 290px;
  }
}
@media (min-width: 1200px) {
  #realtor-bio-page .agent-action-buttons {
    flex: 0 0 350px;
  }
}
#realtor-bio-page .phone-label {
  min-width: 68px;
}
#realtor-bio-page .social-icon-list li {
  margin-left: 0;
  padding: 0;
}
#realtor-bio-page .social-icon-list li .social-links [class^=bi],
#realtor-bio-page .social-icon-list li .social-links [class*=bi-] {
  color: #919091;
  background-color: transparent !important;
  display: block;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
}
#realtor-bio-page .social-icon-list li .social-links .svg-icon {
  color: #919091;
  background-color: transparent;
  display: inline-block;
  font-size: 20px;
  text-decoration: none;
  text-align: center;
  vertical-align: text-bottom;
}
#realtor-bio-page .social-icon-list li a.social-links:hover {
  text-decoration: none;
}
#realtor-bio-page .social-icon-list li a.social-links:hover [class^=bi],
#realtor-bio-page .social-icon-list li a.social-links:hover [class*=" bi-"],
#realtor-bio-page .social-icon-list li a.social-links:hover .svg-icon {
  color: #404041;
}

#realtor-bio-page .quoteWrapper,
.rr-agent-testimonials-container .quoteWrapper {
  position: relative;
}
#realtor-bio-page .quoteWrapper .quoteInner:before,
.rr-agent-testimonials-container .quoteWrapper .quoteInner:before {
  content: " ";
  background-image: url(/img/icons/quotes.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 42px;
  height: 32px;
  display: block;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  #realtor-bio-page .quoteWrapper .quoteInner:before,
  .rr-agent-testimonials-container .quoteWrapper .quoteInner:before {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  #realtor-bio-page .quoteWrapper .quoteInner:before,
  .rr-agent-testimonials-container .quoteWrapper .quoteInner:before {
    position: absolute;
    margin-left: -72px;
    margin-top: 6px;
  }
}
#realtor-bio-page .quoteWrapper > p,
.rr-agent-testimonials-container .quoteWrapper > p {
  margin-top: 30px;
}
@media (min-width: 768px) {
  #realtor-bio-page .quoteWrapper > p,
  .rr-agent-testimonials-container .quoteWrapper > p {
    margin-top: 0;
  }
}
#realtor-bio-page .quoteWrapper .testimonial-name,
.rr-agent-testimonials-container .quoteWrapper .testimonial-name {
  text-align: center;
}
@media (min-width: 768px) {
  #realtor-bio-page .quoteWrapper .testimonial-name,
  .rr-agent-testimonials-container .quoteWrapper .testimonial-name {
    text-align: left;
  }
}
#realtor-bio-page .quoteWrapper .testimonial-name cite,
.rr-agent-testimonials-container .quoteWrapper .testimonial-name cite {
  font-style: normal;
}
#realtor-bio-page .quoteWrapper .testimonial-name .date,
.rr-agent-testimonials-container .quoteWrapper .testimonial-name .date {
  margin-top: -5px;
}
#realtor-bio-page .quoteWrapper .testimonial-name:before,
.rr-agent-testimonials-container .quoteWrapper .testimonial-name:before {
  content: " ";
  width: 30px;
  height: 20px;
  border-top: 3px solid #000;
  display: block;
  margin: 0 auto;
}
@media (min-width: 768px) {
  #realtor-bio-page .quoteWrapper .testimonial-name:before,
  .rr-agent-testimonials-container .quoteWrapper .testimonial-name:before {
    float: left;
    margin: 11px 10px 0 0;
    height: 30px;
  }
}

.mobileTrigger {
  display: block;
  background-color: #919091;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 11px;
  margin-bottom: 11px;
  font-size: 16px;
  margin: 0;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-decoration: none;
  color: #fff;
  padding: 9px 15px;
  font-size: 15px;
  margin-bottom: 2px !important;
}
.mobileTrigger a {
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}
.mobileTrigger a i {
  transition: transform 0.2s linear;
}
.mobileTrigger a.collapsed i {
  transform: rotate(-180deg);
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Community details styles
 * 
 */
.show-bio .more {
  display: inline-block;
}
.show-bio .less {
  display: none;
}
.show-bio i {
  transition: transform 0.2s linear;
}
.show-bio.active .more {
  display: none;
}
.show-bio.active .less {
  display: inline;
}
.show-bio.active i {
  transform: rotate(-180deg);
}

#communityDetailsAccordion .community-listings-container .rr-prop-compare-container {
  display: none;
  padding: 0;
}
@media (min-width: 992px) {
  #communityDetailsAccordion .community-listings-container .rr-prop-compare-container {
    display: block;
  }
}
#communityDetailsAccordion .community-listings-container .rr-prop-compare-container button {
  border: none;
  font-weight: 400;
}
#communityDetailsAccordion .community-listings-container .nav.nav-tabs {
  background-color: transparent;
}
#communityDetailsAccordion .community-listings-container .nav.nav-tabs > li {
  padding-top: 0;
}
#communityDetailsAccordion .community-listings-container .nav-link {
  color: #000;
  border: none;
  font-weight: 400;
}
#communityDetailsAccordion .community-listings-container .nav-link .radio-checked {
  display: none;
}
#communityDetailsAccordion .community-listings-container .nav-link .svg-icon {
  margin-bottom: 4px;
}
#communityDetailsAccordion .community-listings-container .nav-link.active {
  border: none;
}
#communityDetailsAccordion .community-listings-container .nav-link.active .radio-checked {
  display: inline-block;
}
#communityDetailsAccordion .community-listings-container .nav-link.active .radio-unchecked {
  display: none;
}
#communityDetailsAccordion button {
  font-size: inherit;
  color: inherit;
  text-transform: inherit;
  text-decoration: none;
  font-weight: 300;
  padding: 0;
}
#communityDetailsAccordion button i {
  font-size: 30px;
  transition: transform 0.2s linear;
}
@media (max-width: 991.98px) {
  #communityDetailsAccordion button i {
    font-size: 24px;
  }
}
#communityDetailsAccordion button:not(.collapsed) i {
  transform: rotate(-180deg);
}
#communityDetailsAccordion button:hover, #communityDetailsAccordion button:focus, #communityDetailsAccordion button:active {
  color: #2cae9c;
}
#communityDetailsAccordion button:focus {
  box-shadow: none;
}

@media (min-width: 768px) {
  #community-page .nav.nav-tabs > li, #community-page .nav.nav-tabs.nav-justified > li {
    margin-bottom: 0;
    margin-top: 0;
  }
}
#community-page .community-hero {
  height: 40vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  top: 0;
  margin-top: 80px;
}
@media (min-width: 768px) {
  #community-page .community-hero {
    height: 50vh;
  }
}
@media (min-width: 992px) {
  #community-page .community-hero {
    height: 70vh;
    margin-top: 0px;
  }
}
#community-page .bio-wrap {
  max-height: 400px;
  overflow: hidden;
  position: relative;
  -webkit-transition: max-height 0.25s ease;
  -moz-transition: max-height 0.25s ease;
  -ms-transition: max-height 0.25s ease;
  -o-transition: max-height 0.25s ease;
  transition: max-height 0.25s ease;
}
#community-page .bio-wrap .btn {
  min-height: 50px;
  height: auto;
}
#community-page .bio-wrap:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
}
#community-page .bio-wrap.active {
  max-height: 3000px;
}
#community-page .bio-wrap.active:after {
  content: none;
}
#community-page #community-map {
  height: 300px;
}
@media (min-width: 768px) {
  #community-page #community-map {
    height: 400px;
  }
}
@media (min-width: 992px) {
  #community-page #community-map {
    height: 600px;
  }
}
@media (min-width: 1200px) {
  #community-page #community-map {
    height: 700px;
  }
}
#community-page #community-list {
  border: 1px solid #f5f5f5;
  border-left: none;
  border-right: none;
  overflow: auto;
  height: 450px;
  -webkit-box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.5);
  box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.5);
}
@media (min-width: 992px) {
  #community-page #community-list {
    height: 600px;
  }
}
@media (min-width: 1200px) {
  #community-page #community-list {
    height: 700px;
  }
}
#community-page #community-list li.active, #community-page #community-list li:hover, #community-page #community-list li:active {
  background-color: #f5f5f5;
}
#community-page #community-list li.active a, #community-page #community-list li:hover a, #community-page #community-list li:active a {
  color: #279c8c;
}
#community-page #community-list .community-image img {
  object-fit: cover;
  width: 217px;
  height: 135px;
  overflow: hidden;
}
#community-page #community-list .community-image .community-number {
  font-weight: 700;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  z-index: 1;
}

.community-search-form select.rr-placeholder {
  color: #919091;
}
.community-search-form select option {
  color: #000;
}

#poi-map-element .poi-map-div {
  height: 500px;
}

#poi-map-element .ui-autocomplete-input {
  font-weight: 400;
}

#poi-map-element .poi-map-form .svg-icon {
  position: absolute;
  right: 0;
  top: 5px;
  fill: #2e2e2e;
  font-size: 24px;
  transform: rotate(-90deg);
}

#poi-map-element .poi-map-form .poi-form-element {
  margin-top: 18px;
}

#poi-map-element .poi-map-form .poi-map-autocomplete-toggle {
  border: 0;
  right: 0;
  top: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: transparent;
}

#poi-map-element .poi-map-results-wrapper .alert-info {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  color: #2e2e2e;
  padding: 20px;
  border-radius: 0;
}

#poi-map-element .poi-map-results-wrapper .alert-danger {
  z-index: 100;
}

#poi-map-element .poi-map-results-wrapper .poi-map-results h3 {
  margin: 20px 0 25px;
  font-size: 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.005em;
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul {
  height: 510px;
  overflow-y: auto;
  padding: 0;
  list-style: none;
  margin-left: 0;
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item {
  padding: 10px 0;
  margin-left: 0;
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a {
  color: #2e2e2e;
  font-weight: 400;
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a:hover, #poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a:active, #poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a:focus {
  color: #a41832;
  text-decoration: none;
}

#poi-map-element .poi-map-results-wrapper .poi-map-results ul .poi-item a span {
  color: #4a525d;
  padding-right: 20px;
  min-width: 20px;
  display: inline-block;
  font-weight: 600;
}

#poi-map-element .placeInfoBubble {
  width: 250px;
}

#poi-map-element .placeInfoBubble span {
  display: block;
}

#poi-map-element .placeInfoBubble .placeName {
  font-weight: 700;
}

#poi-map-element .ui-helper-hidden-accessible {
  display: none;
}

#poi-map-element .ui-autocomplete {
  z-index: 105;
  position: absolute;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0;
  border: 1px solid #c4c4c4;
  -webkit-box-shadow: 5px 5px 10px 0 #777;
  box-shadow: 5px 5px 10px 0 #777;
}

#poi-map-element .ui-autocomplete .ui-menu-item {
  margin: 0;
  width: 100%;
  display: block;
  padding: 0.25em 0.2em;
  background: #fff;
  cursor: pointer;
  text-decoration: none;
}

#poi-map-element .ui-autocomplete .ui-menu-item.ui-state-hover, #poi-map-element .ui-autocomplete .ui-menu-item:hover {
  background: #eee;
}

#poi-map-element .ui-autocomplete .ui-menu-item a {
  display: block;
  padding-left: 15px;
}

#poi-map-element .ui-autocomplete .ui-menu-item a:hover {
  text-decoration: none;
}

.stat-trend-wrapper {
  height: 160px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.03), 0 2px 7px 0 rgba(0, 0, 0, 0.03);
  text-align: center;
  color: #2e2e2e;
  padding: 25px;
}

@media screen and (max-width: 767px) {
  .stat-trend-wrapper {
    margin-bottom: 15px;
  }
}
@media screen and (min-width: 768px) {
  .stat-trend-wrapper {
    padding: 15px 10px;
  }
}
@media screen and (min-width: 992px) {
  .stat-trend-wrapper {
    padding: 25px 15px;
  }
}
@media screen and (min-width: 1200px) {
  .stat-trend-wrapper {
    padding: 25px;
    margin: 0 15px;
  }
}
.stat-trend-wrapper .stat-label {
  margin-bottom: 5px;
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 768px) {
  .stat-trend-wrapper .stat-label {
    font-size: 13px;
    line-height: 20px;
  }
}
@media screen and (min-width: 992px) {
  .stat-trend-wrapper .stat-label {
    font-size: 16px;
    line-height: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .stat-trend-wrapper .stat-label {
    font-size: 16px;
    line-height: 20px;
  }
}
.stat-trend-wrapper .stat-value {
  margin-bottom: 5px;
  display: block;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

@media screen and (min-width: 768px) {
  .stat-trend-wrapper .stat-value {
    font-size: 27px;
    line-height: 36px;
  }
}
@media screen and (min-width: 992px) {
  .stat-trend-wrapper .stat-value {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (min-width: 1200px) {
  .stat-trend-wrapper .stat-value {
    font-size: 30px;
    line-height: 40px;
  }
}
.stat-trend-wrapper .stat-last-month {
  color: #8c8c8c;
}

#community-page .find_community button {
  width: 66px;
}

@media screen and (max-width: 767px) {
  #community-page #community-bio-tabs {
    padding: 10px 0;
  }
  #community-page #community-bio-tabs li {
    padding: 0;
  }
}
.resultsPropertyBubble {
  min-height: 30px;
}

a.maponics-trigger:hover, a.maponics-trigger:focus {
  color: #2e2e2e;
  text-decoration: none;
}

#communityDataDropdown iframe {
  margin-top: 50px;
  width: 100%;
  border: 0;
  min-height: 350px;
}

#community-map-all {
  height: 300px;
}
@media (min-width: 768px) {
  #community-map-all {
    height: 400px;
  }
}
@media (min-width: 1200px) {
  #community-map-all {
    height: 700px;
  }
}

#community-list {
  border: 1px solid #f5f5f5;
  border-left: none;
  border-right: none;
  overflow: auto;
  height: 450px;
  -webkit-box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.5);
  box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.5);
}
@media (min-width: 992px) {
  #community-list {
    height: 600px;
  }
}
@media (min-width: 1200px) {
  #community-list {
    height: 700px;
  }
}
#community-list li.active, #community-list li:hover, #community-list li:active {
  background-color: #f5f5f5;
}
#community-list li.active a, #community-list li:hover a, #community-list li:active a {
  color: #279c8c;
}
#community-list .community-image img {
  object-fit: cover;
  width: 217px;
  height: 135px;
  overflow: hidden;
}
#community-list .community-image .community-number {
  font-weight: 700;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  z-index: 1;
}
#community-list .community-info a:hover {
  text-decoration: none;
}

/*!
 * Reinhart Realtor Website Theme
 *
 * Page specific styles for https://www.reinhartrealtors.com/buyers-guide/
 * Page specific styles for https://www.reinhartrealtors.com/sellers-guide/
 */
.buyer-seller-page .hero {
  height: 560px;
}
@media (max-width: 767.98px) {
  .buyer-seller-page .hero {
    height: 440px;
  }
}
.buyer-seller-page .search-wrap {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 1;
}
.buyer-seller-page .search-wrap h1, .buyer-seller-page .search-wrap h2 {
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
}
.buyer-seller-page .search-wrap .bg-white-75 {
  box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.03), 0 2px 7px 0 rgba(0, 0, 0, 0.03);
  background-color: rgba(255, 255, 255, 0.85);
}
.buyer-seller-page .search-wrap form input {
  height: 50px;
  background: 0 0;
  font-size: 16px;
  padding: 12px 0 0 0;
}
.buyer-seller-page .search-wrap form input[placeholder] {
  text-overflow: ellipsis;
}
.buyer-seller-page hr {
  border-color: #dedede;
}
.buyer-seller-page .tabClass li button {
  background: #c8c7c8;
  color: #404041;
  letter-spacing: 0.8px;
  padding: 30px 4px 22px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  border: 0px solid #000;
  width: 100%;
  height: 100%;
}
@media (max-width: 767.98px) {
  .buyer-seller-page .tabClass li button {
    padding: 11px;
    border-radius: 30px 0 0 30px;
  }
}
@media (min-width: 768px) {
  .buyer-seller-page .tabClass li button {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .buyer-seller-page .tabClass li button {
    font-size: 16px;
  }
}
.buyer-seller-page .tabClass li button span {
  transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out;
  font-weight: 400;
  font-size: 30px;
  color: #000;
  position: absolute;
  left: 50%;
  top: -24px;
  line-height: 40px;
  background-color: #fff;
  transform: translateX(-50%);
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 2px solid #dedede;
}
@media (max-width: 767.98px) {
  .buyer-seller-page .tabClass li button span {
    left: 0;
    top: 0;
    transform: none;
  }
}
.buyer-seller-page .tabClass li button.active, .buyer-seller-page .tabClass li button:hover {
  background-color: #404041;
  color: #fff;
}
.buyer-seller-page .tabClass li button.active span, .buyer-seller-page .tabClass li button:hover span {
  border-color: #404041;
  color: #404041;
}
@media (min-width: 768px) {
  .buyer-seller-page .tabClass li button.active:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 16px;
    height: 16px;
    background-color: #404041;
  }
}
.buyer-seller-page .buyer-seller-helper-container {
  margin-top: 20px;
}
.buyer-seller-page .buyer-seller-helper-container button.btn {
  background-color: #c8c7c8;
  border-color: #c8c7c8;
  color: #000;
  font-weight: normal;
  font-size: 14px;
}
@media (min-width: 768px) {
  .buyer-seller-page .buyer-seller-helper-container button.btn {
    font-size: 15px;
  }
}
@media (min-width: 992px) {
  .buyer-seller-page .buyer-seller-helper-container button.btn {
    font-size: 16px;
  }
}
.buyer-seller-page .buyer-seller-helper-container button.btn:hover {
  background-color: #404041;
  border-color: #404041;
  color: #fff;
}
.buyer-seller-page .guide-step-btn {
  min-width: 180px;
}
@media (min-width: 576px) {
  .buyer-seller-page .guide-step-btn {
    min-width: 150px;
  }
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Property search form and results styles
 * 
 */
body.property-search-page {
  margin-bottom: 50px;
}

.property-search .nav {
  padding-bottom: 15px;
}
@media (min-width: 768px) {
  .property-search .nav {
    padding-bottom: 0px;
  }
}
.property-search .nav.nav-tabs > li.nav-item {
  margin-bottom: 0;
}
.property-search input::placeholder {
  color: #919091;
}
.property-search select.rr-placeholder {
  color: #919091;
}
.property-search select option {
  color: #000;
}

.rr-collapse-heading {
  cursor: pointer;
}
.rr-collapse-heading i {
  display: inline-block;
  font-size: 0.8125em;
  color: #919091;
  transition: transform 0.2s linear;
}
.rr-collapse-heading.collapsed i {
  transform: rotate(-180deg);
}

#advanced-search-width-wrapper {
  z-index: 1;
}

.formCounter {
  position: fixed;
  top: 0;
  bottom: 0;
  height: auto;
  width: auto;
  z-index: 1500;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  right: 0;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.06), 0 9px 18px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #dedede;
}
.formCounter .formCounterCountValue {
  font-size: 24px;
}
@media (min-width: 768px) {
  .formCounter .formCounterCountValue {
    margin-right: 20px;
  }
}
.formCounter .formCounterText {
  font-size: 24px;
  margin-right: 40px;
}
@media (min-width: 992px) {
  .formCounter .formCounterText {
    font-size: 16px;
    margin-right: 0px;
  }
}
.formCounter button {
  height: auto;
}
@media (max-width: 991.98px) {
  .formCounter {
    top: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .formCounter .formCounterCountValue,
  .formCounter .formCounterSubmit {
    flex: 1 0 50%;
  }
  .formCounter .formCounterText {
    display: none;
  }
}
@media (min-width: 992px) {
  .formCounter {
    position: sticky;
    top: 15px;
    bottom: unset;
    right: unset;
    margin: 0 -15px 15px -20px;
    transition: top 0.3s ease;
  }
  .formCounter .formCounterCountValue {
    font-size: 30px;
    color: #000;
    min-height: 36px;
    margin-right: 0px;
  }
  .formCounter button {
    padding: 12px 10px;
  }
  .formCounter .formCounterSubmit {
    margin-top: 12px;
  }
}
@media (min-width: 1200px) {
  .formCounter {
    margin: 0 0 15px -5px;
  }
}

.to-spacer-gutter {
  padding-left: 0;
  padding-right: 0;
  width: 30px;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
  line-height: 22px;
}

.rr-prop-compare-container {
  padding: 15px 15px 12px;
}
.rr-prop-compare-container.show > button {
  position: relative;
}
.rr-prop-compare-container.show > button:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-left: 2px solid #919091;
  border-top: 2px solid #919091;
  z-index: 11;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  bottom: -18px;
}
.rr-prop-compare-container button {
  padding: 0;
  outline: 0;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.rr-prop-compare-container a {
  display: inline-block;
  color: #000;
  text-decoration: none;
}
.rr-prop-compare-container .rr-prop-compare-indicator {
  color: #2cae9c;
}
.rr-prop-compare-container i {
  font-size: 24px;
  line-height: 1;
}
.rr-prop-compare-container .dropdown-menu {
  border-top: 2px solid #919091;
  z-index: 10;
  box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.3);
}
.rr-prop-compare-container .dropdown-item {
  padding: 3px 20px;
}
.rr-prop-compare-container .dropdown-item img {
  vertical-align: bottom;
}

.rr-search-filters-container button {
  width: 100%;
  background-color: #919091;
  color: #fff;
  outline: 0;
  padding: 12px 20px;
}
.rr-search-filters-container button:hover, .rr-search-filters-container button:focus, .rr-search-filters-container button:active {
  background-color: #919091;
  border-color: #919091;
  color: #fff;
  box-shadow: none;
}
.rr-search-filters-container button:not(.collapsed):focus {
  background-color: #404041;
}
.rr-search-filters-container button:not(.collapsed) i {
  transform: rotate(180deg);
}
.rr-search-filters-container button i {
  transition: transform 0.2s linear;
}
@media (min-width: 768px) {
  .rr-search-filters-container button {
    width: auto;
  }
}

.search-results .sort_order .select-wrapper {
  text-align: center;
}
.search-results .sort_order select:disabled {
  color: #919091;
}
@media (max-width: 767.98px) {
  .search-results .sort_order select {
    display: inline-block;
    width: auto;
  }
}
.search-results .page_nav .prev.disabled,
.search-results .page_nav .next.disabled {
  color: #c8c7c8;
}
.search-results .page_nav select {
  min-width: 80px;
  text-align: center;
  appearance: none;
}
.search-results .refine-container {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.search-results .ui-autocomplete {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.results-grid {
  display: grid;
  gap: 60px 30px;
  grid-template-columns: 1fr;
  justify-items: center;
}
@media (min-width: 768px) {
  .results-grid {
    grid-template-columns: repeat(2, 330px);
  }
}
@media (min-width: 992px) {
  .results-grid {
    grid-template-columns: repeat(3, 290px);
  }
}
@media (min-width: 1200px) {
  .results-grid {
    grid-template-columns: repeat(3, 350px);
  }
}

.property-container,
.map-list-property-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 390px;
}
.property-container a,
.map-list-property-container a {
  transition: color 0.15s ease-in-out;
}
.property-container .openhouse-banner,
.property-container .sold-banner,
.map-list-property-container .openhouse-banner,
.map-list-property-container .sold-banner {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 4px 10px;
  z-index: 10;
  letter-spacing: 0.8px;
  line-height: 19px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.property-container .openhouse-banner,
.map-list-property-container .openhouse-banner {
  background: #2cae9c;
}
.property-container .sold-banner,
.map-list-property-container .sold-banner {
  background: #000;
}
.property-container .property-img-container,
.map-list-property-container .property-img-container {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  overflow: hidden;
}
@supports not (aspect-ratio: 3/2) {
  .property-container .property-img-container,
  .map-list-property-container .property-img-container {
    height: min(60vw, 260px);
  }
  @media (min-width: 768px) {
    .property-container .property-img-container,
    .map-list-property-container .property-img-container {
      height: 220px;
    }
  }
  @media (min-width: 992px) {
    .property-container .property-img-container,
    .map-list-property-container .property-img-container {
      height: 200px;
    }
  }
  @media (min-width: 1200px) {
    .property-container .property-img-container,
    .map-list-property-container .property-img-container {
      height: 240px;
    }
  }
}
.property-container .property-img-container img,
.map-list-property-container .property-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.property-container .property-address,
.map-list-property-container .property-address {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
}
.property-container .property-address .prop-street,
.property-container .property-address .prop-city,
.map-list-property-container .property-address .prop-street,
.map-list-property-container .property-address .prop-city {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .property-container {
    max-width: 330px;
  }
}
@media (min-width: 992px) {
  .property-container {
    max-width: 290px;
  }
}
@media (min-width: 1200px) {
  .property-container {
    max-width: 350px;
  }
}
.property-container:hover .property-address a {
  color: #279c8c;
}
.property-container .property-status-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 12px;
}
.property-container .property-status-container .prop-status {
  text-transform: uppercase;
  margin-top: 2px;
  line-height: 2;
  letter-spacing: 0.8px;
}
.property-container .property-address {
  flex-grow: 1;
}
.property-container .property-address a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.property-container .property-details {
  margin-top: 15px;
  color: #404041;
  font-size: 18px;
}
.property-container .property-details .prop-params {
  min-height: 24px;
}
.property-container .property-details .prop-params > span {
  margin-right: 5px;
}
.property-container .property-actions {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #919091;
}
.property-container .property-bin,
.property-container .favorite-property {
  position: relative;
  text-decoration: none;
  padding-left: 30px;
  color: #919091;
}
.property-container .property-bin:hover,
.property-container .favorite-property:hover {
  color: #279c8c;
}
.property-container .property-bin .inactive,
.property-container .favorite-property .inactive {
  opacity: 1;
}
.property-container .property-bin .active,
.property-container .favorite-property .active {
  opacity: 0;
}
.property-container .property-bin svg,
.property-container .property-bin i,
.property-container .favorite-property svg,
.property-container .favorite-property i {
  position: absolute;
  top: 0;
  left: 0;
}
.property-container .property-bin svg.active,
.property-container .property-bin i.active,
.property-container .favorite-property svg.active,
.property-container .favorite-property i.active {
  color: #279c8c;
}
.property-container .property-bin svg,
.property-container .favorite-property svg {
  font-size: 24px;
  vertical-align: bottom;
}
.property-container .property-bin i,
.property-container .favorite-property i {
  font-size: 20px;
  line-height: 1.3;
}
.property-container .property-bin {
  display: none;
}
@media (min-width: 768px) {
  .property-container .property-bin {
    display: inline-block;
    margin-right: 30px;
  }
}
.property-container .property-bin.property-bin-remove {
  color: #279c8c;
}
.property-container .property-bin.property-bin-remove .inactive {
  opacity: 0;
}
.property-container .property-bin.property-bin-remove .active {
  opacity: 1;
}
.property-container .favorite-property {
  display: inline-block;
}
.property-container .favorite-property.remove-favorite-property {
  color: #279c8c;
}
.property-container .favorite-property.remove-favorite-property .inactive {
  opacity: 0;
}
.property-container .favorite-property.remove-favorite-property .active {
  opacity: 1;
}
.property-container .map-property {
  display: inline-block;
  text-decoration: none;
  color: #919091;
  font-size: 16px;
}
.property-container .map-property:hover {
  color: #279c8c;
}
.property-container .map-property i {
  margin-right: 3px;
}

.map-list-property-container {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #919091;
}
.map-list-property-container .property-price-status {
  margin-top: 10px;
  line-height: 1.5;
  letter-spacing: 0.8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.map-list-property-container .property-price-status .prop-price {
  font-size: 16px;
}
.map-list-property-container .property-price-status .prop-status {
  font-size: 14px;
  text-transform: uppercase;
}
.map-list-property-container .property-params {
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.map-list-property-container .property-params > span {
  margin-right: 5px;
}
@media (min-width: 768px) {
  .map-list-property-container .openhouse-banner,
  .map-list-property-container .sold-banner {
    font-size: 12px;
  }
}
.map-list-property-container a.clickthru {
  text-indent: -99em;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
}
.map-list-property-container:hover {
  cursor: pointer;
}
.map-list-property-container:hover .property-address {
  color: #279c8c;
}
@media (min-width: 768px) {
  .map-list-property-container {
    flex-direction: row;
    max-width: unset;
  }
  .map-list-property-container .property-img-container {
    margin-right: 10px;
    max-width: 160px;
    flex: 1 0 160px;
  }
  .map-list-property-container .property-details {
    overflow: hidden;
  }
  .map-list-property-container .property-address {
    margin-top: 0px;
  }
  .map-list-property-container .property-address .prop-street {
    font-size: 16px;
  }
  .map-list-property-container .property-address .prop-city {
    font-size: 14px;
    font-weight: normal;
  }
  .map-list-property-container .property-price-status .prop-price {
    font-size: 14px;
  }
  .map-list-property-container .property-price-status .prop-status {
    font-size: 12px;
  }
  .map-list-property-container .property-params {
    font-size: 14px;
  }
}

#map-search .btn-map-gallery {
  display: inline;
  padding: 8px 12px;
}
#map-search .properties-list-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  #map-search .properties-list-options {
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
  }
}
@media (min-width: 768px) {
  #map-search .properties-list-options {
    justify-content: flex-end;
  }
}
#map-search .properties-list-options .form-control {
  width: auto;
}
#map-search .properties-list-options .page-link:focus {
  box-shadow: none;
}
#map-search #map-tab,
#map-search #map-tab #map {
  height: 560px;
  z-index: 5;
}
@media (min-width: 768px) {
  #map-search #map-tab,
  #map-search #map-tab #map {
    height: 750px;
  }
}
#map-search .properties-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 768px) {
  #map-search .properties-list {
    height: 750px;
    overflow-y: auto;
  }
}
#map-search .properties-list .property .property-header {
  font-size: 0.9rem;
}
#map-search .properties-list .property .property-header img {
  width: 110px;
  height: auto;
}
#map-search .properties-list .property .property-header .property-address {
  color: #279c8c;
  font-size: 1rem;
}
#map-search .properties-list .property .property-details-mobile {
  font-size: 0.9rem;
}
#map-search .properties-list .property .property-footer {
  font-size: 0.9rem;
}
#map-search .properties-list .property .property-footer .logo img {
  height: 40px;
  width: auto;
}
@media (min-width: 768px) {
  #map-search .tab-content > .tab-pane {
    display: block;
  }
}

.property-pin {
  display: block;
  background-color: #000;
  color: #fff;
  font-size: 8.5pt;
  line-height: 8.5pt;
  box-sizing: border-box;
  width: 54px;
  text-align: center;
  padding: 2px 0 3px;
  position: relative;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.property-pin:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  width: 0;
  height: 0;
  border-top: solid 9px #000;
  border-left: solid 6px transparent;
  border-right: solid 6px transparent;
}
.property-pin.active, .property-pin.new {
  background-color: #338000;
}
.property-pin.active:after, .property-pin.new:after {
  border-top: solid 9px #338000;
}
.property-pin.conting {
  background-color: #e6b300;
  color: #000;
}
.property-pin.conting:after {
  border-top: solid 9px #e6b300;
}
.property-pin.sold {
  background-color: #279c8c;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.property-pin.sold:after {
  border-top: solid 9px #279c8c;
}

.listing-map-popup {
  width: 300px;
}
.listing-map-popup .prop-hdr-popup {
  display: flex;
  width: 100%;
  background-color: #404041;
  color: #fff;
}
.listing-map-popup .prop-hdr-popup .prop-address {
  flex-grow: 1;
  width: 100%;
  padding: 0.5rem;
  overflow: hidden;
}
.listing-map-popup .prop-hdr-popup .prop-address .address {
  max-width: 100%;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listing-map-popup .prop-hdr-popup .prop-address .city {
  font-size: 0.75em;
}
.listing-map-popup .prop-hdr-popup .prop-popup-close {
  font-size: 20px;
  padding: 5px 10px;
}
.listing-map-popup .prop-img-popup {
  width: 100%;
  aspect-ratio: 3/2;
  overflow: hidden;
}
@supports not (aspect-ratio: 3/2) {
  .listing-map-popup .prop-img-popup {
    height: 200px;
  }
}
.listing-map-popup .prop-img-popup img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.listing-map-popup .prop-details-popup {
  padding: 0.5rem 0.5rem 0.75rem;
}
.listing-map-popup .prop-details-popup .prop-details-1 {
  width: 100%;
  overflow: hidden;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.listing-map-popup .prop-details-popup .prop-details-1 .price {
  margin-right: 5px;
  font-weight: bold;
}
.listing-map-popup .prop-details-popup .prop-details-1 .status {
  margin-left: 5px;
  font-size: 0.875rem;
}
.listing-map-popup .prop-details-popup .prop-details-2 {
  font-size: 0.875rem;
}
.listing-map-popup .prop-details-popup .prop-details-2 > span {
  margin-right: 0.75rem;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Property details styles
 * 
 */
.property-details-page .banner {
  line-height: 16px;
  padding: 5px;
  background-color: #2cae9c;
  color: #fff;
}
.property-details-page .cta-link {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  padding-left: 30px;
  font-weight: 400;
  text-decoration: none;
  color: #000;
  background-color: transparent;
}
@media (min-width: 768px) {
  .property-details-page .cta-link {
    padding-left: 0;
    padding-top: 25px;
  }
}
@media (min-width: 992px) {
  .property-details-page .cta-link {
    padding-left: 30px;
    padding-top: 0;
  }
}
.property-details-page .cta-link:hover {
  color: #279c8c;
}
.property-details-page .cta-link.active {
  color: #279c8c;
}
.property-details-page .cta-link.active .unchecked {
  display: none;
}
.property-details-page .cta-link.active .checked {
  display: inline-block;
}
.property-details-page .cta-link .checked {
  display: none;
}
.property-details-page .cta-link img {
  position: absolute;
  top: 0;
  left: 0;
  vertical-align: bottom;
}
@media (min-width: 768px) {
  .property-details-page .cta-link img {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) {
  .property-details-page .cta-link img {
    left: 0;
    transform: translateX(0);
  }
}
.property-details-page .action-btn {
  padding: 0;
  outline: 0;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.property-details-page .action-btn:hover, .property-details-page .action-btn:focus {
  color: #279c8c;
}
.property-details-page .action-link {
  color: #000;
  text-decoration: none;
}
.property-details-page .action-link:hover, .property-details-page .action-link:focus {
  color: #279c8c;
}
.property-details-page .action-btn .svg-icon,
.property-details-page .action-link .svg-icon {
  font-size: 24px;
}
.property-details-page button.request-info {
  padding: 8px 11px;
}
@media (max-width: 767.98px) {
  .property-details-page button.request-info {
    width: 100%;
  }
}
.property-details-page .property-open-house {
  border-top: 1px solid #c8c7c8;
  border-bottom: 1px solid #c8c7c8;
  padding-top: 10px;
  padding-bottom: 10px;
}
.property-details-page .btn.property-tooltip,
.property-details-page .btn.property-modal {
  height: unset;
  vertical-align: top;
  margin-left: 2px;
  padding: 0;
  color: #919091;
}
.property-details-page .btn.property-tooltip:focus,
.property-details-page .btn.property-modal:focus {
  box-shadow: none;
}
.property-details-page .map-cta {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 0;
}
.property-details-page .map-cta .inner {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  margin: 0 10px;
}
.property-details-page .map-cta .inner a.btn-sm, .property-details-page .map-cta .inner .btn-group-sm > a.btn {
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}
.property-details-page #property-details-map {
  height: 262px;
}
.property-details-page .details-agent-wrap {
  display: flex;
}
.property-details-page .details-agent-wrap .details-agent-photo {
  width: 100px;
  margin: 5px 20px 0 0;
}
.property-details-page .details-agent-wrap .details-agent-info {
  flex-grow: 1;
}
.property-details-page .details-agent-wrap .details-agent-info a {
  text-decoration: none;
  color: #000;
}
.property-details-page .details-agent-wrap .details-agent-info a:hover {
  color: #279c8c;
}
@media (min-width: 992px) {
  .property-details-page .nav.nav-tabs > li > a {
    padding: 14px 40px;
  }
}

.property-header li .svg-icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24px;
  vertical-align: bottom;
}
@media (min-width: 768px) {
  .property-header li .svg-icon {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) {
  .property-header li .svg-icon {
    left: 0;
    transform: translateX(0);
  }
}

.btn.calculate-payment,
.btn.property-history,
.btn.property-content {
  height: unset;
  padding: 0;
  font-weight: normal;
  letter-spacing: unset;
  text-transform: capitalize;
}
.btn.calculate-payment:focus,
.btn.property-history:focus,
.btn.property-content:focus {
  box-shadow: none;
}
.btn.calculate-payment:not(.collapsed) i,
.btn.property-history:not(.collapsed) i,
.btn.property-content:not(.collapsed) i {
  transform: rotate(-180deg);
}
.btn.calculate-payment i,
.btn.property-history i,
.btn.property-content i {
  font-size: 18px;
  transition: transform 0.2s linear;
}
@media (min-width: 768px) {
  .btn.calculate-payment i,
  .btn.property-history i,
  .btn.property-content i {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .btn.calculate-payment i,
  .btn.property-history i,
  .btn.property-content i {
    font-size: 24px;
  }
}

.btn.calculate-payment,
.btn.property-history {
  margin-bottom: 10px;
  color: #2cae9c;
}
.btn.calculate-payment:hover,
.btn.property-history:hover {
  color: #279c8c;
}

.btn.property-content {
  display: flex;
  align-items: center;
  font-weight: 300;
}

#propertyDetailsCarousel .carousel-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  transition: opacity 0.2s linear;
}
#propertyDetailsCarousel .carousel-loading.no-visibility {
  opacity: 0;
}
#propertyDetailsCarousel .carousel-loading .spinner-border {
  position: relative;
  top: calc(50% - 1em);
}

#propertyHistory table th, #propertyHistory table td {
  white-space: nowrap;
}

#payment-calc-instance label,
#mrtgCalc label,
#calculatedPayment label {
  font-size: 13px;
  line-height: 24px;
}
#payment-calc-instance span.form-control,
#mrtgCalc span.form-control,
#calculatedPayment span.form-control {
  border-bottom-color: transparent;
}

#payment-calc-instance .down-payment-block,
#mrtgCalc .down-payment-block {
  display: flex;
}
#payment-calc-instance .down-payment-block input,
#mrtgCalc .down-payment-block input {
  margin-right: 10px;
}
#payment-calc-instance .down-payment-block select,
#mrtgCalc .down-payment-block select {
  min-width: 125px;
}

#calculatedPayment .pmt-total label,
#calculatedPayment .pmt-total span {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
}

#photoGallery button.close {
  float: unset;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 32px;
  color: #fff;
  opacity: 0;
}
@media (min-width: 576px) {
  #photoGallery .modal-dialog {
    max-width: unset;
    margin: 1.75rem 1rem;
  }
}
@media (min-width: 1200px) {
  #photoGallery .modal-dialog {
    max-width: 1140px;
    margin: 1.75rem auto;
  }
}
#photoGallery .modal-content {
  background-color: #000;
  transition: opacity 0.5s ease-in;
}
#photoGallery .modal-header {
  background-color: #000;
  border: none;
  padding-left: 0px;
}
#photoGallery .modal-header .photo-gallery-title {
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
#photoGallery .modal-header button.close {
  color: #fff;
  font-size: 2em;
  opacity: 1;
}
#photoGallery .modal-body {
  padding: 0;
}
#photoGallery .property-gallery-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
}
#photoGallery .property-gallery-counter {
  color: #fff;
  margin-right: 10px;
  font-size: 18px;
  opacity: 0.8;
}
@media (min-width: 992px) {
  #photoGallery .property-gallery-counter {
    font-size: 22px;
  }
}
#photoGallery button.splide__toggle {
  padding: 0;
  height: unset;
  color: #fff;
}
#photoGallery button.splide__toggle:focus {
  box-shadow: none;
}
#photoGallery button.splide__toggle .svg-icon {
  font-size: 36px;
}
@media (min-width: 992px) {
  #photoGallery button.splide__toggle .svg-icon {
    font-size: 42px;
  }
}

.tooltip.sqft-tooltip.show {
  opacity: 1;
}
.tooltip.sqft-tooltip .tooltip-inner {
  max-width: 300px;
  background-color: #fff;
  color: #000;
  border-top: 2px solid #000;
  box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.3);
}

.prop-inq-modal-addr {
  font-size: 20px;
  margin-bottom: 0;
}

.prop-inq-modal-mls {
  font-size: 16px;
  margin-bottom: 0;
}

#requestMoreInfo .custom-control .custom-control-label.schedule-showing-label {
  color: #404041;
  text-transform: none;
  line-height: 30px;
}
#requestMoreInfo .custom-control .custom-control-label.schedule-showing-label:before {
  top: 0.5rem;
  border: 1px solid #404041;
}
#requestMoreInfo .custom-control .custom-control-label.schedule-showing-label:after {
  top: 0.5rem;
}
#requestMoreInfo .custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #404041;
}
#requestMoreInfo input#showing-date:not([disabled])::placeholder {
  color: #404041;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * My account page styles
 * 
 */
.rr-account-page .nav-tabs .nav-item .nav-link {
  font-size: 14px;
}
.rr-account-page .favorites-addr-link {
  color: #000;
  text-decoration: none;
}
.rr-account-page .favorites-addr-link:hover, .rr-account-page .favorites-addr-link:focus {
  color: #279c8c;
}
.rr-account-page .price-tracker-btn,
.rr-account-page .favorite-btn,
.rr-account-page .recommended-btn,
.rr-account-page .add-note-dropdown {
  padding: 0;
  outline: 0;
  height: unset;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.rr-account-page .price-tracker-btn:hover, .rr-account-page .price-tracker-btn:focus,
.rr-account-page .favorite-btn:hover,
.rr-account-page .favorite-btn:focus,
.rr-account-page .recommended-btn:hover,
.rr-account-page .recommended-btn:focus,
.rr-account-page .add-note-dropdown:hover,
.rr-account-page .add-note-dropdown:focus {
  color: #279c8c;
}
.rr-account-page .price-tracker-btn:focus,
.rr-account-page .favorite-btn:focus,
.rr-account-page .recommended-btn:focus,
.rr-account-page .add-note-dropdown:focus {
  box-shadow: none;
}
.rr-account-page .price-tracker-btn {
  color: #279c8c;
}
.rr-account-page .price-tracker-btn .tracker-text {
  font-weight: normal;
  font-size: 0.875em;
  text-transform: capitalize;
}
.rr-account-page .price-tracker-expiration {
  font-size: 14px;
  margin-bottom: 5px;
}
.rr-account-page .favorite-btn,
.rr-account-page .recommended-btn,
.rr-account-page .add-note-dropdown {
  color: #404041;
  font-weight: normal;
  text-transform: capitalize;
}
.rr-account-page .favorite-notes-container {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .rr-account-page .favorite-notes-container {
    margin-top: 0;
  }
}
.rr-account-page .add-note-dropdown:not(.collapsed) i {
  transform: rotate(-180deg);
}
.rr-account-page .add-note-dropdown i {
  display: inline-block;
  transition: transform 0.2s linear;
}
.rr-account-page .add-note-btn {
  margin-top: 10px;
  height: auto;
  line-height: 1;
  padding: 10px;
  font-size: 0.875rem;
}
.rr-account-page .note-date {
  color: #919091;
  font-size: 0.875em;
}
.rr-account-page .add-saved-search .btn {
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  .rr-account-page .add-saved-search .btn {
    font-size: 1rem;
  }
}
.rr-account-page .email_pass_phone p {
  cursor: not-allowed;
}

.account-overview-link-header h2 {
  color: #2cae9c;
}
.account-overview-link-header h2:hover {
  color: #279c8c;
  text-decoration: underline;
}
.account-overview-link-header:hover {
  text-decoration: none;
}

.account-favorites img {
  width: 320px;
  aspect-ratio: 3/2;
  object-fit: cover;
  max-width: 100%;
}

.saved-searches-table.table .thead-light th {
  border-color: #919091;
}
.saved-searches-table th {
  font-size: 0.75rem;
  text-transform: uppercase;
  white-space: nowrap;
}
.saved-searches-table .search-name {
  width: 100%;
}
@media (min-width: 576px) {
  .saved-searches-table .search-name {
    width: 60vw;
  }
}
@media (min-width: 1200px) {
  .saved-searches-table .search-name {
    width: 100%;
  }
}
.saved-searches-table .search-name button.close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 5;
}
@media (min-width: 768px) {
  .saved-searches-table .search-name .modal-body {
    font-size: 1rem;
  }
}
.saved-searches-table .view-criteria-btn {
  height: unset;
  letter-spacing: unset;
  vertical-align: unset;
  text-transform: capitalize;
  padding: 0;
  font-size: 0.875rem;
}
.saved-searches-table .view-criteria-btn:focus {
  box-shadow: none;
}
.saved-searches-table .created {
  display: none;
}
@media (min-width: 576px) {
  .saved-searches-table .created {
    display: table-cell;
    padding-right: 5px;
    width: 190px;
  }
}
@media (min-width: 1200px) {
  .saved-searches-table .created {
    white-space: nowrap;
  }
}
.saved-searches-table .expires,
.saved-searches-table .email {
  display: none;
}
@media (min-width: 992px) {
  .saved-searches-table .expires,
  .saved-searches-table .email {
    display: table-cell;
  }
}
@media (min-width: 1200px) {
  .saved-searches-table .expires,
  .saved-searches-table .email {
    white-space: nowrap;
  }
}
.saved-searches-table td {
  font-size: 0.875rem;
}
.saved-searches-table .view .btn,
.saved-searches-table .cancel .btn {
  height: unset;
  padding: 0.25rem 0.375rem;
  font-size: 0.75rem;
  line-height: 1.125em;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .saved-searches-table .view .btn,
  .saved-searches-table .cancel .btn {
    font-size: 0.875rem;
  }
}
.saved-searches-table td.view,
.saved-searches-table td.cancel {
  white-space: nowrap;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * Compare properties styles
 * 
 */
.compare-properties {
  overflow: auto;
}

.compare-properties-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 300px;
}

.title-grid, .compare-properties-column {
  display: grid;
  grid-template-rows: 280px repeat(15, 59px) 1fr;
  align-items: stretch;
  gap: 0px;
  height: 100%;
}

.compare-properties-column .compare-cell-width {
  max-width: 300px;
  padding: 15px;
  border-right: 1px solid #c8c7c8;
  border-bottom: 1px solid #c8c7c8;
}
.compare-properties-column .bi-x-lg {
  font-size: 1.25rem;
  color: #2cae9c;
  font-weight: bold;
}
.compare-properties-column .bi-x-lg:hover {
  cursor: pointer;
}

.title-grid .compare-cell-width {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #c4c4c4;
  padding: 15px 15px 18px 15px;
}
.title-grid .compare-cell-width:last-child {
  align-items: flex-start;
  border-bottom: 0px solid #c4c4c4;
}

.compare-photo {
  position: relative;
  width: 270px;
  aspect-ratio: 3/2;
  overflow: hidden;
}
@supports not (aspect-ratio: 3/2) {
  .compare-photo {
    height: 167px;
  }
}
.compare-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.compare-photo .status-banner {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px 2px;
  z-index: 10;
  letter-spacing: 0.8px;
  line-height: 19px;
  color: #fff;
  background: rgba(39, 156, 140, 0.8);
  text-transform: uppercase;
}

.compare-street-addr {
  display: block;
  padding-top: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#preferred-date-text-mask {
  position: absolute;
  top: 1px;
  left: 10px;
  background: #e9ecef;
  width: 114px;
}

/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Content (CMS) page styles
 * 
 */
.rr-agent-contact-address {
  margin: 20px 0px;
}

.rr-agent-contact-phone {
  display: flex;
}
.rr-agent-contact-phone .contact-phone-label {
  flex: 0 0 80px;
}
.rr-agent-contact-phone .contact-phone-number {
  margin-left: 10px;
  flex: 1 0;
}

.rr-agent-contact-email {
  margin-top: 10px;
}

/*!
 * Reinhart Realtors Website Theme
 *
 * New Construction page styles
 * 
 */
.new-construction-container {
  margin-bottom: 30px;
}
.new-construction-container .row {
  justify-content: center;
}
@media (min-width: 768px) {
  .new-construction-container .row {
    justify-content: flex-start;
  }
}
.new-construction-container .row .col {
  flex: 0 0 100%;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .new-construction-container .row .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.new-construction-container .new-construction-community {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (min-width: 768px) {
  .new-construction-container .new-construction-community {
    max-width: 360px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.new-construction-container .new-construction-community a {
  display: inline-block;
  padding: 25px 10px;
  text-decoration: none;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease-in;
}
.new-construction-container .new-construction-community a:hover {
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.new-construction-container .new-construction-community a:active {
  box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.75);
}
.new-construction-container .new-construction-community img {
  width: 100%;
  height: min(300px, 100vw - 50px);
  object-fit: contain;
}
.new-construction-container .new-construction-community h2 {
  margin-top: 10px;
  font-size: 32px;
  font-weight: 400;
}
.new-construction-container .new-construction-community p {
  font-size: 24px;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .standard-property {
    margin: auto;
    margin-bottom: 60px;
    max-width: 362px;
  }
}
.standard-property a {
  -webkit-transition: color 0.15s ease-in-out;
  -moz-transition: color 0.15s ease-in-out;
  -ms-transition: color 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}

.standard-property .property-photo-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.standard-property .prop-photo-nav {
  position: absolute;
  z-index: 102;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  line-height: 26px;
  width: 32px;
  height: 32px;
  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -ms-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

.standard-property .prop-photo-nav.next-photo {
  left: auto;
  right: 0;
}

.standard-property:hover .prop-photo-nav {
  opacity: 1;
}

.standard-property:hover .address a {
  color: #279c8c;
}

.standard-property .open-house-banner, .standard-property .sold-banner {
  background: rgba(39, 156, 140, 0.8);
  position: absolute;
  top: 0;
  padding: 0 10px 2px;
  z-index: 102;
  letter-spacing: 0.8px;
  line-height: 19px;
}

.standard-property .sold-banner {
  background: rgba(0, 0, 0, 0.8);
}

.standard-property .prop-cta {
  border-top: 1px solid #919091;
}

.standard-property .cta-link a {
  color: #000;
}

.standard-property .cta-link a:hover {
  text-decoration: none;
}