/*!
 * Reinhart Realtors Website Theme
 *
 * Property details styles
 * 
 */

.property-details-page {
  .banner {
    line-height: 16px;
    padding: 5px;
    background-color: $bright-red;
    color: $white;
  }
  .cta-link {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    padding-left: 30px;
    font-weight: 400;
    text-decoration: none;
    color: $carbon-gray;
    background-color: transparent;
    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-top: 25px;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 30px;
      padding-top: 0;
    }
    &:hover {
      color: $dark-red;
    }
    &.active {
      color: $dark-red;
      .unchecked {
        display: none;
      }
      .checked {
        display: inline-block;
      }
    }
    .checked {
      display: none;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      vertical-align: bottom;
      @include media-breakpoint-up(md) {
        left: 50%;
        transform: translateX(-50%);
      }
      @include media-breakpoint-up(lg) {
        left: 0;
        transform: translateX(0);
      }
    }
  }
  .action-btn {
    padding: 0;
    outline: 0;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    &:hover,
    &:focus {
      color: $dark-red;
    }
  }
  .action-link {
    color: $carbon-gray;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $dark-red;
    }
  }
  .action-btn .svg-icon,
  .action-link .svg-icon {
    font-size: 24px;
  }
  button.request-info {
    padding: 8px 11px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .property-open-house {
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding-top: 10px;
    padding-bottom: 10px
  }
  .btn.property-tooltip,
  .btn.property-modal {
    height: unset;
    vertical-align: top;
    margin-left: 2px;
    padding: 0;
    color: $medium-gray;
    &:focus {
      box-shadow: none;
    }
  }
  .map-cta {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 0;
    .inner {
      background-color: rgba($white,.8);
      padding: 10px;
      margin: 0 10px;
      a.btn-sm {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  #property-details-map {
    height: 262px;
  }
  .details-agent-wrap {
    display: flex;
    .details-agent-photo {
      width: 100px;
      margin: 5px 20px 0 0;
    }
    .details-agent-info {
      flex-grow: 1;
      a {
        text-decoration: none;
        color: $carbon-gray;
        &:hover {
          color: $dark-red;
        }
      }
    }
  }
  .nav.nav-tabs > li > a {
    @include media-breakpoint-up(lg) {
      padding: 14px 40px;
    }
  }
}

.property-header {
  li .svg-icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 24px;
    vertical-align: bottom;
    @include media-breakpoint-up(md) {
      left: 50%;
      transform: translateX(-50%);
    }
    @include media-breakpoint-up(lg) {
      left: 0;
      transform: translateX(0);
    }
  }
}

.btn.calculate-payment,
.btn.property-history,
.btn.property-content {
  height: unset;
  padding: 0;
  font-weight: normal;
  letter-spacing: unset;
  text-transform: capitalize;
  &:focus {
    box-shadow: none;
  }
  &:not(.collapsed) i {
    transform: rotate(-180deg);
  }
  i {
    font-size: 18px;
    transition: transform 0.2s linear;
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
  }
}
.btn.calculate-payment,
.btn.property-history {
  margin-bottom: 10px;
  color: $bright-red;
  &:hover {
    color: $dark-red;
  }
}
.btn.property-content {
  display: flex;
  align-items: center;
  font-weight: 300;
}

#propertyDetailsCarousel {
  .carousel-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: $white;
    transition: opacity 0.2s linear;
    &.no-visibility {
      opacity: 0;
    }
    .spinner-border {
      position: relative;
      // left: calc(50% - 1em); inherits text-center from header element
      top: calc(50% - 1em);
    }
  }
}

#propertyHistory table {
  th, td {
    white-space: nowrap;
  }
}

#payment-calc-instance,
#mrtgCalc,
#calculatedPayment {
  label {
    font-size: 13px;
    line-height: 24px;
  }
  span.form-control {
    border-bottom-color: transparent;
  }
}

#payment-calc-instance,
#mrtgCalc {
  .down-payment-block {
    display: flex;
    input {
      margin-right: 10px;
    }
    select {
      min-width: 125px;
    }
  }
}

#calculatedPayment .pmt-total {
  label,
  span {
    color: $carbon-gray;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
  }
}

#photoGallery {
  button.close {
    float: unset;
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 32px;
    color: $white;
    opacity: 0;
  }
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: unset;
      margin: 1.75rem 1rem;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1140px;
      margin: 1.75rem auto;
    }
  }
  .modal-content {
    background-color: $black;
    transition: opacity .5s ease-in;
  }
  .modal-header {
    background-color: $black;
    border: none;
    padding-left: 0px;
    .photo-gallery-title {
      font-size: 18px;
      font-weight: normal;
      color: $white;
    }
    button.close {
      color: $white;
      font-size: 2em;
      opacity: 1;
    }
  }
  .modal-body {
    padding: 0;
  }
  .property-gallery-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.5rem;
  }
  .property-gallery-counter {
    color: $white;
    margin-right: 10px;
    font-size: 18px;
    opacity: 0.8;
    @include media-breakpoint-up(lg) {
      font-size: 22px;
    }
  }
  button.splide__toggle {
    padding: 0;
    height: unset;
    color: $white;
    &:focus {
      box-shadow: none;
    }
    //color: $white;
    .svg-icon {
      font-size: 36px;
      @include media-breakpoint-up(lg) {
        font-size: 42px;
      }
    }
  }
}

.tooltip.sqft-tooltip {
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    max-width: 300px;
    background-color: $white;
    color: $black;
    border-top: 2px solid $black;
    box-shadow: 0 6px 7px 0 rgba($black,.3);
  }
}

.prop-inq-modal-addr {
  font-size: 20px;
  margin-bottom: 0;
}

.prop-inq-modal-mls {
  font-size: 16px;
  margin-bottom: 0;
}

#requestMoreInfo {
  .custom-control {
    .custom-control-label.schedule-showing-label {
      color: $dark-charcoal-gray;
      text-transform: none;
      line-height: 30px;
      &:before {
        top: 0.5rem;
        border: 1px solid $dark-charcoal-gray;
      }
      &:after {
        top: 0.5rem;
      }
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $dark-charcoal-gray;
    }
  }
  input#showing-date:not([disabled])::placeholder {
    color: $dark-charcoal-gray;
  }
}
