/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// Following recommendations from Bootstrap 4 themeing instructions found here:
// https://getbootstrap.com/docs/4.6/getting-started/theming/

// Include functions first for manipulating colors, SVGs, calc, etc)
@import "../../bootstrap/functions";

// Include default variable ovverrides here - most ovverides wiil come
// from the Reinhart style guide and override the values in bootstrap/_variables.scss
@import "variables/colors"; // Used by global/variables/bootstrap
@import "../../global/variables/bootstrap";
// Consider adding a local variables file here for the themes that overrides key main site/black theme values


//Include require Bootstrap stylesheet modules according to docs 
@import "../../bootstrap/variables";
@import "../../bootstrap/mixins";

// Comment out any unsed or unwanted modules
@import "../../bootstrap/root";
@import "../../bootstrap/reboot";
// @import "bootstrap/type"; 
@import "../../bootstrap/images";
@import "../../bootstrap/code";
@import "../../bootstrap/grid";
@import "../../bootstrap/tables";
@import "../../bootstrap/forms";
@import "../../bootstrap/buttons";
@import "../../bootstrap/transitions";
@import "../../bootstrap/dropdown";
@import "../../bootstrap/button-group";
@import "../../bootstrap/input-group";
@import "../../bootstrap/custom-forms";
@import "../../bootstrap/nav";
@import "../../bootstrap/navbar";
@import "../../bootstrap/card";
@import "../../bootstrap/breadcrumb";
@import "../../bootstrap/pagination";
@import "../../bootstrap/badge";
@import "../../bootstrap/jumbotron";
@import "../../bootstrap/alert";
@import "../../bootstrap/progress";
@import "../../bootstrap/media";
@import "../../bootstrap/list-group";
@import "../../bootstrap/close";
@import "../../bootstrap/toasts";
@import "../../bootstrap/modal";
@import "../../bootstrap/tooltip";
@import "../../bootstrap/popover";
@import "../../bootstrap/carousel";
@import "../../bootstrap/spinners";
@import "../../bootstrap/utilities";
@import "../../bootstrap/print";
