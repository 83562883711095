/*!
 * Reinhart Realtors Agent Theme - Base
 * Authored by MavTech Consulting (https://www.mavtechconsulting.com)
 * Based on the Bootstrap 4 framework
 */

// Import global styles
@import "global/typography"; // leave at top because later styles overwrite some typocgraphy values
@import "global/reboot";
@import "global/colors"; // may need redefined or moved to the individual agent themes so the colors can be customized
@import "global/text";
@import "global/icons";
@import "global/maps";
@import "global/layout";

// Import component styles
@import "components/buttons";
@import "components/card";
@import "components/carousel";
@import "agent-themes/components/footer";
@import "components/forms";
@import "agent-themes/components/header";
@import "components/nav";
@import "agent-themes/components/navigation";
@import "components/tab-accordion";

// Import page styles
@import "pages/home";
@import "agent-themes/pages/home";
@import "pages/offices";
@import "pages/agents";
@import "agent-themes/pages/content";
@import "pages/landing-page";
@import "pages/login-form";
@import "pages/mobile";
@import "pages/agent-details";
@import "pages/community-details";
@import "pages/all-communities";
@import "pages/buyer-seller";
@import "pages/property-search";
@import "pages/property-details";
@import "pages/account";
@import "pages/compare-properties";
@import "agent-themes/pages/contact";
@import "pages/new-construction";

// remove at very end
@import "needs_review";
