/*!
 * Reinhart Realtors Website Theme
 *
 * Tabbed accordion
 * A special component when collapsable elements are contained within tabs
 * Currently, this component is limited to the agent details and community details pages
 * If this becomes more common across the site, move to a specialized class so it doesn't
 * affect the operation of other generic Bootstrap components across the site
 * 
 */

#realtor-bio-page,
.property-details-page {
  .tab-content > .tab-pane {
    @include media-breakpoint-up(lg) {
      > .collapse {
        display: block;
      } 
      > .collapsing {
        transition: none; 
      }
    }
  }
  // Show mobile triggers up to lg screen - overwite nav transitions for small screens
  .tab-content > .tab-pane {
    @include media-breakpoint-down(md) {
      display: block;
      opacity: 1;
    }
  }
  // mobile trigger hidden at lg screen
  .mobileTrigger {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
 
// #community-page {
#community-bio-content {
  > .tab-content > .tab-pane {
    @include media-breakpoint-up(md) {
      > .collapse {
        display: block;
      } 
      > .collapsing {
        transition: none; 
      }
    }
  }
  // Show mobile triggers up to md screen - overwite nav transitions for small screens
  > .tab-content > .tab-pane {
    @include media-breakpoint-down(sm) {
      display: block;
      opacity: 1;
    }
  }
  // mobile trigger hidden at md screen
  .mobileTrigger {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
