/*!
 * Reinhart Realtors Website Theme
 *
 * Set color style properties from Reinhart style guide
 * 
 */

//
// Foreground colors properties
// 

.carbon-gray {
  color: $carbon-gray;
}

.dark-gray {
  color: $dark-gray;
}

.dark-charcoal-gray {
  color: $dark-charcoal-gray;
}

.med-gray {
  color: $medium-gray;
}

.light-gray {
  color: $light-gray;
}

.off-white {
  color: $off-white;
}

.white {
  color: $white;
}

.white-hover:hover,.white-hover:focus {
  color: $white;
}

.black {
  color: $black;
}

.black-hover:hover,.black-hover:focus {
  color: $black;
}

.dark-cool-gray {
  color: $dark-cool-gray;
}

.med-cool-gray {
  color: $med-cool-gray;
}

.light-cool-gray {
  color: $light-cool-gray;
}

.dark-red {
  color: $dark-red;
}

.bright-red {
  color: $bright-red;
}

.rebrand-red {
  color: $rebrand-red;
}

.bg-rebrand-red {
  background-color: $rebrand-red !important;
}

.brand-primary {
  color: $dark-gray;
}

//
// Background color properties
//

.bg-carbon-gray {
  background-color: $carbon-gray;
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.bg-dark-charcoal-gray {
  background-color: $dark-charcoal-gray;
}

.bg-med-gray {
  background-color: $medium-gray;
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-off-white {
  background-color: $off-white;
}

// Removed - exists in bootstrap/utilities/background:13-15
// .bg-white {
//   background-color: $white;
// }

.bg-white-75 {
  background-color: rgba($white,.75);
  transition: background-color .25s ease-in;
}

.bg-dark-cool-gray {
  background-color: $dark-cool-gray;
}

.bg-med-cool-gray {
  background-color: $med-cool-gray;
}

.bg-light-cool-gray {
  background-color: $light-cool-gray;
}

.bg-dark-red {
  background-color: $dark-red;
}

.bg-bright-red {
  background-color: $bright-red;
}

.bg-rebrand-red {
  background-color: $rebrand-red;
}

.bg-black {
  background-color: $black;
}

.bg-black-75 {
  background-color: rgba($black,.75);
}

//
// Border colors
//

.border-carbon-gray {
  border: 1px solid $carbon-gray;
}

.border-dark-cool-gray {
  border: 1px solid $dark-cool-gray;
}

.border-med-cool-gray {
  border: 1px solid $med-cool-gray;
}

.border-light-cool-gray {
  border: 1px solid $light-cool-gray;
}
