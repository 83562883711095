/*!
 * Reinhart Realtors Website Theme
 *
 * Nav, nav tabs, nav pills
 *
 * Overrides Bootstrap style values
 * 
 */

.nav.nav-tabs {
  background-color: $light-cool-gray;
  border: 0;
  border-bottom: none;
  // List item / nav-item styling
  > li,
  &.nav-justified > li {
    padding-bottom: 0;
    padding-top: 15px;
    position: relative;
    border: none;
    margin-bottom: 1px;
    margin-top: 1px;
    // float: none; Removing - no effect due to flex box
    text-align: center;
    vertical-align: bottom;
    @include media-breakpoint-up(lg) {
      background-color: transparent;
      // float: left; Removing - no effect due to flex box
      padding-left: 0;
      // border-left: none; Removing since border set to none above
      // border-right: none;
      // border-top: none;
      margin-bottom: 0;
      margin-top: 0;
    }
    // Anchor element / nav-link styling
    > a {
      display: block;
      color: $white;
      text-transform: uppercase;
      letter-spacing: .05em;
      border: 0;
      border-radius: 0;
      position: relative;
      width: 100%;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
        padding: 14px 30px;
      }
      @include media-breakpoint-up(lg) {
        padding: 14px 20px;
      }
      // Pseudo-state styles
      &:hover,
      &:active,
      &:focus {
        background-color: $dark-charcoal-gray;
        border: 0;
      }
      // Active nav-link styles
      &.active {
        color: $dark-charcoal-gray;
        background-color: $white;
        opacity: 1;
      }
    }
  }
}