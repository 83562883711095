/*!
 * Reinhart Realtors Website Theme
 *
 * Compare properties styles
 * 
 */

.compare-properties{
  overflow: auto;
}

.compare-properties-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 300px;
}

.title-grid, .compare-properties-column {
  display: grid;
  grid-template-rows: 280px repeat(15, 59px) 1fr; // fixed first row height to keep rows aligned
  align-items: stretch;
  gap: 0px;
  height: 100%;
}

.compare-properties-column {
  // display: grid;
  // align-items: stretch;
  // gap: 0px;
  // height: 100%;   
  .compare-cell-width {
    max-width: 300px;
    padding: 15px;
    border-right: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
  }
  .bi-x-lg {
    font-size: 1.25rem;
    color: $bright-red;
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
}

.title-grid {
  .compare-cell-width {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #c4c4c4;
    padding: 15px 15px 18px 15px;
    &:last-child {
      align-items: flex-start;
      border-bottom: 0px solid #c4c4c4;
    }
  }
}

.compare-photo {
  position: relative;
  width: 270px;
  aspect-ratio: 3 / 2;
  overflow: hidden;
  @supports not (aspect-ratio: 3 / 2) {
    height: 167px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .status-banner {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 10px 2px;
    z-index: 10;
    letter-spacing: 0.8px;
    line-height: 19px;
    color: $white;
    background: rgba($dark-red,0.8);
    text-transform: uppercase;
  }
}

.compare-street-addr {
  display: block;
  padding-top: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// For property modal
#preferred-date-text-mask {
  position: absolute;
  top: 1px;
  left: 10px;
  background: #e9ecef;
  width: 114px;
}
