/*!
 * Reinhart Realtor Website Theme
 *
 * Page specific styles for https://www.reinhartrealtors.com/buyers-guide/
 * Page specific styles for https://www.reinhartrealtors.com/sellers-guide/
 */

.buyer-seller-page {
  .hero {
    // height: 480px;
    height: 560px;
    @include media-breakpoint-down(sm) {
      height: 440px;
    }
  }
  .search-wrap {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    z-index: 1;
    h1, h2 {
      text-shadow: 0 2px 6px rgba(0,0,0,.5);
    }
    .bg-white-75 {
      box-shadow: 0 9px 18px 0 rgba(0,0,0,.03),0 2px 7px 0 rgba(0,0,0,.03);
      background-color: rgba(255,255,255,.85);
    }
    form input {
      height: 50px;
      background: 0 0;
      font-size: 16px;
      padding: 12px 0 0 0;
    }
    form input[placeholder] {
      text-overflow: ellipsis;
    } 
  }
  hr {
    border-color: $off-white; // Migration value #e0e0e0
  }
  .tabClass li button {
    background: $light-gray;
    color: $dark-charcoal-gray; // old site - $light-cool-gray
    letter-spacing: .8px;
    padding: 30px 4px 22px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out;
    border: 0px solid $black;
    width: 100%;
    height: 100%;
    @include media-breakpoint-down(sm) {
      padding: 11px;
      border-radius: 30px 0 0 30px;
    }
    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }
    span {
      transition: all .15s ease-in-out,border-color .15s ease-in-out;
      font-weight: 400;
      font-size: 30px;
      color: $carbon-gray;
      position: absolute;
      left: 50%;
      top: -24px;
      line-height: 40px;
      background-color: $white;
      transform: translateX(-50%);
      width: 46px;
      height: 46px;
      border-radius: 50%;
      border: 2px solid $off-white;
      @include media-breakpoint-down(sm) {
        left:0;
        top: 0;
        transform: none;
      }
    }
    // &:after {
    //   @include media-breakpoint-up(md) {
    //     transition: background-color .15s ease-in-out;
    //     content: "";
    //     background-color: $light-gray; // has to match the li button background above
    //   }
    // }
    &.active,
    &:hover {
      // background-color: $light-cool-gray;
      background-color: $dark-charcoal-gray;
      color: $white;
      span {
        // border-color: $light-cool-gray;
        border-color: $dark-charcoal-gray;
        // color: $light-cool-gray;
        color: $dark-charcoal-gray;
      }
    }
    &.active:after {
      @include media-breakpoint-up(md) {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 16px;
        height: 16px;
        // background-color: $light-cool-gray;
        background-color: $dark-charcoal-gray;
      }
    }
  }
  .buyer-seller-helper-container {
    margin-top: 20px;
    button.btn {
      background-color: $light-gray;
      border-color: $light-gray;
      color: $black;
      font-weight: normal;
      font-size: 14px;
      @include media-breakpoint-up (md) {
        font-size: 15px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }
      &:hover {
        background-color: $dark-charcoal-gray;
        border-color: $dark-charcoal-gray;
        color: $white;
      }
    }
  }
  .guide-step-btn {
    min-width: 180px;
    @include media-breakpoint-up (sm) {
      min-width: 150px;
    }
  }
}
