#community-map-all {
    height: 300px;

    @include media-breakpoint-up(md) {
        height: 400px;
    }
    
    @include media-breakpoint-up(xl) {
        height: 700px;
    }
}

#community-list {
    border: 1px solid #f5f5f5;
    border-left: none;
    border-right: none;
    overflow: auto;
    height: 450px;
    -webkit-box-shadow: -4px 0 4px -4px rgba(0,0,0,.5);
    box-shadow: -4px 0 4px -4px rgba(0,0,0,.5);

    @include media-breakpoint-up(lg) {
        height: 600px;
    }
    
    @include media-breakpoint-up(xl) {
        height: 700px;
    }

    li {
        &.active, &:hover, &:active {
            background-color: #f5f5f5;

            a {
                color: $dark-red;
            }
        }
    }

    .community-image {
        img {
            object-fit: cover;
            width: 217px;
            height: 135px;
            overflow: hidden;
        }

        .community-number {
            font-weight: 700;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            width: 36px;
            height: 36px;
            z-index: 1;
        }
    }

    .community-info a:hover {
        text-decoration: none;
    }
}
