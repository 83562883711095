/*!
 * Reinhart Realtors Website Theme
 *
 * Set typography values from Reinhart style guide
 * Replaces Bootstrap _type.scss, which is commendted out
 * Affects following Bootstrap styles:
 *    h1 - h6, .h1 - .h6, .lead, display-1 - diplay-4, hr, small/.small, mark/.mark
 *    .list-unstyled, .list-inline, .list-inline-item, .initialism, .blockquote, .blockqoute-footer
 * If any of these styles from Bootstrap are desired copy from the Bootstrap _type.scss and
 * insert in this file
 */

//
// Headings
// 

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $headings-color;
}

// Base heading sizes - applies to xs and sm (<768 px)
// font-sizes set to explicit px values, could switch to $font-size-base * <scaling-factor>
// and generate the scaling factor assuming most browsers use 16px as the 1rem value
h1, .h1 {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
}

h2, .h2 {
  font-weight: 300;
  font-size: 28px;
  line-height: 36px
}

h3, .h3 {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px
}

h4, .h4 {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px
}

h5, .h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: .05em
}

h6, .h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: .05em
}

// md heading sizes - applies to md screens (>768px, <992px)
@include media-breakpoint-up(md) {
  h1, .h1 {
    font-size:38px;
    line-height: 50px
  }
  h2, .h2 {
    font-size:34px;
    line-height: 44px
  }
  h3, .h3 {
    font-size:27px;
    line-height: 36px
  }
  h4, .h4 {
    font-size:27px;
    line-height: 36px
  }
  h5, .h5 {
    font-size:15px;
    line-height: 20px
  }
  h6, .h6 {
    font-size:15px;
    line-height: 22px
  }
}

// lg heading sizes - applies to lg screens (>992px, <1200px)
@include media-breakpoint-up(lg) {
  h1, .h1 {
    font-size:46px;
    line-height: 56px
  }
  h2, .h2 {
    font-size:38px;
    line-height: 48px
  }
  h3, .h3 {
    font-size:30px;
    line-height: 40px
  }
  h4, .h4 {
    font-size:30px;
    line-height: 40px
  }
  h5, .h5 {
    font-size:16px;
    line-height: 20px
  }
  h6, .h6 {
    font-size:16px;
    line-height: 24px
  }
}

// xl heading sizes
@include media-breakpoint-up(xl) {
  h1,.h1 {
    font-size:46px;
    line-height: 56px
  }
  h2,.h2 {
    font-size:38px;
    line-height: 48px
  }
  h3,.h3 {
    font-size:30px;
    line-height: 40px
  }
  h4,.h4 {
    font-size:30px;
    line-height: 40px
  }
  h5,.h5 {
    font-size:16px;
    line-height: 20px
  }
  h6,.h6 {
    font-size:16px;
    line-height: 24px
  }
}

// .lg headings

h1.lg {
  font-size: 46px;
  line-height: 56px
}

h2.lg {
  font-size: 38px;
  line-height: 48px
}

h3.lg {
  font-size: 30px;
  line-height: 40px
}

h4.lg {
  font-size: 30px;
  line-height: 40px
}

h5.lg {
  font-size: 16px;
  line-height: 24px
}

h6.lg {
  font-size: 16px;
  line-height: 24px
}

// .md headings

h1.md {
  font-size: 46px;
  line-height: 56px
}

h2.md {
  font-size: 38px;
  line-height: 48px
}

h3.md {
  font-size: 30px;
  line-height: 40px
}

h4.md {
  font-size: 30px;
  line-height: 40px
}

h5.md {
  font-size: 16px;
  line-height: 24px
}

h6.md {
  font-size: 16px;
  line-height: 24px
}

// .sm headings

h1.sm {
  font-size: 38px;
  line-height: 50px
}

h2.sm {
  font-size: 34px;
  line-height: 44px
}

h3.sm {
  font-size: 27px;
  line-height: 36px
}

h4.sm {
  font-size: 27px;
  line-height: 36px
}

h5.sm {
  font-size: 15px;
  line-height: 22px
}

h6.sm {
  font-size: 15px;
  line-height: 22px
}

// .xs headings

h1.xs {
  font-size: 30px;
  line-height: 40px
}

h2.xs {
  font-size: 28px;
  line-height: 36px
}

h3.xs {
  font-size: 24px;
  line-height: 30px
}

h4.xs {
  font-size: 24px;
  line-height: 30px
}

h5.xs {
  font-size: 14px;
  line-height: 20px
}

h6.xs {
  font-size: 14px;
  line-height: 20px
}

//
// Paragraphs
//

p, .p {
  line-height: 24px;
  color: $dark-gray;
}

//
// Lists
// From bootstrap/_type.scss, lines 80-98
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}

// Anhors/links
//

a:focus {
  color: $bright-red;
}
