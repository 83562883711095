/*!
 * Reinhart Realtor Website Theme
 *
 * Page specific styles for https://www.reinhartrealtors.com/
 */

// Temporarily removing for agents search page
// Should restrict this alert to the home page only
//  main .alert {
//   position: absolute;
//   z-index: 105;
//   width: 100%;
//   top: 120px
// }

.homepage {
  // Hero image
  .rr-hero-container {
    height: 300px;
    height: clamp(350px, 50px + 75vw, 500px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include media-breakpoint-up(md) {
      height: 500px;
      height: clamp(500px, 100vw - 300px, 600px);
    }
    @include media-breakpoint-up(lg) {
      height: 700px;
      height: clamp(600px, 400px + 20vw, 800px);
      // margin-top: -80px; // in global/_layout.scss
    }
  }
  // Quick Search
  .rr-quick-search-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .rr-quick-search-tagline {
      width: 225px;
      color: $white;
      font-size: 36px;
      font-weight: bold;
      line-height: 1.2;
      text-align: center;
      letter-spacing: 0.5pt;
      text-shadow: 0px 0px 8px $black;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
      @include media-breakpoint-up(md) {
        font-size: 42px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 290%;
      }
    }
    .rr-quick-search-form {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      max-width: 500px;
      @include media-breakpoint-up(md) {
        width: 500px;
        max-width: unset;
        padding-left: 0px;
        padding-right: 0px;
      }
      @include media-breakpoint-up(lg) {
        width: 550px;
      }
      form {
        display: flex;
        position: relative;
        width: 100%;
        input {
          width: 100%;
          padding: 10px 70px 10px 30px;
          border-radius: 30px;
          border: none;
          outline: none;
          font-size: 16px;
          &::placeholder {
            color: $black;
          }
          @include media-breakpoint-up(md) {
            padding: 12px 80px 14px 35px;
          }
          // @include media-breakpoint-up(lg) {
          //   font-size: 18px;
          // }
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: $bright-red;
          color: $white;
          border-radius: 30px;
          border: none;
          width: 70px;
          padding: 0;
          text-align: center;
          font-size: 28px;
          @include media-breakpoint-up(sm) {
            width: 80px;
            font-size: 30px;
          }
          @include media-breakpoint-up(lg) {
            width: 90px;
            // font-size: 32px;
          }
        }
      }
    }
    .rr-quick-search-links {
      display: flex;
      // justify-content: space-between;
      justify-content: space-evenly;
      @include media-breakpoint-up(md) {
        width: 500px;
      }
      @include media-breakpoint-up(lg) {
        width: 550px;
      }
      a {
        height: auto;
        color: $white;
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.5px;
        padding: 10px;
        text-shadow: 0px 0px 8px #000;
        @include media-breakpoint-up(sm) {
          font-size: 16px;
          padding: 10px 15px;
        }
        @include media-breakpoint-up (lg) {
          font-size: 18px;
        }
        &:hover,
        &:focus {
          text-decoration: underline;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  // Introduction
  .rr-intro-heading {
    color: $bright-red;
    font-size: clamp(32px, 0.75px + 12.5vw, 64px);
    font-weight: 300;
    letter-spacing: 0.025em;
    line-height: 1.2;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      // font-size: 72px;
      margin-left: 60px;
      font-size: 64px;
    }
    @include media-breakpoint-up(xl) {
      margin-left: 150px;
    }
  }
  .rr-intro-text {
    // font-size: 22px;
    // font-size: clamp(16px, 12.25px + 1.5vw, 22px);
    font-size: clamp(16px, 12.25px + 1.5vw, 20px);
    // line-height: 1.6;
    line-height: 1.8;
    @include media-breakpoint-up(lg) {
      width: 340px;
    }
  }
  
  // Tools You Need section
  .rr-tools-row {
    justify-content: center;
    @include media-breakpoint-up(sm) {
      justify-content: unset;
    }
    @include media-breakpoint-up(md) {
      justify-content: space-evenly;
    }
  }

  .rr-tool-container {
    a {
      min-width: 170px;
      min-width: clamp(140px, 30vw, 170px);
      @include media-breakpoint-up(sm) {
        min-width: unset;
      }
      &:hover,
      &:focus {
        color: $white;
      }
    }
    img {
      width: auto;
      // height: 140px;
      height: 130px;
      // @include media-breakpoint-up(md) {
      //   height: 170px;
      // }
    }
    svg {
      display: block;
      width: 120px;
      // height: 140px;
      height: 130px;
      margin: 0 auto 15px;
      padding-top: 10px;
      color: $white;
      // @include media-breakpoint-up(md) {
      //   height: 170px;
      // }
    }
  }
  
  // Community section
  #communityWrapper {
    .community {
      text-align: center;
      a {
        display: block;
        transition: box-shadow .25s ease-in;
        &:hover {
          text-decoration: none!important;
          box-shadow: 0 9px 18px 0 rgba($black,.09),0 2px 7px 0 rgba($black,.06);
          span {
            color: $dark-red;
          }
        }
      }
      img {
        width: 100%;
        aspect-ratio: 3 / 2;
        object-fit: cover;
      }
    }
  }
  
  // Market Stats section on home page
  #market-stats .card-shadow {
    box-shadow: 0 9px 18px 0 rgba($black,.1),0 2px 7px 0 rgba($black,.1);
    background-color: $white;
    margin-top: 30px;
    min-height: 180px;
    min-width: 165px;
    @include media-breakpoint-up(lg) {
      min-height:200px
    }
    h5 {
      min-height: 40px;
      &.h1 {
        letter-spacing: unset;
      } 
    }
  }
  
  // Blog section on home page
  #featuredBlogContainer {
    position: relative;
    min-height: 125px;;
  }

  .featured-blog-loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: $white;
    transition: opacity 1s linear;
    z-index: 5;
    &.loading {
      opacity: 1;
    }
  }

  .sm-blog-img {
    height: max(190px, 35vw);
    @include media-breakpoint-up(md) {
      height: 190px;
    } 
  }
  
  .popular_feed {
    a {
      color: $bright-red;
      border-bottom: 1px solid transparent;
      text-decoration: none;
      &:hover,
      &:active {
        color: $dark-red;
        border-bottom: 1px solid $dark-red;
      }
    }
    .sm-blog-img {
      height: max(190px, 35vw);
      @include media-breakpoint-up(md) {
        height: 400px;
      }
    }
  }
  
  h3.home-blog-sidebar {
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
    font-weight: 400;
    a {
      color: $bright-red;
      border-bottom: 1px solid transparent;
      text-decoration: none;
      &:hover,
      &:active {
        color: $dark-red;
        border-bottom: 1px solid $dark-red;
      }
    }
  }
}


// From the existing site - needs moved above this line and Sass-ified

// #site-header .breadcrumb-bar {
//   background-color: transparent
// }

// #site-header .breadcrumb-bar .dropdown-toggle {
//   color: #fff
// }

// body.win-scrolled #site-header {
//   background-color: #fff;
//   -webkit-box-shadow: 0 1px 8px 0 rgba(0,0,0,.25);
//   box-shadow: 0 1px 8px 0 rgba(0,0,0,.25)
// }

// @media screen and (max-width: 767px) {
//   body.win-scrolled #site-header {
//       background-color:rgba(255,255,255,.75)
//   }
// }

// body.win-scrolled #site-header .breadcrumb-bar .dropdown-toggle {
//   color: #4a525d
// }

// body.win-scrolled #site-header .breadcrumb-bar .compact-menu a {
//   color: #4a525d
// }

// body.win-scrolled.compact-menu-open #site-header {
//   background-color: #fff
// }

// @media screen and (max-width: 767px) {
//   body.win-scrolled.compact-menu-open #site-header {
//       background-color:rgba(255,255,255,.75)
//   }
// }

// body.win-scrolled.compact-menu-open #site-header .desktop-nav {
//   background-color: #4a525d
// }

// body.win-scrolled.compact-menu-open #site-header .desktop-nav a.top-level-item {
//   color: #fff
// }

// @media screen and (max-width: 767px) {
//   body.win-scrolled.win-mobile-scrolled #site-header {
//       background-color:#fff
//   }
// }

// @media screen and (max-width: 767px) {
//   #site-header .desktop-nav {
//       background-color:#626e7a!important
//   }

//   #site-header a[aria-controls=desktop-nav] svg {
//       fill: #fff
//   }
// }

// Temporarily commented out - likely needs added back, limited to home page
// .hero {
//   background: #5c5c5c;
//   position: relative;
//   top: 0;
//   right: 0;
//   left: 0
// }

// @media screen and (min-width: 768px) {
//   .hero {
//       height:450px
//   }
// }

// @media screen and (min-width: 992px) {
//   .hero {
//       height:680px
//   }
// }

// @media screen and (min-width: 1200px) {
//   .hero {
//       height:680px
//   }
// }

// .hero .bg-image-cover {
//   height: 250px
// }

// @media screen and (min-width: 768px) {
//   .hero .bg-image-cover {
//       height:100%
//   }
// }

// @media screen and (min-width: 768px) {
//   .hero:before {
//       box-shadow:inset 0 230px 160px -250px #000;
//       height: 250px;
//       display: block;
//       width: 100%;
//       content: "";
//       position: absolute;
//       z-index: 1;
//       top: 74px
//   }

//   body.win-scrolled .hero:before {
//       top: 50px
//   }
// }

#quick-search-bar {
  background-color: transparent;
  bottom: 0;
  padding: 0;
  width: 100%
}

@media screen and (max-width: 767px) {
  #quick-search-bar {
      margin-top:2px
  }
}

@media screen and (min-width: 768px) {
  #quick-search-bar:before {
      box-shadow:inset 0 -230px 160px -170px #000;
      height: 250px;
      display: block;
      width: 100%;
      content: "";
      position: absolute;
      z-index: 1;
      margin-top: -250px
  }
}

#quick-search-bar .tabClass .tab-content {
  margin-top: 0
}

@media screen and (min-width: 768px) {
  #quick-search-bar .tabClass .tab-content {
      padding:20px 0 0
  }
}

@media screen and (min-width: 992px) {
  #quick-search-bar .tabClass .tab-content {
      padding:20px 0
  }
}

#quick-search-bar .address-city-zip {
  height: 42px;
  padding: 8px 15px;
  display: block;
  color: #8c8c8c;
  font-size: 16px;
  letter-spacing: .16px;
  line-height: 24px;
  border: 1px solid #8c8c8c
}

#quick-search-bar .address-city-zip.sm-typeahead-field {
  padding: 7px 50px 8px 15px
}

#quick-search-bar .address-city-zip[placeholder] {
  text-overflow: ellipsis
}

#quick-search-bar .qs-submit {
  position: absolute;
  right: 0;
  top: 0;
  width: 42px;
  height: 42px;
  min-width: 0
}

#quick-search-bar .nav.nav-tabs {
  background-color: transparent;
  padding: 0;
  position: absolute;
  z-index: 1;
  left: 0;
  top: -42px
}

#quick-search-bar .nav.nav-tabs span.h5 {
  color: inherit
}

#quick-search-bar .nav.nav-tabs>li {
  background-color: transparent;
  text-indent: 0!important;
  padding-top: 0
}

#quick-search-bar .nav.nav-tabs>li>a {
  color: #fff!important;
  opacity: 1;
  padding: 10px 19px
}

@media screen and (min-width: 1200px) {
  #quick-search-bar .nav.nav-tabs>li>a {
      padding:10px 29px
  }
}

#quick-search-bar .nav.nav-tabs>li>a span {
  border: 0;
  color: #fff
}

#quick-search-bar .nav.nav-tabs>li>a:hover,#quick-search-bar .nav.nav-tabs>li>a:active,#quick-search-bar .nav.nav-tabs>li>a:focus {
  background-color: #626e7a
}

#quick-search-bar .nav.nav-tabs>li>a:hover span,#quick-search-bar .nav.nav-tabs>li>a:active span,#quick-search-bar .nav.nav-tabs>li>a:focus span {
  border: 0;
  color: #fff;
  opacity: 1
}

#quick-search-bar .nav.nav-tabs>li>a:hover span:after,#quick-search-bar .nav.nav-tabs>li>a:active span:after,#quick-search-bar .nav.nav-tabs>li>a:focus span:after {
  width: 100%
}

#quick-search-bar .nav.nav-tabs>li.active {
  background-color: #f5f5f5
}

#quick-search-bar .nav.nav-tabs>li.active span {
  color: #2e2e2e
}

#quick-search-bar .nav.nav-tabs>li.active>a {
  color: #2e2e2e;
  background-color: transparent!important
}

#quick-search-bar .nav.nav-tabs>li.active>a:hover,#quick-search-bar .nav.nav-tabs>li.active>a:active,#quick-search-bar .nav.nav-tabs>li.active>a:focus {
  background-color: transparent
}

#quick-search-bar .nav.nav-tabs>li.active>a:hover span,#quick-search-bar .nav.nav-tabs>li.active>a:active span,#quick-search-bar .nav.nav-tabs>li.active>a:focus span {
  border: 0;
  color: #2e2e2e;
  opacity: 1
}

#quick-search-bar .nav.nav-tabs>li.active>a:hover span:after,#quick-search-bar .nav.nav-tabs>li.active>a:active span:after,#quick-search-bar .nav.nav-tabs>li.active>a:focus span:after {
  width: 100%
}

#quick-search-bar .mobile-selection a {
  color: #a41832
}

#quick-search-bar .mobile-selection a:hover,#quick-search-bar .mobile-selection a:active,#quick-search-bar .mobile-selection a:focus {
  text-decoration: none
}

#quick-search-bar .mobile-selection a:hover span,#quick-search-bar .mobile-selection a:active span,#quick-search-bar .mobile-selection a:focus span {
  color: #c4122f
}

.tools a:hover,.tools a:active {
  color: #fff;
  text-decoration: underline
}

#quick-search-tabs .nav-tabs {
  border-bottom: none
}

#quick-search-tabs li {
  padding-bottom: 0
}
