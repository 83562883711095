/*!
 * Reinhart Realtors Agent Website Theme - Teal
 *
 * Set color variables from Reinhart style guide
 * Compile this file before the _bootstrap.scss file as color values from
 * this file are referenced there.
 */

 // Grays
$carbon-gray:           #000; // new palette #000000; old palette color #2e2e2e
$dark-gray:             #000; // new palette #000000; old palette color #5c5c5c
$dark-charcoal-gray:    #404041; 
$medium-gray:           #919091; // new palette #919091; old palette color #8c8c8c
$light-gray:            #c8c7c8; // new palette #c8c7c8; old palette color #c4c4c4
$off-white:             #dedede; // new palette #dedede; old palette color #f5f5f5

// Cool Grays
$dark-cool-gray:        #000; // new palette #000000; old palette color $4a525d
$med-cool-gray:         #919091; // new palette #919091; old palette color #626e7a
$light-cool-gray:       #919091; // new palette #919091; old palette color #7e8897

// Reds
$dark-red:              #279c8c; // #a41832
$bright-red:            #2cae9c; // #c4122f
$rebrand-red:           #bc2023;

// Component colors
// Header and nav
$primary-header-bg:     $bright-red;
$nav-header-bg:         #000;
// navigation login button
$header-login-btn:        #fff;
$header-login-btn-hover:  $medium-gray;
$header-login-btn-bg:     transparent;
$header-login-btn-border: #fff;
$header-login-btn-icon:   #000;
$login-btn-hover-mobile:  $dark-charcoal-gray;
$nav-mobile-header-bg:  $bright-red;

// Home page colors
// btn primary
$home-btn-primary-bg:     $bright-red;
$home-btn-primary-color:  #fff;
$home-btn-primary-hover:  $dark-red;
// btn secondary
$home-btn-secondary-bg:     #fff;
$home-btn-secondary-color:  $bright-red;
$home-btn-secondary-hover-bg:  $dark-red;
$home-btn-secondary-hover-color: #fff;
// testimonials - exception case for black
$home-btn-testimonials-bg: $home-btn-primary-bg;
$home-btn-testimonials-color: $home-btn-primary-color;
$home-btn-testimonials-hover: $home-btn-primary-hover;

// Agent wells
// Tools you need well
$tools-you-need-label:          $bright-red;
$tools-you-need-label-hover:    $dark-red;
$tools-you-need-icon:           $bright-red;
$tools-you-need-icon-hover:     $dark-red;
