/*!
 * Reinhart Realtors Website Theme
 *
 * Carousel components - bootstrap, splide, others
 * 
 */

// Set image height and arrow styles for community and property details carousel
#communityDetailsCarousel,
#propertyDetailsCarousel,
#propertyDetailsGallery {
  .splide__arrow--prev {
      left: 0;
  }
  .splide__arrow--next {
      right: 0;
  }
  .splide__arrow {
      background: $dark-charcoal-gray;
      border-radius: 0;
      height: 2rem;
      width: 2rem;
      @include media-breakpoint-up(md) {
          height: 3rem;
          width: 3rem;
      }
      svg {
          fill: $white;
          height: 1.125rem;
          width: 1.125rem;
          @include media-breakpoint-up(md) {
              height: 1.625rem;
              width: 1.625rem;
          }
      }
  }
}

#communityDetailsCarousel,
#propertyDetailsCarousel {
  .splide__slide img {
      height: 100%;
  }
}

#propertyDetailsGallery .splide__slide img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
