/*!
 * Reinhart Realtor Website Theme
 *
 * Page specific styles for https://www.reinhartrealtors.com/offices/
 */

#office-roster-page .office-roster-map-container {
  position: relative;
  min-height: 600px;
  // min-height: 300px;
  // height: calc(100vh - 223px);
  // @include media-breakpoint-up(md) {
  //   height:calc(100vh - 257px)
  // }
}

#office-roster-page .office-roster-map-container .office-roster-map {
  background: $gray-400; // #ccc originally
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  height: -webkit-calc(100% - 46px);
  height: -moz-calc(100% - 46px);
  height: calc(100% - 46px)
}

#office-roster-page .office-roster-map-container .office-roster-map {
  @include media-breakpoint-up(md) {
    height:50%
  }
}

#office-roster-page .office-roster-map-container .office-roster-map {
  @include media-breakpoint-up(lg) {
    height:100%;
    width: -webkit-calc(100% - 550px);
    width: -moz-calc(100% - 550px);
    width: calc(100% - 550px)
  }
}


#office-roster-page .office-roster-map-container .office-roster-map {
  @include media-breakpoint-up(xl) {
    width:-webkit-calc(100% - 580px);
    width: -moz-calc(100% - 580px);
    width: calc(100% - 580px)
  }
}

#office-roster-page .office-roster-map-container .office-roster-list {
  height: 46px;
  top: -webkit-calc(100% - 46px);
  top: -moz-calc(100% - 46px);
  top: calc(100% - 46px);
  max-height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $white;
  transition: top .25s ease,height .25s ease
}

#office-roster-page .office-roster-map-container .office-roster-list {
  @include media-breakpoint-down(md) {
    padding-bottom:30px
  }
}

#office-roster-page .office-roster-map-container .office-roster-list {
  @include media-breakpoint-up(md) {
    padding-bottom:0;
    top: 50%;
    height: 50%;
    z-index: 102
  }
}

#office-roster-page .office-roster-map-container .office-roster-list {
  @include media-breakpoint-up(lg) {
    top:0;
    left: auto;
    width: 550px;
    height: 100%;
    padding-left: 5px
  }
}

#office-roster-page .office-roster-map-container .office-roster-list {
  @include media-breakpoint-up(xl) {
    width:580px
  }
}

#office-roster-page .office-roster-map-container .office-roster-list .office-roster-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  height: auto
}

#office-roster-page .office-roster-map-container.open .office-roster-list {
  top: 0;
  height: 100%
}

#office-roster-page .office-roster-map-container.open .office-roster-list .icon {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
  transition: transform .25s ease-in-out;
}

#office-roster-page .office-roster-map-container .single-office {
  background-color: $white;
  border: 1px solid $gray-500; // #c4c4c4 orignally
  -webkit-transition: box-shadow .25s ease;
  -moz-transition: box-shadow .25s ease;
  -ms-transition: box-shadow .25s ease;
  -o-transition: box-shadow .25s ease;
  transition: box-shadow .25s ease
}

#office-roster-page .office-roster-map-container .single-office.active,#office-roster-page .office-roster-map-container .single-office:hover {
  background-color: $gray-100; // #f5f5f5 orignally
  -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,.35);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.35)
}

#office-roster-page .office-roster-map-container .single-office.active .office-name,#office-roster-page .office-roster-map-container .single-office:hover .office-name {
  color: $dark-red;
}

#office-roster-page .office-roster-map-container .single-office .office-image,#office-roster-page .office-roster-map-container .single-office .office-info {
  @include media-breakpoint-up(md) {
    float:left
  }
}

#office-roster-page .office-roster-map-container .single-office .office-image {
  @include media-breakpoint-up(md) {
    width: 45%;
    max-width: 247px
  }
}

#office-roster-page .office-roster-map-container .single-office .office-info {
  @include media-breakpoint-up(md) {
    width: 50%;
    margin-left: 2.5% !important;
  }
}

#office-roster-page .office-roster-map-container .single-office address {
  line-height: 22px
}

#office-roster-page .office-roster-map-container .single-office a:hover {
  text-decoration: none
}

#office-roster-page .office-roster-map-container .resultsPropertyBubble {
  min-width: 370px;
  min-height: 116px;
  overflow: hidden
}

#office-roster-page .office-roster-map-container .gm-style img {
  width: 100%;
  max-width: 140px
}

#office-roster-page .office-roster-map-container .gm-style-iw>div>div {
  overflow: hidden!important
}

#office-bio-page .office-info-padding {
  padding: 20px
}

#office-bio-page .office-info-padding {
  @include media-breakpoint-up(md) {
    padding:0
  }
}

#office-bio-page .office-info-padding {
  @include media-breakpoint-up(lg) {
    padding:20px
  }
}

#office-bio-page .office-name {
  @include media-breakpoint-up(md) {
    font-size:30px
  }
}

#office-bio-page .office-name {
  @include media-breakpoint-up(lg) {
    font-size:34px
  }
}

#office-bio-page {
  .video-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
    font-size: 62px;
    width: 62px;
    height: 62px;
    display: block
  }
} 

#office-bio-page {
  .office-results-map {
    width: 100%;
    height: 300px;
    border-bottom: 3px solid $white;
  }
}

.gm-style .gm-style-iw {
  font: inherit;
  font-size: 16px;
  font-family: inherit;
}
