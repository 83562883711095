/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Home page styles
 * 
 */

 // generic button styles
 body.homepage {
  .btn-primary {
    background-color: $home-btn-primary-bg;
    border-color: $home-btn-primary-bg;
    color: $home-btn-primary-color;
    &:hover,
    &:focus {
      background-color: $home-btn-primary-hover;
      border-color: $home-btn-primary-hover;
      color: $home-btn-primary-color;
    }
    &:not(:disabled):not(.disabled):active {
      background-color: $home-btn-primary-hover;
      border-color: $home-btn-primary-hover;
      color: $home-btn-primary-color;
      box-shadow: none;
    }
  }
  .btn-secondary {
    background-color: $home-btn-secondary-bg;
    border-color: $home-btn-secondary-color;
    color: $home-btn-secondary-color;
    &:hover {
      background-color: $home-btn-secondary-hover-bg;
      border-color: $home-btn-secondary-hover-bg;
      color: $home-btn-secondary-hover-color;
    }
  }
  #testimonials .btn-primary {
    background-color: $home-btn-testimonials-bg;
    border-color: $home-btn-testimonials-bg;
    color: $home-btn-testimonials-color;
    &:hover,
    &:focus {
      background-color: $home-btn-testimonials-hover;
      border-color: $home-btn-testimonials-hover;
      color: $home-btn-testimonials-color;
    }
    &:not(:disabled):not(.disabled):active {
      background-color: $home-btn-testimonials-hover;
      border-color: $home-btn-testimonials-hover;
      color: $home-btn-testimonials-color;
      box-shadow: none;
    }
  }
}

section.rr-agent-home-about {
  margin-top: 30px;
  margin-bottom: 60px;
  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }
  .home-about-photo-container {
    overflow: hidden;
    width: 250px;
    height: 250px;
    max-width: calc(100vw - 30px);
    max-height: calc(100vw - 30px);
    border-radius: 50%;
    margin-bottom: 30px;
    @include media-breakpoint-up(md) {
      width: 200px;
      height: 200px;
      margin-bottom: 50px;
    }
    @include media-breakpoint-up(lg) {
      width: 250px;
      height: 250px;
    }
    @include media-breakpoint-up(xl) {
      width: 275px;
      height: 275px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .home-about-logo-container {
    width: 300px;
    max-width: calc(100vw - 30px);
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(md) {
      margin-left: unset;
      margin-right: unset;
    }
    @include media-breakpoint-up(lg) {
      width: 350px;
    }
    @include media-breakpoint-up(xl) {
      width: 400px;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 150px;
      object-fit: contain;
      object-position: center;
      @include media-breakpoint-up(md) {
        object-position: left;
        max-height: 120px;
      }
      @include media-breakpoint-up(lg) {
        max-height: 150px;
      }
    }
  }
  #agentBio {
    position: relative;
    &.collapse:not(.show) {
      display: block;
      height: 350px;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        height: 275px;
      }
      @include media-breakpoint-up(lg) {
        height: 325px;
      }
      @include media-breakpoint-up(xl) {
        height: 350px;
      }
      &:not(.show)::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 50px;
        background: linear-gradient(180deg, rgba($white,0) 0%, rgba($white,0) 10%, rgba($white,1) 80%);
      }
    }
    &.collapsing {
      height: 350px;
      @include media-breakpoint-up(md) {
        height: 275px;
      }
      @include media-breakpoint-up(lg) {
        height: 325px;
      }
      @include media-breakpoint-up(xl) {
        height: 350px;
      }
    }
  }
  #btnReadMore {
    height: unset;
    padding: 0;
    text-decoration: none;
    &:focus {
      box-shadow: none;
    }
    i {
      display: inline-block;
      transition: transform 0.4s linear;
    }
    &[aria-expanded="true"] i {
      transform: rotate(-180deg);
    }
  }
}

section.testimonials-listings {
  display: flex;
  flex-direction: column;
  // gap: 50px;
  margin-top: 60px;
  margin-bottom: 0px;
  @include media-breakpoint-up(md) {
    flex-direction: column-reverse;
  }
 }

.rr-agent-home-listings-container {
  .rr-agent-home-listings-select {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 30px;
    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      ul {
        justify-content: center;
        flex-grow: 1;
        padding-left: 52px;
      }
    }
  }
  .results-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: unset;
    }
  }
  .rr-prop-compare-container {
    display: none;
    padding: 0;
    @include media-breakpoint-up(lg) {
      display: block;
    }
    button {
      border: none;
      font-weight: 400;
    }
  }
  .nav.nav-tabs {
    background-color: transparent;
    > li {
      padding-top: 0;
    }
  }
  .nav-link {
    color: $dark-gray;
    border: none;
    font-weight: 400;
    .radio-checked {
      display: none;
    }
    .svg-icon {
      margin-bottom: 4px;
    }
    &.active {
      border: none;
      .radio-checked {
        display: inline-block;
      }
      .radio-unchecked {
        display: none;
      }
    }
  }
  button {
      font-size: inherit;
      color: inherit;
      text-transform: inherit;
      text-decoration: none;
      font-weight: 300;
      padding: 0;

      i {
          font-size: 30px;
          transition: transform 0.2s linear;
          @include media-breakpoint-down(md) {
              font-size: 24px;
          }
      }

      &:not(.collapsed) {
          i {
              transform: rotate(-180deg);
          }
      }

      &:hover,
      &:focus,
      &:active {
          color: $bright-red;
      }
      &:focus {
          box-shadow: none;
      }
  }
}

.rr-agent-home-testimonials-container {
  background-color: $dark-charcoal-gray;
  margin-top:40px;
  padding: 30px 0;
  @include media-breakpoint-up(md) {
    margin-top: 0px;
    margin-bottom: 60px;
  }
  h2 {
    text-align: center;
    color: $white;
  }
  p {
    color: $white;
  }
  .home-testimonials-header {
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      margin-bottom: 30px;
    }
  }
  .home-testimonials-icon {
    text-align: center;
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {
      margin-top: -10px;
      margin-bottom: 0;
    }
    svg {
      color: $white;
      width: 72px;
      height: 72px;
    }
  }
  .home-testimonials-content {
    position: relative;
    overflow: hidden;
    max-height: 375px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      height: calc(100% - 300px);
      background: linear-gradient(180deg, rgba($dark-charcoal-gray,0) 0%, rgba($dark-charcoal-gray,0) 10%, rgba($dark-charcoal-gray,1) 80%);
    }
  }
}
.rr-agent-home-custom-content {
  margin: 60px 0;
}

.rr-agent-wells-container {
  background-color: $off-white;
  padding: 60px 0 30px;
  .agent-wells {
    position: relative;
    // min-height: 500px;
    height: 100%;
    background-color: $white;
    @include media-breakpoint-up(lg) {
      min-height: 500px;
    }
  }
  .wells-image {
    height: 150px;
    @include media-breakpoint-up(md) {
      height: 250px;
    }
    @include media-breakpoint-up(lg) {
      height: 290px;
    }
  }
  .wells-title {
    min-height: 75px;
    @include media-breakpoint-up(lg) {
      min-height: 90px;
    }
  }
  .wells-text {
    min-height: 65px;
  }
  #toolsYouNeed {
    h4 {
      padding-top: 20px;
      margin-bottom: 65px;
      text-align: center;
    }
    a {
      color: $tools-you-need-label;
      //text-decoration: none;
      &:hover,
      &:focus {
        color: $tools-you-need-label-hover;
      }
    }
    svg {
      width: 125px;
      height: 100px;
      // color: $medium-gray;
    }
  }
  #agentJustListedContainer {
    padding: 20px;
    h4 {
      margin-bottom: 20px;
      text-align: center;
    }
    .carousel-item {
      transition-duration: 1.5s;
    }
    // .carousel-control-next-icon {
    //   background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23c4122f' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");
    // }
    .carousel-control-prev,
    .carousel-control-next {
      width: 10%;
      opacity: 0.8;
      &:hover {
        opacity: 1.0;
      }
      span {
        margin-bottom: 145px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 65px;
        }
      }
    }
    #justListedCarousel a {
      text-decoration: none;
      color: $black;
      &:hover {
        color: $dark-red;
      }
    }
    .just-listed-container {
      .property-img-container {
        position: relative;
        width: 100%;
        aspect-ratio: 3/2;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .property-details-container {
        margin-top: 20px;
        text-align: center;
        font-size: 18px;
        @include media-breakpoint-up(sm) {
          text-align: left;
        }
        .property-address,
        .property-details {
          margin-bottom: 10px;
        }
        .property-address {
          font-weight: bold;
        }
        .property-details {
          @include media-breakpoint-up(sm) {
            text-align: right;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
  #agentFeaturedBlogContainer {
    .agent-featured-blog-loading {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      background: $white;
      transition: opacity 1s linear;
      z-index: 5;
      &.loading {
        opacity: 1;
      }
      p {
        margin-top: 160px;
      }
      span {
        color: $medium-gray;
        font-size: 30px;
      }
    }
  }
  #agentHomeValueContainer {
    button {
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: unset;
      }
    }
  }
}
