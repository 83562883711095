/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Content (CMS) page styles
 * 
 */

.cms-page {
  margin-top: 0px;
  margin-bottom: 60px;
  font-family: Lato,Arial,Helvetica,san-serif!important;
  h1, h2, h3, h4 {
    margin-bottom: 25px;
  }
  h5, h6 {
    margin-bottom: 10px;
  }
  .cms-page-content {
    margin-top: 20px;
    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
  a {
    text-decoration: underline;
  }
  p, ul li, ol li, span {
    font-family: Lato,Arial,Helvetica,san-serif!important;
    // font-size: 16px!important;
    // line-height: 24px!important;
  }
  ul, ol {
    margin-bottom: 20px;
  }
  table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .cms-page-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 160px;
    @include media-breakpoint-up(md) {
      height: 230px;
    }
    @include media-breakpoint-up(lg) {
      height: 300px;
    }
    @include media-breakpoint-up(xl) {
      height: 400px;
    }
  }
}

// Random utility class for What's My Home Worth page
.a-lot-of-factors-go {
  font-size: 20px;
  letter-spacing: .2px;
  line-height: 30px;
}
