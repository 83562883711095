/*!
 * Reinhart Realtors Website Theme
 *
 * Resets component default values, complementary and overrides values in
 * bootstrap/_reboot.scss
 *
 */

// Add items to body and html that aren't in the Bootstrap reboot, or override values
// html: lines 25-30; body: lines 46-55
body, html {
  letter-spacing: .01em;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border-top: 1px solid $light-gray;
  &.off-white {
    border-color: $off-white;
  }
  &.light {
    border-color: $off-white;
  }
  &.medium {
    border-color: $medium-gray;
  }
  &.dark {
    border-color: $black;
  }
}
