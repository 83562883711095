/*!
 * Reinhart Realtors Website Theme
 *
 * Login form page styles
 * 
 */

#web_user_account-page {
  .bg-image-cover {
    // height: 250px;
    // max-height: 60vh;
    height: clamp(250px, 40vw, 80vh);
    @include media-breakpoint-up(lg) {
      height: clamp(300px, 60vh, 450px);
    }
  }
  .reinhart-web-account-signup-box {
    display: block;
    position: relative;
    width: 100%;
    margin: 30px 0px 0px 0px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    box-shadow: 0 9px 18px 0 rgba(0,0,0,.03), 0 2px 7px 0 rgba(0,0,0,.03);
    background-color: $white;
    transition: none;
    @include media-breakpoint-up(sm) {
      position: absolute;
      bottom: 0;
      margin: 0;
    }
    h5 {
      letter-spacing: 0.8px;
      line-height: 24px;
    }
  }
  .info-box {
    @include media-breakpoint-up(md) {
      min-height: 200px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 180px;
    }
  }
}

#createAccountFormModal,
#vowModal {
  .modal-dialog {
    @include media-breakpoint-down(xs) {
      margin: 1rem;
    }
  }
  .modal-content {
    margin-top: 10vh;
  }
  .nav-tabs .nav-link {
    color: $white;
    border: none;
    margin: 0;
    border-radius: 0px;
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    &:hover {
      border: none;
      background-color: $medium-gray;
      margin: 0;
    }
    &.active {
      border: none;
      margin: 0;
      background-color: $white;
      color: $dark-charcoal-gray;
    }
  }
  .nav.nav-tabs {
    @include media-breakpoint-down(xs) {
      padding: 15px;
    }
    > li {
      margin: 0;
      @include media-breakpoint-down(xs) {
        display: block;
        width: 100% !important;
        padding: 0px;
        border: 1px solid $white;
        &:first-child {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.modal-header > .rr-modal-close {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  padding: 0;
  background: $black;
  color: $white;
  border: 3px solid $white;
  border-radius: 50%;
  box-shadow: -2px 4px 4px rgba($black, 0.5);
  font-size: 1.5rem;
  i:before {
    vertical-align: 0.1em;
  }
}
