/*!
 * Reinhart Realtors Website Theme
 *
 * My account page styles
 * 
 */

.rr-account-page {
  .nav-tabs .nav-item .nav-link {
    font-size: 14px;
  }
  .favorites-addr-link {
    color: $carbon-gray;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $dark-red;
    }
  }
  .price-tracker-btn,
  .favorite-btn,
  .recommended-btn,
  .add-note-dropdown {
    padding: 0;
    outline: 0;
    height: unset;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    &:hover,
    &:focus {
      color: $dark-red;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .price-tracker-btn {
    color: $dark-red;
    .tracker-text {
      font-weight: normal;
      font-size: 0.875em;
      text-transform: capitalize;
    }
  }
  .price-tracker-expiration {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .favorite-btn,
  .recommended-btn,
  .add-note-dropdown {
    color: $dark-charcoal-gray;
    font-weight: normal;
    text-transform: capitalize;
  }
  .favorite-notes-container {
    margin-top: 15px;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
  .add-note-dropdown {
    &:not(.collapsed) i {
      transform: rotate(-180deg);
    }
    i {
      display: inline-block;
      transition: transform 0.2s linear;
    }
  }
  .add-note-btn {
    margin-top: 10px;
    height: auto;
    line-height: 1;
    padding: 10px;
    font-size: 0.875rem;
  }
  .note-date {
    color: $medium-gray;
    font-size: 0.875em;
  }
  .add-saved-search .btn {
    font-size: 0.875rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
  .email_pass_phone p {
    cursor: not-allowed;
  }
}

.account-overview-link-header {
  h2 {
    color: $bright-red;
    &:hover {
      color: $dark-red;
      text-decoration: underline;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.account-favorites {
  img {
    width: 320px;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    max-width: 100%;
  }
}

.saved-searches-table {
  &.table .thead-light th {
    border-color: $medium-gray;
  }
  th {
    font-size: 0.75rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .search-name {
    width: 100%;
    @include media-breakpoint-up (sm) {
      width: 60vw;
    }
    @include media-breakpoint-up(xl) {
      width: 100%;
    }
    button.close {
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 5;
    }
    .modal-body {
      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
  }
  .view-criteria-btn {
    height: unset;
    letter-spacing: unset;
    vertical-align: unset;
    text-transform: capitalize;
    padding: 0;
    font-size: 0.875rem;
    &:focus {
      box-shadow: none;
    }
  }
  .created {
    display: none;
    @include media-breakpoint-up(sm) {
      display: table-cell;
      padding-right: 5px;
      width: 190px;
    }
    @include media-breakpoint-up(xl) {
      white-space: nowrap;
    }
  }
  .expires,
  .email {
    display: none;
    @include media-breakpoint-up(lg) {
      display: table-cell;
    }
    @include media-breakpoint-up(xl) {
      white-space: nowrap;
    }
  }
  td {
    font-size: 0.875rem;
  }
  .view,
  .cancel {
    .btn {
      height: unset;
      padding: 0.25rem 0.375rem;
      font-size: 0.75rem;
      line-height: 1.125em;
      white-space: nowrap;
      @include media-breakpoint-up(md) {
        font-size: 0.875rem;
      }
    }
  }
  td.view,
  td.cancel {
    white-space: nowrap;
  }
}
