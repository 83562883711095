/*!
 * Reinhart Realtors Website Theme
 *
 * Icon styling classes, primarily for svg icons
 *
 * NOTE AREAS BELOW WHERE REINHART STYLES ARE BEING REPLACED WITH BOOTSTRAP TEXT UTILITY CLASSES
 */

 .svg-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.svg-icon.dark-red {
  fill: $dark-red;
}

.svg-icon.light-gray {
  fill: $light-gray;
}

.svg-icon.med-gray {
  fill: $medium-gray;
}

.svg-icon.white {
  fill: $white;
}

.svg-icon.bottom {
  vertical-align: bottom;
}

.svg-icon.top {
  vertical-align: top;
}

.svg-icon.sub {
  vertical-align: sub;
}

.svg-icon.text-top {
  vertical-align: text-top;
}

.svg-icon.text-bottom {
  vertical-align: text-bottom;
}

.svg-icon.top-two {
  position: relative;
  top: -2px;
}

.svg-icon.top-one {
  position: relative;
  top: 1px;
}

.svg-icon.top-1 {
  position: relative;
  top: -1px;
}

.svg-icon.lre,.svg-icon.lux-port {
  max-width: 135px;
}

.icon-video-play {
  border-radius: 50%;
  background-color: rgba(0,0,0,.4);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 42px;
  height: 42px;
  cursor: pointer;
  line-height: 42px;
  font-size: 42px;
  transform: translate(-50%,-50%);
  z-index: 102;
}

.flip-link svg {
  -webkit-transition: transform .25s ease-in-out;
  -moz-transition: transform .25s ease-in-out;
  -ms-transition: transform .25s ease-in-out;
  -o-transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transform: rotate(0);
}

.flip-link[aria-expanded=true] svg {
  transform: rotate(180deg);
}
