/*!
 * Reinhart Realtors Website Theme
 *
 * New Construction page styles
 * 
 */

 .new-construction-container {
  margin-bottom: 30px;
  .row {
    justify-content: center;
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }
    .col {
      flex: 0 0 100%;
      margin-bottom: 60px;
      @include media-breakpoint-up(md) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .new-construction-community {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @include media-breakpoint-up(md) {
      max-width: 360px;
      margin-left: 20px;
      margin-right: 20px;
    }
    a {
      display: inline-block;
      padding: 25px 10px;
      text-decoration: none;
      box-shadow: 2px 2px 5px 0px rgba($black,0.25);
      transition: all 0.2s ease-in;
      &:hover {
        box-shadow: 5px 5px 10px 0px rgba($black,0.50);
      }
      &:active {
        box-shadow: inset 0px 0px 5px 2px rgba($black,0.75);
      }
    }
    img {
      width: 100%;
      height: min(300px,calc(100vw - 50px));
      object-fit: contain;
    }
    h2 {
      margin-top: 10px;
      font-size: 32px;
      font-weight: 400;
    }
    p {
      font-size: 24px;
      margin-bottom: 0px;
    }
  }
 }
