/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Site-wide navigation component for all themes
 * Overrides the navigation styling on the main site/parent theme
 * 
 */

// https://stackoverflow.com/a/55260765 <= reference for slide animation Bootstrap dropdown

// Overwrite the homepage height value for large screens
// If this becomes an issue, may need to use javascript to target container height with javascript
// June 2024 - removing this rule as it shrinks the container compared to main site. With the transparency
// and reappear on scroll, this rule should no longer be necessary to control the height of the hero image
// (request made via email from Mary on 6/5/2024).
// .homepage .home-hero .rr-hero-container {
//   @include media-breakpoint-up(lg) {
//     margin-top: -100px;
//   }
// }

.rr-hero-transparent .navbar .navbar-agent-info-container {
  @include media-breakpoint-up(lg) {
    background-color: rgba($primary-header-bg, 0.8);
  }
}

// Overwrite the default styles for .navbar class; bootstrap/_navbar.scss:18-24
// Add in other style properties such as min-height, alignment, link spacing, font size,
// dropdown menu, margin on desktop
.navbar {
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 80px;
  margin-left: -15px;
  padding: 0;
  @include media-breakpoint-up(lg) {
    // flex-wrap: wrap;
    min-height: 100px;
    margin-left: 0px;
  }
  .navbar-toggler {
    z-index: 5;
    font-size: 2rem; // default for browsers that don't support clamp
    // font-size: clamp(1.5rem, 12.5vw, 2.5rem);
    padding: 0 0.25rem 0.625rem 0;
    padding-left: 11px; // align with other elements in grid
    border-color: transparent;
  }
// BEGIN navbar-brand styling for agent information
  .navbar-brand {
    flex: 1 0 0;
    text-align: center;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      text-align: left;
    }
    // min-width: 210px; // Only need to set this if the black bar should be a minimum width
    margin: 0;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .navbar-agent-info-container {
    display: inline-flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
    @include media-breakpoint-up(lg) {
      min-width: 2750px;
      margin-left: -2500px;
      padding-left: 2500px;
      padding-right: 30px;
      background-color: $primary-header-bg;
    }
    img {
      // height: 60px;
      max-height: 60px;
      max-width: min(calc(100% - 10px), 450px);
    }
  }
  .navbar-agent-info-container,
  .mobile-menu-brand {
    color: $white;
    text-decoration: none;
    .rr-agent-header-name {
      font-size: 20px;
      letter-spacing: 0.025em;
      white-space: normal;
      @include media-breakpoint-up(lg) {
        white-space: nowrap;
      }
      @include media-breakpoint-up(xl) {
        font-size: 24px;
      }
    }
    .rr-agent-header-title {
      font-size: 14px;
      white-space: normal;
      @include media-breakpoint-up(lg) {
        white-space: nowrap;
      }
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
  }
// END navbar-brand styling for agent information
  
// BEGIN navbar navigation elements
  .navbar-nav {
    @include media-breakpoint-up(lg) {
      // width: 100%;
      flex: 1 0;
      justify-content: space-between;
      // justify-content: space-around;
    }
    .nav-item {
      @include media-breakpoint-up(lg) {
        margin: 0 10px;
        &.dropdown.show .nav-link {
          background-color: $med-cool-gray;
          &:focus,
          &:active {
            background-color: $med-cool-gray;
          }
        }
      }
    }
    .nav-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-up(lg) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
      > div {
        font-size: 1.25rem;
        letter-spacing: 0.0375em;
        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }
      }
      > i {
        font-size: 1.375rem;
      }
      @include media-breakpoint-up(lg) {
        &:hover {
          background-color: $med-cool-gray;
        }
      }
      transition: background-color 0.2s linear;
    }
    .dropdown-menu {
      @include media-breakpoint-up(lg) {
        margin: 0;
        box-shadow: 1px 1.73px 10px 0 rgba(0,0,0,.16),1px 1.73px 5px 0 rgba(0,0,0,.26);
        border: none;
        // Animate dropdown into view
        display: block;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.2s linear;
        &.show {
          opacity: 1;
          max-height: 750px;
        }
      }
    }
    .dropdown-item {
      &:hover,
      &:focus,
      &:active {
        color: $white;
        background-color: $med-cool-gray;
      }
      @include media-breakpoint-down(md) {
        padding: 1rem 1.25rem;
        font-size: 1.125rem;
        font-size: clamp(0.875rem, 4.375vw, 1.25rem);
        letter-spacing: 0.0375em;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.rr-agent-header-logo {
  display: none;
  justify-content: flex-end;
  align-items: center;
  // flex: 1 0 150px;
  flex: 1 2 80px;
  @include media-breakpoint-up(xl) {
    flex: 1 1 120px;
  }
  max-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  img {
    max-height: 100%;
  }
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

// On desktop, adjust styles so hover on menu items creates a box that extends top to bottom of header
// This grows the navigation element and centers the text in the anchor tag.
// TO-DO: Remove padding from navbar and move padding to individual elements as necessary
.navbar-expand-lg .navbar-collapse {
  @include media-breakpoint-up(lg) {
    // flex: 1 0 auto;
    flex: 2 1 auto;
    align-self: stretch;
    align-items: stretch;
    @include media-breakpoint-up(xl) {
      flex: 1 1 auto;
    }
    .navbar-nav {
      flex-wrap: wrap;
      padding: 0 15px;
      .nav-link {
        justify-content: flex-start;
        height: 100%;
        > i {
          display: none;
        }
        display: flex;
        align-items: center;
      }
    }
  }
}

.nav-header > div {
  height: 80px;
  padding: 0 1.25rem;
  color: $white;
  background-color: $nav-mobile-header-bg;
}

.mobile-menu-divider {
  height: 0;
  overflow: hidden;
  border-top: 4px solid $light-gray;
}

// Set background on the nav-link when the collapse is open and the dropdown is showing
.navbar-collapse.show .nav-item.show .nav-link {
  background-color: rgba($gray-600,0.25);
}

// Custom styles for button and link that is used for the Login button and Listing Alert actions
.rr-nav-account {
  @include media-breakpoint-down(md) {
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .label-account {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 0;
    bottom: -38px;
    z-index: 10;
  }
  .btn-account {
    font-size: 1.5rem; // default for browsers that do not support clamp
    // font-size: clamp(0.75rem, 4vw, 0.8rem);
    letter-spacing: 0.05em;
    text-align: center;
    // padding: 3px 12px 5px;
    // padding: 0.125em 0.75em 0.25em;
    padding: 0.1em 0.25em;
    color: $white;
    background: $header-login-btn;
    border: none;
    border-radius: 0.25em;
    @include media-breakpoint-up(lg) {
      font-size: 0.75rem;
      padding: 0.1em 1em 0.3em;
      border-radius: 15px;
      background-color: $bright-red;
    }
    &:hover {
      background-color: $header-login-btn-hover;
      text-decoration: none;
      @include media-breakpoint-up(lg) {
        background-color: $dark-red;
      }
    }
  }
  .icon-account,
  .icon-contact {
    padding: 10px;
    color: $white;
  }
  a.icon-account,
  a.icon-contact {
    text-decoration: none;
  }
  button.icon-account {
    background: transparent;
    margin: 0;
    box-shadow: none;
    border: none;
  }
  .icon-account {
    font-size: 1.875rem;
  }
  .icon-contact {
    font-size: 1.75rem;
  }
}

// Mobile menu styles for collapse and dropdowns
.navbar-collapse {
  @include media-breakpoint-down(md) {
    position: absolute;
    top: 0;
    z-index: 10;
    overflow-x: hidden;
    .navbar-nav {
      width: 75vw;
      min-width: 250px;
      height: 100vh;
      background-color: $white;
    }
  }
}

// Use CSS to slide in dropdown menus from the right to overlay the mobile menu
// Use max-width media queries on the dropdown-menus

.nav-item.dropdown {
  @include media-breakpoint-down(md) {
    position: unset;
    .dropdown-menu {
      position: absolute;
      top: 0;
      // display: block;
      // visibility: hidden; // start with hidden visibility, and show on shift
      margin: 0;
      border-radius: 0;
      width: 100%;
      min-height: 100%;
      transform: translateX(100%);
      transition: transform 0.4s ease;
      &.shift-left {
        transform: translateX(0);
      }
      &.d-block-show {
        display: block;
        border-width: 0px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .dropdown-menu {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
}

.mobile-menu-back {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 1.25rem;
  // font-size: clamp(0.75rem, 3.75vw, 1.125rem);
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 0 1.25rem;
  color: $black;
  white-space: nowrap;
  transition: background-color 0.2s linear;
  &:hover,
  &:focus,
  &:active {
    color: $black;
    background-color: rgba($gray-600,0.25);
    text-decoration: none;
  }
}

.nav-item.dropdown .dropdown-divider {
  border-top: 5px solid $light-gray;
}

.nav-item.dropdown .dropdown-header {
  padding: 0.5rem 1.25rem;
  font-size: 1.5rem;
  font-size: clamp(1rem, 5vw, 1.5rem);
  font-weight: bold;
  color: $black;
}

// classes to fix body and navbar-nav to fixed values
.fixed-body-mobile-nav {
  @include media-breakpoint-down(md) {
    // position: fixed;
    overflow: hidden;
    width: 100%; // needed to make sure that body stays 100% width when content is limited
  }
}

.fixed-navbar-nav {
  @include media-breakpoint-down(md) {
    position: fixed;
    overflow-y: auto;
  }
}

/*
 * Don't particularly like using the max-width breakpoint, but want to allow Bootstrap styles to
 * take precedence when not using the mobile slideout drawer
 */
 .navbar-nav .nav-link {
  @include media-breakpoint-down(md) {
    padding: 1rem 1.25rem;
  }
 }

.navbar-dark .navbar-collapse .navbar-nav .nav-link {
  @include media-breakpoint-down(md) {
    color: $black;
  }
}

// Make the background nav div that hides the background and closes the nav menu
#mobileNavBackground {
  display: none;
  position: fixed;
  inset: 0;
  z-index: 5;
  background-color: rgba($black,0.8);
  opacity: 0;
  transition: opacity 0.3s ease;
  &.show {
    display: block;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .close-icon {
    position: fixed;
    top: 25px;
    right: 9vw;
    height: 32px;
    width: 32px;
    fill: var(--white);
  }
}

// Quick navigation block on the mobile - home page only?
.mobile-quick-nav {
  display: flex;
  overflow-x: auto;
  margin: 0 -15px;
  padding: 0;
  list-style: none;
  scrollbar-width: none; // hide scrollbar on Firefox
  background-color: $white;
  li {
    padding: 10px 15px;
    white-space: nowrap;
    background-color: inherit; // Otherwise, inherits -webkit-tap-highlight-color from body set at rgba(0,0,0,0) - from reboot.scss
    a {
      color: $carbon-gray;
      text-decoration: none;
    }
  }
  &::-webkit-scrollbar {
    display: none; // hide scrollbar on Chrome, Safari, Edge
  }
}
