/*!
 * Reinhart Realtors Agent Website Theme
 *
 * Site-wide header component
 * 
 */

 body > header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 105;
  transition: top 0.3s ease, background-color 0.3s ease;
  &.rr-nav-scroll {
    position: fixed;
    .rr-header-background {
      background-color: $nav-mobile-header-bg;
      @include media-breakpoint-up(lg) {
        background-color: $nav-header-bg;
      }
    }
  }
  &.hide-nav {
    // top: -100px;
    top: -150px;
  }
}

// Added June 2024 to add more specificity to styling rule so header has a solid
// background when it reappears on scroll
body.homepage > header.rr-nav-scroll .rr-header-background {
  background-color: $nav-mobile-header-bg;
  @include media-breakpoint-up(lg) {
    background-color: $nav-header-bg;
  }
}

// Parking this override here for agent sites on the #main-content area for large screens
main#main-content {
  @include media-breakpoint-up(lg) {
    margin-top: 100px;
  }
}
 
 // Override bg-black on header background
 div.rr-header-background {
   background-color: $primary-header-bg;
   transition: background-color 0.3s ease;
   @include media-breakpoint-up(lg) {
     background-color: $nav-header-bg;
    }
  }
  
// Change background header transparency on pages with hero image on large screens
.rr-hero-transparent div.rr-header-background,
.rr-hero-transparent-80 div.rr-header-background,
.homepage.rr-hero-transparent-80 div.rr-header-background {
  @include media-breakpoint-up(lg) {
    background-color: rgba($nav-header-bg,0.8);
  }
}

.rr-hero-transparent-60 div.rr-header-background,
.homepage.rr-hero-transparent div.rr-header-background {
  @include media-breakpoint-up(lg) {
    background-color: rgba($nav-header-bg,0.6);
  }
}

// Breadcrumb styles from Reinhart site

.breadcrumb {
  background: none;
  color: $dark-charcoal-gray; // original #5c5c5c; for inactive links, slightly lighter than active links
  padding: 15px 15px 12px 0;
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
  border-radius: 0;
  a {
    color: $black; // original #5c5c5c
  }
  li {
    margin-left: 0;
  }
}

.breadcrumb > li {
  display: inline-flex;
  padding-bottom: 0;
}

.breadcrumb > li + li:before {
  color: $dark-charcoal-gray; // original #5c5c5c
  font-family: "bootstrap-icons";
  content: "\F285";
  padding: 0 5px;
}

.breadcrumb > .active {
  color: $medium-gray; // original #8c8c8c
}

.breadcrumb-bar {
  position: relative;
  background-color: rgba($white,0.75);
  // Adjust compare icon to the left of the login/my-account button
  .rr-prop-compare-container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      right: max(80px, calc((100vw - 1040px)) / 2);
    }
    @include media-breakpoint-up(xl) {
      right: calc(((100vw - 1140px) / 2) + 80px);
    }
    &.my-account {
      @include media-breakpoint-up(lg) {
        position: absolute;
        right: max(115px, calc((100vw - 970px)) / 2);
      }
      @include media-breakpoint-up(xl) {
        right: calc(((100vw - 1140px) / 2) + 115px);
      }
    }
  }
}

// Change account button for up to 992px to be rounded with different indicators for logged in versus not logged in
// a.btn-account needed to increase specificity to overwrite the main site styles
// width and height 1rem more than font-size for icon
.rr-nav-account {
  a.btn-account,
  button.btn-account {
    @include media-breakpoint-down(md) {
      // width: 3rem;
      // height: 3rem;
      width: 2.5rem;
      height: 2.5rem;
      background-color: $header-login-btn-bg;
      border-radius: 50%;
      border: 2px solid $header-login-btn-border;
      &.my-account {
        background-color: $header-login-btn;
        border-color: $header-login-btn;
        svg {
          color: $header-login-btn-icon;
        }
        &:hover {
          background-color: $header-login-btn-hover;
          border-color: $header-login-btn-hover;
        }
        // border-color: $my-account-btn-border-color;
      }
      &:hover {
        border-color: $login-btn-hover-mobile;
        background-color: $login-btn-hover-mobile;
      }
      svg {
        height: 20px;
        width: 20px;
        margin-bottom: 12px;
      }
    }
  }
}

