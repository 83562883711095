// standard property classes
@media screen and (max-width: 767px) {
    .standard-property {
        margin:auto;
        margin-bottom: 60px;
        max-width: 362px
    }
}

.standard-property a {
    -webkit-transition: color .15s ease-in-out;
    -moz-transition: color .15s ease-in-out;
    -ms-transition: color .15s ease-in-out;
    -o-transition: color .15s ease-in-out;
    transition: color .15s ease-in-out
}

.standard-property .property-photo-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
}

.standard-property .prop-photo-nav {
    position: absolute;
    z-index: 102;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: rgba($dark-cool-gray,.8);
    cursor: pointer;
    line-height: 26px;
    width: 32px;
    height: 32px;
    -webkit-transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -ms-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out
}

.standard-property .prop-photo-nav.next-photo {
    left: auto;
    right: 0;
}

.standard-property:hover .prop-photo-nav {
    opacity: 1;
}

.standard-property:hover .address a {
    color: $dark-red;
}

.standard-property .open-house-banner,.standard-property .sold-banner {
    background: rgba($dark-red,.8);
    position: absolute;
    top: 0;
    padding: 0 10px 2px;
    z-index: 102;
    letter-spacing: .8px;
    line-height: 19px;
}

.standard-property .sold-banner {
    background: rgba($carbon-gray,.8);
}

.standard-property .prop-cta {
    border-top: 1px solid $medium-gray;
}

.standard-property .cta-link a {
    color: $black;
}

.standard-property .cta-link a:hover {
    text-decoration: none
}
