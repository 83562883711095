/*!
 * Reinhart Realtors Website Theme
 *
 * Site-wide style updates for Google Maps
 * 
 */

 .google-map-canvas *,.google-map-canvas :before,.google-map-canvas :after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

.google-map-canvas img {
  max-width: none
}

.google-map-canvas .gm-style,.google-map-canvas .gm-style-iw {
  font-family: inherit;
  font-size: inherit
}
