/*!
 * Reinhart Realtors Website Theme
 *
 * Card and container components
 * 
 */

// wdign-container - used at bottom of content pages

.wdign-container {
  .background-image {
    height: 195px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }
  // initially, only applies to agent sites; need to update main site with wdign-row class
  // then some of the equal-height items need to be re-examined, possibly
  .wdign-row {
    gap: 30px;
    @include media-breakpoint-up(md) {
      gap: unset;
      .equal-height {
        display: flex;
        flex-direction: column;
        height: 100%;
        .next-details {
          min-height: 105px;
        }
      }
    }
  }
  .equal-height {
    background-color: $white;
    transition: box-shadow .5s ease-in-out;
    box-shadow: 0 9px 18px 0 rgba(0,0,0,.03),0 2px 7px 0 rgba(0,0,0,.03);
    @include media-breakpoint-up(md) {
      min-height: 300px;
    }
    &:hover {
      box-shadow: 0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
      .wdign-name {
        color: $dark-red;
      }
    }
    a {
      color: #000;
      text-decoration: none!important;
    }
    img {
      width: 100%;
    }
    .next-details {
      padding: 10px 15px 20px;
      display: block;
      min-height: 122px;
    }
    span.h5 {
      color: $black;
    }
  }
}


// @media screen and (max-width: 767px) {
//   .wdign-container .equal-height img {
//       width:100%
//   }
// }

// @media screen and (min-width: 768px) {
//   .wdign-container .equal-height {
//       min-height:300px
//   }
// }
