/*!
 * Reinhart Realtors Website Theme
 *
 * Text utilities for font sizes, font weights, line heights, etc.
 * Complementary to _typopgraphy.scss file and bootstrap/utilities/_text.scss
 *
 * NOTE AREAS BELOW WHERE REINHART STYLES ARE BEING REPLACED WITH BOOTSTRAP TEXT UTILITY CLASSES
 */

//
// Font sizes
//

.font-10 {
  font-size: 10px!important;
}

.font-12 {
  font-size: 12px!important;
}

.font-13 {
  font-size: 13px!important;
}

.font-14 {
  font-size: 14px!important;
}

.font-15 {
  font-size: 15px!important;
}

.font-16 {
  font-size: 16px!important;
}

.font-18 {
  font-size: 18px!important;
}

.font-20 {
  font-size: 20px!important;
}

.font-24 {
  font-size: 24px!important;
}

.font-36 {
  font-size: 36px!important;
}

.font-30 {
  font-size: 30px!important;
}

.font-40 {
  font-size: 40px!important;
}

.font-44 {
  font-size: 44px!important;
}

.font-48 {
  font-size: 48px!important;
}

.font-50 {
  font-size: 50px!important;
}

.font-72 {
  font-size: 48px!important;
  @include media-breakpoint-up(lg) {
    font-size:72px!important
  }
  @include media-breakpoint-up(xl) {
    font-size:72px!important
  }
}

.font-xs-12 {
  font-size: 12px;
  line-height: 19px;
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 24px;
  }
}

//
// Font weights
//

/*! 
 * replace following styles with Bootstrap values
 * .light => font weight 100 (no bootstrap equivalent)
 * .regular-light => .font-weight-light (Bootstrap utility - font-weight 300)
 * .regular => .font-weight-normal
 * .regular-italic => .font-italic
 * .bold => .font-weight-bold
 */

 .light {
  font-weight: 100;
 }

.regular-light {
  font-weight: 300;
}

//
// Line heights
//

.line-1-5 {
  line-height: 1.5;
}

.line-18 {
  line-height: 18px;
}

.line-20 {
  line-height: 20px;
}

.line-22 {
  line-height: 22px;
}

.line-30 {
  line-height: 30px;
}

//
// Text tranforms
//
// replace following values with Bootstrap values
// .uppercase => .text-uppercase

//
// Text decorations
//
// replace following values with Bootstrap values
// .no-underline, .no-underline:hover, .no-underline:focus => .text-decoration-none

.underline {
  text-decoration: underline;
}

.strike {
  text-decoration: line-through;
}

//
// Letter-spacings
//

.letters-02 {
  letter-spacing: .32px;
}

.letters-08 {
  letter-spacing: .8px;
}

//
// Contextual colors (see global/_colors.scss for colors values)
//
// replace following values with Bootstrap values
// .white, .white-hover:hover,.white-hover:focus => .text-white

// Custom text classes

.footer-text, .fine-print {
  font-weight: 400;
  font-size: 0.875rem;
  color: $medium-gray;
  line-height: 1.25rem;
  letter-spacing: 0.02em;
}
