/*!
 * Set values for Bootstrap variables found in bootstrap/_variables.scss
 */

// Color system (bootstrap/_variables.scss lines 6-89)

$white:    #fff; //:8
$black:    #000;//:18

$colors: ( //:47
  "carbon-gray":      $carbon-gray,
  "dark-gray":        $dark-gray,
  "medium-gray":      $medium-gray,
  "light-gray":       $light-gray,
  "off-white":        $off-white,
  "dark-cool-gray":   $dark-cool-gray,
  "med-cool-gray":    $med-cool-gray,
  "light-cool-gray":  $light-cool-gray,
  "dark-red":         $dark-red,
  "bright-red":       $bright-red,
);

$primary:   $light-cool-gray; //:67 - set theme colors here, remove color sets from _buttons.scss
$warning:   $light-cool-gray; //:71

// Spacing (bootstrap/_variables.scss lines 130-161)
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation. 
// NOTE: Overwriting 5 here with 5px to match styles in Reinhart style guide
$spacers: ( //:137
  5:    5px,
  10:   10px,
  15:   15px,
  20:   20px,
  25:   25px,
  30:   30px,
  35:   35px,
  40:   40px,
  45:   45px,
  50:   50px,
  55:   55px,
  60:   60px,
  65:   65px,
  70:   70px,
  75:   75px,
  80:   80px,
  85:   85px,
  90:   90px,
  95:   95px,
  100:  100px,
  130:  130px,
  165:  165px,
);

// Body (bootstrap/_variables.scss lines 168-169
//
// Settings for the `<body>` element.

$body-color:                $black; //:169

// Links (bootstrap/_variables.scss lines 172-181)
//
// Style anchor elements. 

$link-color:                              $bright-red; //:176

$link-hover-color:                        $dark-red; //:178

// Paragraphs (bootstrap/_variables.scss lines 183-187)
//
// Style p element.

$paragraph-margin-bottom:   16px; //:187

// Components (bootstrap/_vairables.scss lines 230-271)
//
// Define common padding and border radius sizes and more

$border-color:               $light-gray;

// Typography (bootstrap/_variables.scss lines 273-345)
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      Lato,Arial,Helvetica,san-serif; //:276

$font-weight-light:           100; //:288

$headings-margin-bottom:      0; //:303
$headings-color:              $carbon-gray;

// Tables (bootstrap/_variables.scss lines 352-381)
//
// Style table elements

$table-head-bg:               $off-white; //:365
$table-head-color:            $carbon-gray; //:366


// Buttons (bootstrap/_variables.scss lines 411-451)
//
// Style bootstrap buttons

$btn-padding-y:               5px; //:415
$btn-padding-x:               20px; //:416

$btn-border-width:            1px; //:432

$btn-font-weight:             700; //::434
$btn-box-shadow:              none; //:435

$btn-border-radius:           0; //:446
$btn-border-radius-lg:        0; //:447
$btn-border-radius-sm:        0; //:448

$btn-transition:              color .25s ease, background-color .25s ease, border-color .25s ease, box-shadow .25s ease; //:450

// Forms (bootstrap/_variables.scss lines 453-652
//
// Style default form values

$input-font-family:           $font-family-sans-serif; //:459

$input-color:                 $dark-gray; //:477
$input-border-color:          $dark-gray; //:478
$input-border-width:          0; //:479
$input-box-shadow:            none; //:480

$input-border-radius:         0; //:482

$input-focus-bg:              inherit; //:486
$input-focus-border-color:    $medium-gray; //:487
$input-focus-box-shadow:      none; //:490

$input-placeholder-color:     $medium-gray; //:492

$input-height:                2rem; //:501

// Navbar (bootstrap/_variables.scss lines 717-754)
//
// Spacings, link and background colors

$navbar-nav-link-padding-x:         1.25rem;

// Using class .navbar-dark to render the links
$navbar-dark-color:                 $white; //:737; must hard-code value since color $white not defined until bootstrap variables load
$navbar-dark-hover-color:           $white; //:738


// Breadcrumb (bootstrap/_variables.scss lines 1058-1075)

$breadcrumb-bg:                     rgba($white,0.75); //:1068; must hard-code value since color $white not defined until bootstrap variables load
